var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "person-afil" },
    [
      _c(
        "span",
        {
          staticClass: "blue-red-hover-text",
          attrs: { id: "person-affil-btn" },
          on: { click: _vm.FIOclickHandle },
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.showAfilNetworkTooltip
        ? _c("afilNetWorkTooltip", { attrs: { inn: _vm.inn, fio: _vm.fio } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                close: _vm.closeModal,
                title:
                  "Аффилированность " +
                  (this.inn_get.length > 1
                    ? "всех собственников-ФЛ с долей более 10%"
                    : ""),
                hasFooter: true,
                printButton: true,
                downloadButton: true,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "modalHeader" }, slot: "modalHeader" },
                [
                  _vm._v(
                    "Аффилированность " +
                      _vm._s(
                        this.inn_get.length > 1
                          ? "всех собственников-ФЛ с долей более 10%"
                          : ""
                      )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { padding: "15px 15px 5px" },
                  attrs: { slot: "modalBody" },
                  slot: "modalBody",
                },
                [
                  _vm.inn_get && _vm.inn_get.length > 1
                    ? _c(
                        "div",
                        { staticClass: "affil-link-table" },
                        _vm._l(_vm.inn_get, function (e, i) {
                          return _c(
                            "span",
                            {
                              key: i,
                              staticClass: "blue-red-hover-text",
                              on: {
                                click: function ($event) {
                                  return _vm.scrollIntoView(_vm.inn_get[i])
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.firstCapitalLetter(_vm.fio_get[i]))
                              ),
                              _c("br"),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.inn_get, function (e, i) {
                    return _c("div", { key: i }, [
                      _c(
                        "div",
                        { attrs: { id: "inn-anchor-" + _vm.inn_get[i] } },
                        [
                          _c("b", [_vm._v("ФИО:")]),
                          _vm._v(
                            " " + _vm._s(_vm.firstCapitalLetter(_vm.fio_get[i]))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("b", [_vm._v("ИНН:")]),
                        _vm._v(" " + _vm._s(_vm.inn_get[i])),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm.loading
                          ? _c("div", [_c("shestirenka")], 1)
                          : _vm.afilData[i]
                          ? _c("div", [
                              _vm.afilData[i].upr.length &&
                              _vm.afilData[i].uchr.length
                                ? _c(
                                    "div",
                                    [
                                      _vm.afilData[i].uchr.filter(function (e) {
                                        return !e.stakeholder_date_end
                                      }).length >= 5
                                        ? _c("massFactor", [
                                            _vm._v(
                                              "\n                                Является учредителем в " +
                                                _vm._s(
                                                  _vm.afilData[i].uchr.filter(
                                                    function (e) {
                                                      return (
                                                        !e.stakeholder_date_end ||
                                                        e.date_end
                                                      )
                                                    }
                                                  ).length
                                                ) +
                                                " ЮЛ и имеет признаки «массового учредителя».\n                            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.afilData[i].upr.filter(function (e) {
                                        return (
                                          !e.stakeholder_date_end || e.date_end
                                        )
                                      }).length >= 10
                                        ? _c("massFactor", [
                                            _vm._v(
                                              "\n                                Является руководителем в " +
                                                _vm._s(
                                                  _vm.afilData[i].upr.filter(
                                                    function (e) {
                                                      return !e.stakeholder_date_end
                                                    }
                                                  ).length
                                                ) +
                                                " ЮЛ и имеет признаки «массового руководителя».\n                            "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].upr && _vm.afilData[i].upr.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(_vm._s(_vm.typeName("upr"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].upr,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].upr,
                                          type: "upr",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].uchr &&
                              _vm.afilData[i].uchr.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(_vm._s(_vm.typeName("uchr"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].uchr,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].uchr,
                                          type: "uchr",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].mbupr &&
                              _vm.afilData[i].mbupr.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(_vm._s(_vm.typeName("mbupr"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].mbupr,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].mbupr,
                                          type: "mbupr",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].mbuchr &&
                              _vm.afilData[i].mbuchr.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(_vm._s(_vm.typeName("mbuchr"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].mbuchr,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray: _vm.afilData[i].mbuchr,
                                          type: "mbuchr",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].upr_history &&
                              _vm.afilData[i].upr_history.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(
                                          _vm._s(_vm.typeName("upr_history"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray:
                                            _vm.afilData[i].upr_history,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray:
                                            _vm.afilData[i].upr_history,
                                          type: "upr_history",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.afilData[i].uchr_history &&
                              _vm.afilData[i].uchr_history.length
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v(
                                          _vm._s(_vm.typeName("uchr_history"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: {
                                          afilArray:
                                            _vm.afilData[i].uchr_history,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray:
                                            _vm.afilData[i].uchr_history,
                                          type: "uchr_history",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.history[i]
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "type-header" }, [
                                        _vm._v("Исторические связи"),
                                      ]),
                                      _vm._v(" "),
                                      _c("personAfilTable", {
                                        attrs: { afilArray: _vm.history[i].ul },
                                      }),
                                      _vm._v(" "),
                                      _c("personAfilStats", {
                                        attrs: {
                                          afilArray: _vm.history[i].ul,
                                          type: "history",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }