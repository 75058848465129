<template>
    <div id="afil-network">
        <div class="full-screen-menu" v-if="auth">
            <!-- <span style="top: 30px;" @click="toggleCanvasSize" class="aff uk-pseudolink af-expand">Свернуть<i class="uk-icon-compress" style="margin-left: 5px;"></i></span> -->
        
            <span class="full-screen-menu__item" @click="showLegendToggle">
                <span v-if="!showLegend">Показать легенду<i class="uk-icon-plus-square-o" style="margin-left: 5px;"></i></span>
                <span v-else>Скрыть легенду<i class="uk-icon-minus-square-o" style="margin-left: 5px;"></i></span>
            </span>

            <span v-show="showTableList == false" class="full-screen-menu__item afil-show-list">
                Список <i class="uk-icon-file-text-o"></i>
            </span>
            <span
                v-show="showTableList == true"
                @click="() => { showTableList = false }"
                class="full-screen-menu__item"
            >Закрыть список <i class="uk-icon-minus-square-o"></i></span>

            <span @click="printHTML" class="full-screen-menu__item">Распечатать<i class="uk-icon-print" style="margin-left: 5px;"></i></span>

            <span @click="savePdf" class="full-screen-menu__item">Сохранить<i class="uk-icon-save" style="margin-left: 5px;"></i></span>
            
            <!-- <span class="aff uk-pseudolink afil-network-show-hide-links">Все связи<i class="uk-icon-eye-slash" style="margin-left: 5px;"></i></span> -->

            <span class="full-screen-menu__item afil__resetDefault">Сбросить<i :class="{'uk-icon-refresh': true, rotating: resetActive}" style="margin-left: 5px;"></i></span>
        </div>

        <shestirenka v-if="loading"/>

        <div id="af-wrap" v-show="showTableList == false">
            <div id="af" :style="fullScreenStyleObject.af"></div>
        </div>

        <div id="afil-legend" v-show="showLegend">
            <ul>
                <li ref="edge.UPR">
                    <div class="legend__line-through" v-if="!legend.edge.UPR"></div>
                    <div class="afil-legend-edge afil-legend-edge__upr">
                        <div class="afil-legend-edge__text"></div>
                    </div>
                    <div>Руководитель, Упр. компания</div>
                </li>
                <li ref="edge.UPR_HISTORY">
                    <div class="legend__line-through" v-if="!legend.edge.UPR_HISTORY"></div>
                    <div class="afil-legend-edge afil-legend-edge__lupr">
                        <div class="afil-legend-edge__text"></div>
                    </div>
                    <div>Бывший руководитель</div>
                </li>
                <li ref="edge.UCHR">
                    <div class="legend__line-through" v-if="!legend.edge.UCHR"></div>
                    <div class="afil-legend-edge afil-legend-edge__uchr">
                        <div class="afil-legend-edge__text">100%</div>
                    </div>
                    <div>Учредитель</div>
                </li>
                <li ref="edge.UCHR_HISTORY">
                    <div class="legend__line-through" v-if="!legend.edge.UCHR_HISTORY"></div>
                    <div class="afil-legend-edge afil-legend-edge__luchr">
                        <div class="afil-legend-edge__text">Учр.</div>
                    </div>
                    <div>Бывший учредитель</div>
                </li>
                <li ref="edge.REGISTRY_HOLDER">
                    <div class="legend__line-through" v-if="!legend.edge.REGISTRY_HOLDER"></div>
                    <div class="afil-legend-edge afil-legend-edge__reestr">
                        <div class="afil-legend-edge__text">Реестродерж.</div>
                    </div>
                    <div>Реестродержатель</div>
                </li>
                <li ref="edge.PREDSH">
                    <div class="legend__line-through" v-if="!legend.edge.PREDSH"></div>
                    <div class="afil-legend-edge afil-legend-edge__predsh">
                        <div class="afil-legend-edge__text">Предш</div>
                    </div>
                    <div>Предшественник</div>
                </li>
                <li ref="edge.PREEM">
                    <div class="legend__line-through" v-if="!legend.edge.PREEM"></div>
                    <div class="afil-legend-edge afil-legend-edge__preem">
                        <div class="afil-legend-edge__text">Преем.</div>
                    </div>
                    <div>Преемник</div>
                </li>
                <li ref="edge.RODSTV">
                    <div class="legend__line-through" v-if="!legend.edge.RODSTV"></div>
                    <div class="afil-legend-edge afil-legend-edge__rodstv">
                        <div class="afil-legend-edge__text">Родств.</div>
                    </div>
                    <div>Родственник</div>
                </li>
                <li ref="edge.STAFF">
                    <div class="legend__line-through" v-if="!legend.edge.STAFF"></div>
                    <div class="afil-legend-edge afil-legend-edge__staff">
                        <div class="afil-legend-edge__text">Сотруд.</div>
                    </div>
                    <div>Сотрудник</div>
                </li>
            </ul>
            <ul>
                <li ref="node.ULRos">
                    <div class="legend__line-through" v-if="!legend.node.ULRos"></div>
                    <i class="fa fa-building"></i>
                    Юридическое лицо
                </li>
                <li ref="node.ULIn">
                    <div class="legend__line-through" v-if="!legend.node.ULIn"></div>
                    <i class="fa fa-globe"></i>
                    Иностранное юридическое лицо
                </li>
                <li ref="node.FL">
                    <div class="legend__line-through" v-if="!legend.node.FL"></div>
                    <i class="fa fa-male"></i>
                    Физическое лицо
                </li>
                <li ref="node.IP">
                    <div class="legend__line-through" v-if="!legend.node.IP"></div>
                    <i class="fa fa-user"></i>
                    Индивидуальный предприниматель
                </li>
                <li ref="node.PIF">
                    <div class="legend__line-through" v-if="!legend.node.PIF"></div>
                    <i class="fa fa-university"></i>
                    Субъект РФ, ПИФ
                </li>
                <li ref="status.green">
                    <div class="legend__line-through" v-if="!legend.status.green"></div>
                    <div class="status-color-block status-color-block__green"></div>
                    Действущее
                </li>
                <li ref="status.orange">
                    <div class="legend__line-through" v-if="!legend.status.orange"></div>
                    <div class="status-color-block status-color-block__orange"></div>
                    В стадии ликвидации
                </li>
                <li ref="status.red">
                    <div class="legend__line-through" v-if="!legend.status.red"></div>
                    <div class="status-color-block status-color-block__red"></div>
                    Ликвидированое
                </li>
            </ul>
        </div>

        <div id="affiliation-tile-node-info-wrap">
            <div id="affiliation-tile-node-info-close">
                <i class="uk-clsoe"></i>
            </div>
            <div id="affiliation-tile-node-header"></div>
            <div id="affiliation-tile-node-info"></div>
        </div>

        <div v-if="showTableList" ref="afilTable">
            <img id="canvasToImgAsBaseUrl" :style="{ display: 'block', margin: '0 auto 20px', 'border-radius': '5px', border: '1px solid #ddd', 'max-width': '70%', height: '50%' }">
            <ul class="affiliantion-modal-list" v-for="(e,i) in modalList" :key="i" :style="{'margin-left': `${5 + (40 * e.level)}px`}">
                <li v-if="e.level" class="affiliantion-modal-list__level">
                    Уровень {{e.level}}
                </li>
                <li class="affiliantion-modal-list__header">
                    <span style="color: #aaa; font-weight: 500;">
                        {{i+1}}.
                    </span>
                    <card-link v-if="e.ogrn" :ogrn="e.ogrn">{{ e.title }}</card-link>
                    <span @click="() => { showTableList = false }" v-else-if="e.inn" class="load_person_affil load_person_affil__tooltip uk-pseudolink" data-uk-modal="{ target: '#modal-company-person_affil' }" :data-inn="e.inn" :data-name="e.title">{{e.title}}</span>
                    <span v-else>{{e.title}}</span>
                </li>
                <li v-if="e.inn || e.ogrn">
                    <b v-if="e.inn">ИНН: </b>
                    <span v-if="e.inn">{{e.inn}}</span>
                    <b v-if="e.ogrn">ОГРН: </b>
                    <span v-if="e.ogrn">{{e.ogrn}}</span>
                </li>
                <li v-if="e.tooltipData && (e.tooltipData.date_start || e.tooltipData.date_stop)">
                    <span v-html="getStatusIcon(e.tooltipData.status_type)"></span>
                    <i style='padding-left: 2px; font-style: italic;'>
                        {{statusTypeText(e.tooltipData.status_type)}}
                        {{e.tooltipData.date_stop ? new Date(e.tooltipData.date_stop).toLocaleDateString() : new Date(e.tooltipData.date_start).toLocaleDateString()}}
                    </i>
                </li>
                
                <li v-if="e.tooltipData && e.tooltipData.addr_full">
                    <b>Адрес: </b>
                    <span>{{e.tooltipData.addr_full}}</span>
                </li>
                <li v-if="e.tooltipData && e.tooltipData.upr && e.tooltipData.upr.length">
                    <b v-if="e.tooltipData.upr[0].position">{{lowerButFirst(e.tooltipData.upr[0].position)}}: </b>
                    <b v-else>Руководитель: </b>
                    <card-link v-if="e.tooltipData.upr[0].ogrn" :ogrn="e.tooltipData.upr[0].ogrn">{{ e.tooltipData.upr[0].name }}</card-link>
                    <span @click="() => { showTableList = false }" v-else-if="e.tooltipData.upr[0].inn" class="load_person_affil load_person_affil__tooltip uk-pseudolink" data-uk-modal="{ target: '#modal-company-person_affil' }" :data-inn="e.tooltipData.upr[0].inn" :data-name="e.tooltipData.upr[0].name">{{lowerButFirstSentence(e.tooltipData.upr[0].name)}}</span>
                    <span v-else>{{lowerButFirstSentence(e.tooltipData.upr[0].name)}}</span>
                </li>
                <li v-if="e.tooltipData && e.tooltipData.uchr && e.tooltipData.uchr.length">
                    <b>Собственники: </b>
                    <span v-for="(uchrElm, uchrIndex) in e.tooltipData.uchr.filter((e,i) => i < 5)" :key="uchrIndex">
                        <card-link v-if="uchrElm.ogrn" :ogrn="uchrElm.ogrn">{{ uchrElm.name }}</card-link>
                        <span v-else-if="uchrElm.inn" @click="() => { showTableList = false }" class="load_person_affil load_person_affil__tooltip uk-pseudolink" data-uk-modal="{ target: '#modal-company-person_affil' }" :data-inn="uchrElm.inn" :data-name="uchrElm.name">{{uchrElm.name}}</span>
                        <span v-else>{{uchrElm.name}}</span>
                        <span v-if="uchrIndex != e.tooltipData.uchr.filter((e,i) => i < 5).length - 1" style="margin-left: -2px;">, </span>
                    </span>
                </li>
                <li v-if="e.tooltipData && e.tooltipData.okved_title">
                    <b>ОКВЭД: </b>
                    {{e.tooltipData.okved_title}}
                </li>
                <li v-if="e.tooltipData && e.tooltipData.auth_capital">
                    <b>Уставный капитал: </b>
                    {{moneyFormat(e.tooltipData.auth_capital)}} руб.
                </li>
                <li v-if="e.tooltipData && e.tooltipData.fin_years && e.tooltipData.fin_rations && e.tooltipData.fin_rations.viruchka && e.tooltipData.fin_years.length">
                    <b>Выручка за {{e.tooltipData.fin_years[e.tooltipData.fin_years.length - 1]}} год:</b>
                    {{moneyFormat(e.tooltipData.fin_rations.viruchka)}} руб.
                </li>
            </ul>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'
import { htmlStringToPrint } from '@/helpers.js'
import { base64StringToBlob } from 'blob-util'
import modal from '@/components/ui/modal/modal.vue'
import shestirenka from '@/components/ui/shestirenkaLoader.vue'
import cardLink from '@/components/ui/cardLink.vue'

export default {
    props: ['ogrn', 'group'],
    components: {
        modal,
        shestirenka,
        cardLink
    },
    data() {
        return {
            loading: true,
            modalList: [],
            showTableList: false,
            resetActive: false,
            // showFullScreen: false,
            showLegend: false,
            nodesToHide: [],
            AF: {
                nodes: [],
                edges: []
            },
            print: {
                title: 'Аффилированность',
                filename: `Affilation-${this.$props.ogrn}`
            },
            legend: {
                node: {
                    ULRos: true, // Юридическое лицо РФ
                    ULIn: true, // Иностранное юридическое лицо
                    FL: true, // Физическое лицо
                    IP: true, // Индивидуальный предприниматель
                    PIF: true // субьект РФ
                },
                status: {
                    green: true, // статус действующее
                    orange: true, // стстус в стадии ликвидации
                    red: true // статус ликвидированно
                },
                edge: {
                    UPR: true, // Руководитель
                    UPR_HISTORY: true, // бывший рук
                    UCHR: true, // Учредитель
                    UCHR_HISTORY: true,
                    REGISTRY_HOLDER: true,
                    PREDSH: true,
                    PREEM: true,
                    RODSTV: true,
                    STAFF: true
                }
            }
        }
    },
    methods: {
        showLegendToggle() {
            this.showLegend = !this.showLegend
        },
        savePdf() {
            if(this.showTableList) {
                this.getAfilTablePdf()
            } else {
                this.saveAsImage()
            }
        },
        printHTML() {
            let htmlCode
            if(this.showTableList) {
                htmlCode = htmlStringToPrint(this.print.title, this.$refs.afilTable.innerHTML, this.afilTablePrintDownloadStyles)
            } else {
                const affilationToImgBase64 = $('.vis-network canvas')[0].toDataURL()
                htmlCode = htmlStringToPrint(this.print.title, `<img src="${affilationToImgBase64}">`)
            }
            const printWin = window.open()
            printWin.document.write(htmlCode)
            printWin.document.close()
        },
        getAfilTablePdf() {
            const htmlCode = htmlStringToPrint(this.print.title, this.$refs.afilTable.innerHTML, this.afilTablePrintDownloadStyles)

            axios({
                method: 'post',
                url: `${process.env.API_PRO}/util/html-to-pdf?demo=1`,
                responseType: 'blob',
                data: {
                    params: {
                        ogrn: this.$props.ogrn,
                        filename: `${this.print.filename}.pdf`
                    },
                    html: htmlCode
                }
            }).then(result => {
                const filenamePDF = `${this.print.filename}.pdf`
                if(navigator.msSaveOrOpenBlob) {
                    navigator.msSaveOrOpenBlob(result, filenamePDF)
                } else {
                    var link = document.createElement('a')
                    link.style = 'display: none'
                    link.href = window.URL.createObjectURL(result.data)
                    link.download = filenamePDF
                    document.body.appendChild(link)
                    link.click()
                }
            })
        },
        saveAsImage() {
            // Преобразуем содержимое канваса в base64 строку
            var affilBase64Img = $('.vis-network canvas')[0].toDataURL().replace(/data:image\/png;base64,/, '')
            // Преобразуем base64 в blob
            var blob = base64StringToBlob(affilBase64Img, 'image/png')
            // Создаём ссылку по которой будет загружаться blob
            var link2Download = document.createElement('a')
            if(navigator.appVersion.toString().indexOf('.NET') > 0) { // Для Internet Explorer
                window.navigator.msSaveBlob(blob, `${this.print.filename}.png`)
            } else { // Для остальных браузеров
                var fileURL = window.URL.createObjectURL(blob)
                link2Download.href = fileURL
                link2Download.download = `${this.print.filename}.png`
                document.body.appendChild(link2Download) 
                link2Download.click()
                link2Download.remove()
            }
        }
    },
    computed: {
        afilTablePrintDownloadStyles() {
            return '#canvasToImgAsBaseUrl { max-width: 100% !important }'
        },
        auth() {
            return window.auth
        },
        fullScreenStyleObject() {
            return {
                af: {
                    position: 'unset',
                    top: 'auto',
                    left: 'auto',
                    zIndex: '',
                    width: 'auto',
                    height: '100%',
                    background: 'none'
                }
            }
        },
        userGroups() {
            return this.$store.state.user.groups.filter(e => e.rules.monitoring_fl)
        }
    },
    mounted() {
        var SELF = this

        var affilation = {}

        affilation.init = () => {
            AF.init()
            // $(document).on('click', '.afil-network-show-hide-links', function() {
            //     console.log('afil-network-show-hide-links')
            //     AF.show_limit = 999999
            //     AF.renderCompany(AF.hide)
            // })

            affilation_test()
        }

        var AF = {
            stabilizing: true,
            handle: null,
            network: null,
            data: null,
            nodes: null,
            edges: null,
            options: {
                interaction: {
                    hover: true
                },
                physics: {
                    enabled: !0,
                    barnesHut: {
                        gravitationalConstant: -8308,
                        centralGravity: .5
                    },
                    maxVelocity: 10,
                    minVelocity: .5,
                    timestep: 1
                },
                nodes: {  shape: 'dot', size: 20, borderWidth: 0, font: { face: 'Monospace', size: 10, color: '#000000', strokeWidth: 3, strokeColor: '#ffffff'  }},
                edges: {  width: 1, color: '#cbc7cd', font: { face: 'Monospace', size: 10, color: '#000', strokeWidth: 3, strokeColor: '#ffffff' }},
                groups: {
                    ULRos: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf1ad', color: '#999' }}, // Юридическое лицо РФ,
                    ULIn: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf0ac', color: '#999' }}, // Иностранное юридическое лицо,
                    FL: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf183', color: '#999' }}, // Физическое лицо
                    IP: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf007', color: '#999' }}, // Индивидуальный предприниматель
                    RFSubMO: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf19c', color: '#999' }}, // субьект РФ,
                    UL: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf1ad', color: '#999' }}, // Управляющая компания
                    PIF: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf19c', color: '#999' }}, // Паевый инвестиционный фонд
                    UprCompPIF: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf19c', color: '#999' }}, // Управляющая компания ПИФ-а
                    WTF: { shape: 'icon', icon: { face: 'FontAwesome', size: 30, code: '\uf21b', color: '#999' }} // Неизвестный тип лица
                }
            },
            hide: {
                links: [],
                nodes: []
            },
            show_edges: [],
            show_id: [],
            show_limit: 1000,
            mainCompany: this.$props.ogrn,
            getNode: function(id) {
                var result = false

                for(var i in AF.data.nodes._data) {
                    var node = AF.data.nodes._data[i]
                    if(node.id == id) {
                        result = node
                        break
                    }
                }

                return result
            },
            getEdge: function(from, to) {
                var result = false
                for(var i in AF.data.edges._data) {
                    var edge = AF.data.edges._data[i]
                    if(edge.from == from && edge.to == to) {
                        result = edge
                        break
                    }
                    if(edge.to == from && edge.from == to) {
                        result = edge
                        break
                    }
                }
                return result
            }
        }

        AF.init = function() {
            AF.handle = document.getElementById('af')
            AF.nodes = new vis.DataSet()
            AF.edges = new vis.DataSet()
            AF.data = { nodes: AF.nodes, edges: AF.edges }

            AF.network = new vis.Network(AF.handle, AF.data, AF.options)
            AF.getCompany(AF.mainCompany, 0)

            // Заливаем фон канваса белым, чтобы при сохранении картинкой, не было прозрачного слоя
            AF.network.on('beforeDrawing', function(ctx) {
                // save current translate/zoom
                ctx.save()
                // reset transform to identity
                ctx.setTransform(1, 0, 0, 1, 0, 0)
                // fill background with solid white
                ctx.fillStyle = '#ffffff'
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
                // restore old transform
                ctx.restore()
            })

            AF.network.on('click', function(e) {
                var self = AF.getNode(e.nodes[0])

                if(false == !!self.id) return
                if(self.loading) return
                if(self.noclick) return

                if(self.expanded) {
                    if(self.group == 'ULRos') {
                        window.open('/company/' + self.id, '_blank')
                    }
                    if(self.group == 'IP') {
                        window.open('/person/' + self.id, '_blank')
                    }
                } else {
                    const id_lenth = self.id.toString().length
                    if(id_lenth == 9 || id_lenth == 10 || id_lenth == 11 || id_lenth == 12 || id_lenth == 13 || id_lenth == 15) {
                        AF.nodes.update([{ id: self.id, loading: true, label: self.label+'...' }])
                        AF.getCompany(self.id, self.level)
                    }
                }

                return
            })
        }

        AF.getCompany = (id, level) => {
            if(id) {
                $.ajax({
                    type: 'GET',
                    url: `${process.api.lic}/company/${id}/afil?type=company&demo=1`,
                    headers: { 'Authorization': 'Bearer ' + window.apiSession },
                    cache: false,
                    success: result => {
                        this.loading = false
                        if(AF.mainCompany == id && ( result.status.code != 200 || result.data.length == 0 ) ) {
                            // если ничего не пришло по основной базовой ноде
                            $('#afil-network').hide()
                        } else if(result.status.code != 200 || result.data.length == 0 ) {
                            // если ничего не пришло
                            AF.nodes.update([{ id, loading: false, expanded: false, label: AF.getNode(id).label.replace('...', '') }])
                        } else {
                            // this.showFullScreen = true
                            AF.nodes.update([{ id, nodesShown: true, expanded: true }])
                            // удаляем все ноды если id это оргн компании
                            if(id == AF.mainCompany && level == 0) {
                                AF.nodes.remove(Object.keys(AF.nodes._data))
                                AF.edges.remove(Object.keys(AF.edges._data))
                                this.resetActive = false
                            }
                            // обычный рендер
                            AF.renderCompany(result.data, level)
                        }
                    },
                    error: function(error) {
                        this.loading = false
                        if(AF.mainCompany == id) {
                            $('#afil-network').hide()
                        } else {
                            AF.nodes.update([{ id: id, loading: false, expanded: false, label: AF.getNode(id).label.replace('...', '') }])
                        }
                        console.log(error)
                    }
                })
            }

            return
        }

        function renderAffilationToolTipInfo(nodeId) {
            // alert("renderAffilationToolTipInfo Была нажата верхняя кнопка: " + nodeId)
            $.ajax({
                method: 'GET',
                url: process.env.API_PRO + '/company/' + nodeId + '/index',
                headers: {
                    'Authorization': 'Bearer ' + window.apiSession
                },
                processData: false,
                contentType: 'application/json',
                dataType: 'json',
                success: function(e) {

                    $('#affiliation-tile-node-header').html('')
                    $('#affiliation-tile-node-info').html('')

                    const header = "<a target='_blank' href='/company/" + e.data.ogrn + "'>" + (e.data.name_short ? e.data.name_short : e.data.name_full) + '</a>'
                    const statusIcon = SELF.getStatusIcon(e.data.status_type)
                    const statusText = (status_type) => {
                        switch(status_type) {
                            case 0:
                                return 'ликвидировано'
                            case 1:
                                return 'зарегистрировано'
                            case 3:
                                return 'зарегистрировано'
                            case 2:
                                return 'в стадии ликвидации'
                        }
                    }

                    const dirName = e.data.upr.length ? e.data.upr[0].name ? e.data.upr[0].name.split(' ').map(e => SELF.lowerButFirst(e)).join(' ') : '' : ''
                    const dirPosition = e.data.upr.length ? e.data.upr[0].position ? SELF.lowerButFirst(e.data.upr[0].position) : '' : ''
                    const dirOgrn = e.data.upr.length ? e.data.upr[0].ogrn ? e.data.upr[0].ogrn : '' : ''

                    var info = ''
                    info += '<div><b>ИНН: </b>' + e.data.inn + ' <b>ОГРН: </b>' + e.data.ogrn + '</div>'
                    info += e.data.date_start ?  `<div>${statusIcon}<i style='padding-left: 5px; font-style: italic;'>${statusText(e.data.status_type)}: </i>${e.data.date_stop ? new Date(e.data.date_stop).toLocaleDateString() : new Date(e.data.date_start).toLocaleDateString()}</div>` : ''
                    info += e.data.addr_full ? `<div><b>Адрес: </b> ${e.data.addr_full}</div>` : ''
                    info += e.data.upr.length ? `<div><b>${dirPosition ? dirPosition : 'Управляющая компания'}: </b>${dirPosition ? `<span style="font-weight: bold;" class="load_person_affil load_person_affil__tooltip uk-pseudolink" data-uk-modal="{ target: '#modal-company-person_affil' }" data-inn="${e.data.inn}" data-name="${dirName}">${dirName}</span>` : (dirOgrn ? `<a target="_blank" href="${dirOgrn}">${dirName}</a>` : dirName)}</div>` : ''
                    info += e.data.uchr.length ? '<div><b>Собственники: </b>' + (e.data.uchr.filter((e, i) => i < 5).map(function(e) { return (e.ogrn ? `<a style="font-weight: bold;" target="_blank" href="${e.ogrn}">${e.name}</a>` : (e.inn ? `<span style="font-weight: bold;" class="load_person_affil load_person_affil__tooltip uk-pseudolink" data-uk-modal="{ target: '#modal-company-person_affil' }" data-inn="${e.inn}" data-name="${e.name}">${e.name}</span>` : `<span>${e.name}</span>`))})).join(', ') +'</div>' : ''
                    info += e.data.okved_title ? '<div><b>ОКВЭД: </b>' + e.data.okved_title + '</div>' : ''
                    info += e.data.auth_capital ? '<div><b>Уставный капитал: </b>' + window.moneyFormat(e.data.auth_capital) + ' руб.</div>' : ''
                    info += (e.data.fin_rations && e.data.fin_rations.viruchka && e.data.fin_years.length) ? `<b>Выручка за ${e.data.fin_years.pop()} год:</b> ${window.moneyFormat(e.data.fin_rations.viruchka)} руб` : ''

                    $('#affiliation-tile-node-header').html(header)
                    $('#affiliation-tile-node-info').html(info)
                    $('#affiliation-tile-node-info-wrap').show()
                }
            })
        }

        // $(document).on('click', '.load_person_affil__tooltip', () => {
        //     this.showFullScreen = false
        // })

        function affilation_test() {
            $('#affiliation-tile-node-info-wrap .lnr-cross').click(function() {
                $('#affiliation-tile-node-info-wrap').hide()
            })

            $(document).on('click', function(e) {
                if(!$('#affiliation-tile-node-info-wrap').is(e.target) && !$('#affiliation-tile-node-info-wrap').has(e.target).length) {
                    $('#affiliation-tile-node-info-wrap').hide()
                }
            })

            AF.network.on('afterDrawing', function(ctx) {

                var allNodePositions = AF.network.getPositions()
                var offsetMenuX = 28
                var offsetMenuY = 25
                
                var circleRadius = 8

                function drawHideNodesMenu(x, y) {
                    ctx.strokeStyle = '#999'
                    ctx.fillStyle = '#fff'
                    ctx.lineWidth = 1
                    ctx.beginPath()

                    ctx.arc(x + 24, y - 19, 7, 0, Math.PI * 2)
                    ctx.fill()
                    ctx.stroke()
                    
                    ctx.moveTo(x + 20, y - 19)
                    ctx.lineTo(x + 28, y - 19)
                    ctx.stroke()
                }

                function drawShowNodesMenu(x, y) {
                    ctx.strokeStyle = '#999'
                    ctx.fillStyle = '#fff'
                    ctx.lineWidth = 1
                    ctx.beginPath()

                    ctx.arc(x + 24, y - 19, 7, 0, Math.PI * 2)
                    ctx.fill()
                    ctx.stroke()
                    
                    ctx.moveTo(x + 20, y - 19)
                    ctx.lineTo(x + 28, y - 19)
                    ctx.stroke()

                    ctx.moveTo(x + 24, y - 15)
                    ctx.lineTo(x + 24, y - 23)
                    ctx.stroke()
                }

                function drawTooltipMenu(x, y) {
                    ctx.strokeStyle = '#999'
                    ctx.fillStyle = '#fff'
                    ctx.lineWidth = 2

                    ctx.beginPath()
                    ctx.moveTo(x - 4, y)

                    ctx.arc(x + 7, y + 2, 2, Math.PI * 1.5, 0)
                    ctx.arc(x + 6, y + 8, 2, 0, Math.PI / 2)
                    ctx.lineTo(x + 4, y + 10)
                    ctx.lineTo(x + 4, y + 13)
                    ctx.lineTo(x, y + 10)
                    ctx.arc(x - 5, y + 8, 2, Math.PI / 2, Math.PI )
                    ctx.arc(x - 6, y + 2, 2, Math.PI, Math.PI * 1.5)

                    ctx.closePath()
                    ctx.stroke()
                    ctx.fill()
                    
                    ctx.beginPath()
                    ctx.arc(x - 4, y + 5, 0.5, 0, Math.PI * 2)
                    ctx.stroke()

                    ctx.beginPath()
                    ctx.arc(x + 0.5, y + 5, 0.5, 0, Math.PI * 2)
                    ctx.stroke()

                    ctx.beginPath()
                    ctx.arc(x + 5, y + 5, 0.5, 0, Math.PI * 2)
                    ctx.stroke()
                }

                for(const e in allNodePositions) {
                    var elem = allNodePositions[e]
                    // NOTE отрисовка меню тултипа
                    if(elem && AF.getNode(e).group != 'FL' && AF.getNode(e).group != 'ULIn') {
                        drawTooltipMenu(elem.x - offsetMenuX, elem.y - offsetMenuY)
                    }

                    if(AF.getNode(e).expanded) {
                        // отрисовка меню закрытия (минус)
                        if(AF.getNode(e).nodesShown && AF.mainCompany != e) {
                            drawHideNodesMenu(elem.x, elem.y)
                        }
    
                        // отрисовка меню плюсик
                        if( !(AF.getNode(e).nodesShown) && AF.mainCompany != e) {
                            drawShowNodesMenu(elem.x, elem.y)
                        }
                    }
                }
                
                // NOTE "Клик по канвасу"
                $('#afil-network .vis-network canvas').off('click')
                $('#afil-network .vis-network canvas').click(function(event) {
                    var clickDOMPositionInsideCanvas = {
                        x: Math.floor(event.originalEvent.pageX - $(this).offset().left),
                        y: Math.floor(event.originalEvent.pageY - $(this).offset().top)
                    }

                    // если клик находится в пределах массива *allNodePositions*
                    for(var node in allNodePositions) {
                        var positions = {
                            canvasPositions: {
                                x: allNodePositions[node].x,
                                y: allNodePositions[node].y
                            },
                            get nodeDOMPositions() {
                                return {
                                    x: Math.floor(AF.network.canvasToDOM(this.canvasPositions).x),
                                    y: Math.floor(AF.network.canvasToDOM(this.canvasPositions).y)
                                }
                            },
                            get tooltipCircleCanvasPosition() {
                                return {
                                    x: this.canvasPositions.x - offsetMenuX,
                                    y: this.canvasPositions.y - offsetMenuY + 5
                                }
                            },
                            get closeCircleCanvasPosition() {
                                return {
                                    x: this.canvasPositions.x + 24,
                                    y: this.canvasPositions.y - 19
                                }
                            },
                            get tooltipCircleDOMPosition() {
                                return {
                                    x: Math.floor(AF.network.canvasToDOM(this.tooltipCircleCanvasPosition).x),
                                    y: Math.floor(AF.network.canvasToDOM(this.tooltipCircleCanvasPosition).y)
                                }
                            },
                            get closeCircleDOMPosition() {
                                return {
                                    x: Math.floor(AF.network.canvasToDOM(this.closeCircleCanvasPosition).x),
                                    y: Math.floor(AF.network.canvasToDOM(this.closeCircleCanvasPosition).y)
                                }
                            },
                            get conditionals() {
                                var tooltip = this.tooltipCircleDOMPosition
                                var close = this.closeCircleDOMPosition
                                var cl = clickDOMPositionInsideCanvas
                                var rad = circleRadius

                                return {
                                    tooltip: cl.x >= (tooltip.x - rad) && cl.x <= (tooltip.x + rad) && cl.y >= (tooltip.y - rad) && cl.y <= (tooltip.y + rad),
                                    close: cl.x >= (close.x - rad) && cl.x <= (close.x + rad) && cl.y >= (close.y - rad) && cl.y <= (close.y + rad)
                                }
                            }
                        }

                        if(positions.conditionals.tooltip) {
                            renderAffilationToolTipInfo(node)
                        }

                        // NOTE "Клик по минусику закрытия / открытия нод"
                        if(positions.conditionals.close) {
                            // если у ноды есть параметр nodesShown
                            if(AF.getNode(parseInt(node)).nodesShown) {

                                SELF.nodesToHide = SELF.nodesToHide.concat(parseInt(node))
                                AF.nodes.update({ ...AF.getNode(parseInt(node)), nodesShown: false })
                                findAndHideRequsively(findAllHiddenNodesFromLegendStatus(), 5)
                            } else {
                                SELF.nodesToHide = SELF.nodesToHide.filter(e => e != parseInt(node))
                                AF.nodes.update({ ...AF.getNode(parseInt(node)), nodesShown: true })
                                findAndHideRequsively(findAllHiddenNodesFromLegendStatus(), 5)
                            }
                        }
                    }
                })
            })
        }

        AF.renderCompany = (company, main_level) => {

            var id = ''
            var color = ''
            var title = ''
            var group = ''
            var expanded = false
            var loading = false
            var companyNode = false
            var statusText = ''
            var level = null

            AF.show_edges = []	// сбрасываем счетчик показанных связей

            // Устанавливаем лимит на отображение связей, для этого наполняем массив скрытых линков
            // и запоминаем все ID nodes, которые нужно показывать
            for(const i in company.links) {
                const item = company.links[i]

                if(AF.show_edges.length < AF.show_limit && item.rel_id && item.id) {
                    AF.show_edges.push(item)
                    if(AF.show_id.indexOf(item.rel_id) == -1) AF.show_id.push(item.rel_id)
                    if(AF.show_id.indexOf(item.id) == -1) AF.show_id.push(item.id)
                } else {
                    AF.hide.links.push(item)
                }
            }

            for(const i in company.nodes) {
                const item = company.nodes[i]

                // Проверяем, нужно ли показывать ноду, если нет, переходим к следующей
                if(AF.show_id.indexOf(item.id) == -1) {
                    AF.hide.nodes.push(item)
                    continue
                }

                id = item.id
                title = item.title_short ? item.title_short : item.title_full
                group = item.rec_type
                expanded = id == AF.mainCompany
                loading = false
                level = id == AF.mainCompany ? 0 : main_level + 1
                switch(item.status) {
                    case 0:
                        color = '#e85445'
                        statusText = 'red'
                        break
                    case 1:
                        color = '#2ecc71'
                        statusText = 'green'
                        break
                    case 2:
                        color = '#e7843c'
                        statusText = 'orange'
                        break
                    default:
                        color = '#999'
                        statusText = false
                        break
                }

                // проверяем, нет ли уже такой компании
                companyNode = AF.getNode(id)
                if(false == !!companyNode) {
                    AF.nodes.add({
                        data: {
                            title,
                            level,
                            inn: item.inn,
                            ogrn: item.ogrn
                        },
                        statusText,
                        mass: 0.5,
                        id,
                        label: title,
                        group,
                        expanded,
                        loading,
                        level,
                        icon: { color }
                    })
                    this.AF.nodes.push({ mass: 0.5, id, label: title, group, expanded, loading, icon: { color }})
                } else {
                    AF.nodes.update([{ id, label: title, group, loading, icon: { color }}])
                }
            }

            for(const i in AF.show_edges) {
                const item = AF.show_edges[i]
                var from, to

                // выбор направления стрелки
                if(item.rel_direction == 'back') {
                    from = item.rel_id
                    to = item.id
                } else {
                    from = item.id
                    to = item.rel_id
                }
                title = item.rel_type == 'UCHR' && item.dolya_procent ? item.dolya_procent + '%' : (item.rel_type_title ? (item.relative_type ? item.relative_type : item.rel_type_title) : '')
                // Если руководитель, то ничего не пишем:
                title = item.rel_type == 'UPR' || item.rel_type == 'UPR_HISTORY' ? '' : title

                switch(item.rel_type) {
                    case 'UPR': color = '#2980b9'; break
                    case 'UPR_HISTORY':  color = '#2980b9'; break
                    default: color = to == AF.mainCompany ? '#777' : '#cbc7cd'
                }

                var dashes = item.rel_type == 'PREEM' || item.rel_type == 'PREDSH' || item.rel_type == 'UPR_HISTORY' || item.rel_type == 'UCHR_HISTORY'

                // Существование текущей ветки
                var currentEdge = AF.getEdge(from, to) || AF.getEdge(to, from)

                // Если нет, то добавляем, иначе обновляем
                if(false == !!currentEdge) {
                    AF.edges.add({
                        id: `${from}_${to}`,
                        arrows: { to: { scaleFactor: 0.5 }},
                        label: title,
                        rec_type: item.rec_type,
                        rel_type: item.rel_type,
                        from,
                        to,
                        color,
                        dashes
                    })
                    this.AF.edges.push({ id: from+'_'+to,  from: from, to: to, arrows: { to: { scaleFactor: 0.5 }}, label: title, color: color, dashes: dashes })
                } else {
                    color = currentEdge.color == '#2980b9' ? '#2980b9' : color								// выставляем приоритет цвета (если цвет руководителя, то сохраняем, иначе текущий)
                    title = currentEdge.label != '' && (item.rel_type == 'UPR' || item.rel_type == 'UPR_HISTORY') ? currentEdge.label : title	// Если подпись есть и она не "руководитель", то сохраняем старую. Иначе текущая.

                    AF.edges.update({ id: currentEdge.id, from: currentEdge.from, to: currentEdge.to, arrows: { to: { scaleFactor: 0.5 }}, label: title, color: color, dashes: dashes })
                }
            }

            // Включаем кнопку "Показать все"
            // if ( AF.show_edges.length < AF.show_limit ) {
            //     $(".afil-network-show-hide-links" ).remove();
            // } else if(AF.hide.links.length == 0 || AF.hide.nodes.length == 0){
            //     $(".afil-network-show-hide-links" ).remove();
            // } else {
            //     $( ".afil-network_buttons").find(".afil-network-show-hide-links").show();
            // }

            findAndHideRequsively(findAllHiddenNodesFromLegendStatus())
        }
        
        affilation.init()

        var self = this

        // NOTE "Рекурсивный поиск нод"
        const findAndHideRequsively = (hiddenNodes, recursionCounter = 5, nodesToHideFromState = self.nodesToHide) => {
            let hiddenEdges = Object.values(AF.data.edges._data)
                .filter(e => !(e.to == AF.mainCompany || e.from == AF.mainCompany))
                .filter(e => AF.getNode(e.from))
                .filter(e => AF.getNode(e.to))

            hiddenNodes = Array.from(new Set([
                ...hiddenNodes,
                ...hiddenEdges
                    .filter(e => hiddenNodes.includes(e.from))
                    .filter(e => (AF.getNode(e.to).level > AF.getNode(e.from).level))
                    .map(e => e.to),
                ...hiddenEdges
                    .filter(e => hiddenNodes.includes(e.to))
                    .filter(e => (AF.getNode(e.from).level > AF.getNode(e.to).level))
                    .map(e => e.from)
            ].filter(e => !nodesToHideFromState.includes(e) )))

            hiddenEdges = Array.from(new Set([
                ...Object.values(AF.data.edges._data)
                    .filter(e => hiddenNodes.includes(e.from) || hiddenNodes.includes(e.to))
                    .map(e => e.id)
            ]))

            AF.nodes.update(Object.values(AF.data.nodes._data).map(e => ({ ...e, hidden: hiddenNodes.includes(e.id) })))
            AF.edges.update(Object.values(AF.data.edges._data).map(e => ({ ...e, hidden: hiddenEdges.includes(e.id) })))

            if(recursionCounter > 0) {
                findAndHideRequsively(hiddenNodes, recursionCounter - 1)
            }
        }

        // NOTE "Поиск всех закрытых нод"
        const findAllHiddenNodesFromLegendStatus = () => {
            
            const legendStateNodeGroup = Object.keys(this.legend.node).filter(e => !this.legend.node[e])
            const legendStateStatus = Object.keys(this.legend.status).filter(e => !this.legend.status[e])
            const legendStateEdge = Object.keys(this.legend.edge).filter(e => !this.legend.edge[e])

            // ноды которые нужно скрыть
            const filterNodeIds = Object.values(AF.data.nodes._data)
                .filter(e => e.id != AF.mainCompany)
                .filter(e => legendStateNodeGroup.includes(e.group) || legendStateStatus.includes(e.statusText))
                .map(e => e.id)

            const hiddenEdges = Object.values(AF.data.edges._data)
                .filter(e => e.id != AF.mainCompany)
                .filter(e => legendStateEdge.includes(e.rel_type))
            
            const filterNodesFromEdges = Array.from(new Set([
                ...hiddenEdges
                    .filter(e => (AF.getNode(e.to).level > AF.getNode(e.from).level))
                    .map(e => e.to),
                ...hiddenEdges
                    .filter(e => (AF.getNode(e.from).level > AF.getNode(e.to).level))
                    .map(e => e.from)
            ]))

            const nodesToHide = Array.from(new Set([...filterNodeIds, ...filterNodesFromEdges, ...this.nodesToHide]))

            return nodesToHide
        }

        Object.keys(this.$refs).forEach(e => {
            var elem = this.$refs[e]
            elem.addEventListener('click', () => {
                const type = e.split('.')[0]
                const group = e.split('.')[1]
                this.legend[type][group] = !this.legend[type][group]

                findAndHideRequsively(findAllHiddenNodesFromLegendStatus())
            })
        })

        $(document).on('click', '.afil__resetDefault', () => {
            this.resetActive = true
            AF.show_limit = 30
            AF.show_id = AF.show_id.slice(0, 25)
            this.nodesToHide = []
            AF.getCompany(AF.mainCompany, 0)
        })

        $(document).on('click', '.afil-show-list', () => {

            const listOfIdsOfAllNotHiddenNodes =
                Object.values(AF.data.nodes._data)
                    .filter(e => !e.hidden)
                    .map(e => `&idlist[]=${e.id}`)
                    .join('')

            axios({
                method: 'GET',
                url: `${process.env.API_PRO}/company/null/index?demo=1${listOfIdsOfAllNotHiddenNodes}`,
                headers: this.$store.state.axios.headers
            }).then(e => {
                if(e.data.status.code == 200) {
                    this.showTableList = true
                    this.modalList = Object.values(AF.data.nodes._data)
                        .filter(e => !e.hidden)
                        .map(elm => ({ ...elm.data, tooltipData: e.data.data.find(singleTooltipElement => elm.data.inn == singleTooltipElement.inn || elm.data.ogrn == singleTooltipElement.ogrn) }))
                        .sort((a, b) => a.level > b.level)
                }
            }).then(() => {
                const canvasToImgAsBaseUrl = document.getElementById('canvasToImgAsBaseUrl')
                if(canvasToImgAsBaseUrl) {
                    canvasToImgAsBaseUrl.setAttribute('src', document.querySelector('#af .vis-network canvas').toDataURL('image/png', 1))
                }
            })
        })
    }
}
</script>

<style scoped lang="scss">
@import 'styles/global-variables.sass';

.uk-pseudolink {
    @include pseudolink
}

#afil-network {
    height: 100%;
    position: relative;
    .full-screen-menu {
        &__item {
            @extend .blue-red-hover-text;
            font-weight: 400;
            display: block;
            text-align: right;
            font-size: 18px;
            margin: 2px 0;
        }
    }
}
.stay-on-this-row {
    display: inline;
}

@media screen and (max-width: 960px) {
    .affiliantion-modal-list__level {
        display: none;
    }
    .affiliantion-modal-list {
        margin-left: 5px !important;
    }
}

ul.affiliantion-modal-list > li.affiliantion-modal-list__level {
    position: absolute;
    right: 10px;
    top: 0;
    font-weight: 700;
    color: #aaa;
}

ul.affiliantion-modal-list {
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 5px 10px;
    position: relative;
}

ul.affiliantion-modal-list:nth-child(2n) {
    background: #eee;
}

ul.affiliantion-modal-list > li.affiliantion-modal-list__header {
    color: #1e8bc3;
    font-weight: 600;
    font-family: Roboto Slab, Arial, Helvetica, sans-serif;
}
ul.affiliantion-modal-list > li > a {
    border: 0;
}
ul.affiliantion-modal-list > li {
    margin: 5px 0;
}

/* resetActive */
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

#afil-legend .legend__line-through {
    position: absolute;
    width: 95%;
    height: 3px;
    background-color: #888;
    top: 14px;
    z-index: 12;
}

#afil-legend .afil-legend-edge {
    width: 90px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-top-width: 3px;
    border-color: #ccc;
    border-style: solid;
    position: relative;
}

#afil-legend .afil-legend-edge__text {
    position: absolute;
    left: 0;
    top: -14px;
    font-size: 10px;
    width: 100%;
    text-align: center;
}

#afil-legend .afil-legend-edge__upr {
    border-color: #2d82ba;
}

#afil-legend .afil-legend-edge__lupr {
    border-color: #2d82ba;
    border-style: dashed;
}

#afil-legend .afil-legend-edge__luchr,
#afil-legend .afil-legend-edge__predsh,
#afil-legend .afil-legend-edge__preem {
    border-style: dashed;
}

#afil-legend .status-color-block {
    width: 22px;
    height: 14px;
    margin-right: 3px;
    display: inline-block;
    position: relative;
    top: 2px;
}

#afil-legend .status-color-block__green {
    background-color: #32cd74;
}

#afil-legend .status-color-block__orange {
    background-color: #e78640;
}

#afil-legend .status-color-block__red {
    background-color: #e85749;
}

#afil-legend .fa {
    font-family: "FontAwesome";
    display: inline-block;
    width: 25px;
    text-align: center;
    font-weight: normal;
    color: #9b9b9b;
    font-size: 22px;
    position: relative;
    top: 2px;
}

#afil-legend {
    font-size: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px gray;
    padding: 10px;
    z-index: 1;
}

#afil-legend ul {
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;
    width: 50%;
}

#afil-legend > ul > li::selection,
#afil-legend > ul > li div::selection {
    background-color: transparent;
    color: #333;
}

#afil-legend > ul > li {
    cursor: pointer;
    line-height: 26px;
    padding: 2px 5px;
    position: relative;
}

#afil-legend > ul > li > div {
    display: inline-block;
}

#afil-legend > ul > li:hover {
    background-color: rgba(0,0,0,0.1);
}

.full-screen-menu {
    position: absolute;
    right: 5px;
    z-index: 1000000001;
    font-size: 20px;
}

#af-wrap {
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    position: relative;
    margin: 0 auto;
    background-color: #fafafa;
}
</style>

<style>
    #afil-network-demo-msg {
        text-align: center;
        font-size: 17px;
        color: #777;
    }

    #afil-network {
        position: relative;
    }

    #affiliation-tile-node-info-wrap {
        color: black;
        display: none;
        width: 350px;
        height: auto;
        background-color: #f5f5f5;
        border: 1px solid #c5c5c5;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
    }

    #affiliation-tile-node-info-wrap .lnr-cross {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    #affiliation-tile__node-hover-menu i {
        top: 0;
        left: 0;
        z-index: 1234;
        position: absolute;
        display: none;
        color: #999;
        pointer-events: all;
        cursor: pointer;
        font-size: inherit;
        background-color: #fff;
        border-radius: 100%;
        display: inline-block;
        font-size: 12px;
        width: calc(12px * 2);
        height: calc(12px * 2);
        line-height: calc(12px * 2);
        text-align: center;
        border: 1px solid #999;
    }

    #affiliation-tile__node-hover-menu i:hover {
        color: red;
        border: 1px solid red;
    }

    #afil-network > div.uk-panel.uk-panel-box {
        overflow: hidden;
    }

    #affiliation-tile-node-header {
        font-weight: bold;
        font-size: 14px;
        color: #1e8bc3;
        font-family: Roboto Slab, Arial, Helvetica, sans-serif;
        max-width: 95%;
    }

    #affiliation-tile-node-info-close {
        max-width: 5%;
        line-height: 0;
        float: right;
        font-size: 25px;
    }

    #affiliation-tile-node-info {
        font-size: 12px;
    }

    #affiliation-tile-node-info > div:first-child {
        margin-top: 10px;
        font-style: italic;
    }

    #affiliation-tile-node-info > div {
        margin: 5px 0;
    }
</style>
