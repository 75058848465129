import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        leasing_list: {},
        pagination: {},
        active: 0
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootGetters }, { offset = 0, limit = 10 }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'leasing',
                data: { 
                    ogrn: rootGetters['card/ogrn'],
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'active', value: e?.data?.data?.activity })
                commit('setStateObject', { name: 'leasing_list', value: e?.data?.data?.list })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    }
}