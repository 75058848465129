var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.afilArray, function (e, i) {
        return _c("tr", { key: i }, [
          _c("td", [_vm._v(_vm._s(e.inn ? e.inn : "—"))]),
          _vm._v(" "),
          _c(
            "td",
            [
              e.status_type
                ? [_c("status-icon", { attrs: { status: e.status_type[0] } })]
                : typeof e.status == "number"
                ? [_c("status-icon", { attrs: { status: e.status } })]
                : _vm._e(),
              _vm._v(" "),
              e.ogrn
                ? [
                    _c("card-link", { attrs: { ogrn: e.ogrn } }, [
                      _vm._v(_vm._s(e.name_short ? e.name_short : e.name_full)),
                    ]),
                    _vm._v(" "),
                    _c("tooltip", { attrs: { ogrn: e.ogrn } }),
                  ]
                : _c("span", [
                    _vm._v(_vm._s(e.name_short ? e.name_short : e.name_full)),
                  ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("td", [
            e.stakeholder_type == "CURRENT" && !_vm.checkInt(e.date_stop)
              ? _c("span", [_vm._v("по н.в.")])
              : e.stakeholder_date_end
              ? _c("span", { staticClass: "per_nowrap" }, [
                  _vm._v(
                    "по " +
                      _vm._s(_vm.date_format(e.stakeholder_date_end)) +
                      " г."
                  ),
                ])
              : _vm.checkInt(e.date_stop)
              ? _c("span", { staticClass: "per_nowrap" }, [
                  _vm._v(
                    "\n                    по " +
                      _vm._s(
                        _vm.date_format(
                          new Date(
                            e.date_stop.toString().slice(0, 4),
                            e.date_stop.toString().slice(4, 6) - 1,
                            e.date_stop.toString().slice(6)
                          )
                        )
                      ) +
                      " г.\n                "
                  ),
                ])
              : e.year
              ? _c("span", { staticClass: "per_nowrap" }, [
                  _vm._v(_vm._s(e.year)),
                ])
              : _c("span", { staticClass: "per_nowrap" }, [
                  _vm._v("прекращено"),
                ]),
          ]),
          _vm._v(" "),
          _c("td", [
            e.addr_full
              ? _c("span", [_vm._v(_vm._s(e.addr_full))])
              : e.address
              ? _c("span", [_vm._v(_vm._s(e.address))])
              : _vm._e(),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ИНН")]),
        _vm._v(" "),
        _c("th", [_vm._v("Наименование")]),
        _vm._v(" "),
        _c("th", [_vm._v("Период")]),
        _vm._v(" "),
        _c("th", [_vm._v("Адрес")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }