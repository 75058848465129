import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        sro_list: []
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootGetters }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'sro',
                data: { 
                    action: 'list',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'sro_list', value: e?.data?.data })

                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    }
}