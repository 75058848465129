import axios from 'axios'
import { set } from 'lodash'
import router from '../../router'

import trademarkStore from '@/components/card/tabs/trademarks/trademarkStore.js'
import sroStore from '@/components/card/tabs/license/sroList/sroStore.js'
import taxDeductionsStore from '@/components/card/tabs/economics/taxDeductions/taxDeductionsStore.js'
import leasingStore from '@/components/card/tabs/leasing-pledge/leasing/leasingStore.js'
import pledgeStore from '@/components/card/tabs/leasing-pledge/pledge/pledgeStore.js'
import inspectionsStore from '@/components/card/tabs/inspections/inspectionsStore.js'
import blockingsStore from '@/components/card/tabs/blockings/blockingsStore.js'
import complexReportStore from '@/components/card/tabs/KO/complexReportStore.js'
import customerStore from '@/components/card/tabs/tenders/customer/customerStore.js'
import participantStore from '@/components/card/tabs/tenders/participant/participantStore.js'
import businessStore from '@/components/card/tabs/economics/businessCoef/businessStore.js'
import financialStore from '@/components/card/tabs/economics/financialStatements/financialStore.js'
import arbitrageStore from '@/components/card/tabs/arbitrage/arbitrageStore.js'
import licenseStore from '@/components/card/tabs/license/licenseList/licenseStore.js'
import certificatesStore from '@/components/card/tabs/license/certificates/certificatesStore.js'
import declarationsStore from '@/components/card/tabs/license/declarations/declarationsStore.js'
import estateStore from '@/components/card/tabs/realestate/estateStore.js'
import executoryStore from '@/components/card/tabs/executory/executoryStore.js'
import historyStore from '@/components/card/tabs/history/historyStore.js'
import fnsStore from '@/components/card/tabs/fns/fnsStore.js'
import egrulStore from '@/components/card/tabs/egrul-egrip/egrulStore.js'
import economicStatementsStore from '@/components/card/tabs/economics/economicStatements/economicStatementsStore.js'
import moexStore from '@/components/card/tabs/economics/moex/moexStore.js'
import shortResumeStore from '@/components/card/tabs/short-resume/shortResumeStore.js'

const cardStore = {
    namespaced: true,
    state: {
        api_pro: process.env.API_PRO,
        CID: 0,
        loading: false,
        info: null,
        stakeholders: null,
        employees: null,
        pdo: null,
        nalogooblojenie: null,
        koap: null,
        bankrupts: null,
        certificates: {
            tile: null,
            modal: {
                open: false,
                data: null,
                type: null,
                status: null,
                total_amount: null
            }
        },
        massAddress: null,
        stop: null,
        uchr: null,
        upr: null,
        comments: null,
        pre: null,
        baseLevelAfilScheme: null,
        unfair_supplier: null
    },
    getters: {
        companyUrl: ({ api_pro }) => (id, method, query = '') => `${api_pro}/company/${id}/${method}?demo=1${query}${(window.refresh_cache ? '&_refresh_cache=true' : '')}`,
        companyUrlv2: ({ api_pro }) => (id, method, query = '') => `${api_pro}/company/${id}/${method}?demo=1${query}${(window.refresh_cache ? '&_refresh_cache=true' : '')}`,
        addressApiUrl: ({ api_pro }) => (addressGuid, method) => `${api_pro}/address/${addressGuid}/${method}?demo=true`,
        historyApiUrlv2: ({ api_pro }) => ({ ogrn, source }) => `${api_pro}/company/${ogrn}/history/${source}`,
        ogrn: state => parseInt(state?.info?.ogrn),
        inn: state => state?.info?.inn,
        companyName: state => state.info.name_short || state.info.name_full,
        baseLevelAfilScheme: state => JSON.parse(JSON.stringify(state.baseLevelAfilScheme))
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        }
    },
    actions: {
        async init({ dispatch, commit, rootState }, ogrn) {
            commit('setStateObject', { name: 'loading', value: true })
            await dispatch('getCID', ogrn)
            const { inn } = await dispatch('getCompany', { ogrn, method: 'info' })
            dispatch('getCompany', { ogrn, method: 'pdo' })
            dispatch('getBaseAfilSchemeData', ogrn)
            dispatch('tax_deductions/getData')
            dispatch('shortResume/getBankRequisites')
            dispatch('shortResume/getBankruptStatus')
            dispatch('shortResume/getIntentions', { offset: 0, limit: 1 })
            if(rootState.card?.info?.status_type != 0 && rootState.card?.info?.fns_address?.date && rootState.card?.info?.fns_address?.text) {
                dispatch('shortResume/getRestrictedUl')
            }
            dispatch('shortResume/getSanctions')
            dispatch('inspections/getData')
            dispatch('business/getData')
            dispatch('customer_tenders/getStats')
            dispatch('customer_tenders/getProduct')
            dispatch('participant_tenders/getStats')
            dispatch('participant_tenders/getProduct')
            dispatch('economicStatements/getEconomics')
            dispatch('estate/getData')
            dispatch('estate/getObjectInfo', 'all')
            dispatch('executory/getData')
            dispatch('history/getHistoryFns')
            dispatch('history/getHistoryRosstat')
            dispatch('egrul/getEgrul')
            dispatch('egrul/getEcpList')
            dispatch('leasing/getData', { offset: 0, limit: 10 })
            dispatch('blockings/getInfo', { offset: 0, limit: 25 })
            dispatch('fns/getInfo', { offset: 0, limit: 10 })
            dispatch('license/getData', { page: 0, limit: 15 })
            dispatch('certificates/getData', { page: 0, limit: 25 })
            dispatch('declarations/getData', { page: 0, limit: 25 })
            dispatch('pledge/getData', { offset: 0, limit: 10 })
            dispatch('getCompany', { ogrn, method: 'employees' })
            commit('setStateObject', { name: 'loading', value: false })
            dispatch('getCompany', { ogrn, method: 'stakeholders' })
            dispatch('getCompany', { ogrn, method: 'bankrupts' })
            dispatch('getUnfairSupplier')
            dispatch('getCompany', { ogrn, method: 'stop' })
            dispatch('getCompany', { ogrn, method: 'pre' })
            dispatch('getMassAddress')
            dispatch('trademarks/getData', { offset: 0, limit: 10 })
            dispatch('sro/getData')
            const data = await dispatch('financial_statements/getLastYear')
            dispatch('financial_statements/getData', { lastYear: data?.last_boo_year })

            if(inn) {
                dispatch('v3/API', {
                    method: 'taxation_type',
                    data: { inn, ogrn: parseInt(ogrn) }
                }, { root: true }).then(e => {
                    commit('setStateObject', { name: 'nalogooblojenie', value: e?.data?.data })
                })
            }

            dispatch('v3/API', {
                method: 'card_comments',
                debug: false,
                data: {
                    action: 'get_by_card',
                    ogrn: parseInt(ogrn),
                    limit: 1000
                } 
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'comments', value: e?.data?.data?.cm })
            })

            dispatch('v3/API', {
                method: 'koap_19_28',
                data: { ogrn: parseInt(ogrn) }
            }, { root: true }).then(e => {
                if(e.status === 200 && e.data?.data?.length) {
                    const actual = e.data.data.filter(e => parseInt(e.actual) === 1)
                    commit('setStateObject', { name: 'koap', value: actual.length > 0 ? actual : null })
                }
            })
        },
        getCID({ rootState, commit }, ogrn) {
            const { apiSession } = rootState.user      
            return axios({
                method: 'POST',
                url: `${process.env.API_AUTH}/v3/`,
                data: {
                    method: 'cid',
                    data: {
                        sid: apiSession,
                        ogrn: parseInt(ogrn)
                    }
                }
            }).then(e => {
                commit('setStateObject', { name: 'CID', value: e?.data?.data?.CID })
                return e?.data?.data?.CID
            })
        },
        async getCompany({ rootState, state, commit, getters }, { ogrn, method }) {
            const { apiSession } = rootState.user
            const { CID } = state
            const url = getters.companyUrl(ogrn, method)

            return axios({
                url,
                headers: {
                    Authorization: `Bearer ${apiSession}`,
                    CID
                }
            }).then(e => {
                if(method === 'info' && e.data?.status === 130) {
                    router.push('/black-list')
                }
                commit('setStateObject', { name: method, value: e?.data?.data })
                return e?.data?.data
            })
        },
        getMassAddress({ rootState, state, getters, commit }) {
            const { apiSession } = rootState.user
            const { CID, info } = state
            const url = getters.addressApiUrl(info.addr_house_guid, 'companies')

            return axios({
                url,
                headers: {
                    Authorization: `Bearer ${apiSession}`,
                    CID
                }
            }).then(e => {
                commit('setStateObject', { name: 'massAddress', value: e.data.data })
            })
        },
        getAfil({ rootState, state, getters, commit }, { inn, rtype }) {
            const { apiSession } = rootState.user
            const { CID } = state
            const url = getters.companyUrlv2(inn, 'afil', `&group_by=shtype&rtype=${rtype}`)

            return axios({
                url,
                headers: {
                    Authorization: `Bearer ${apiSession}`,
                    CID
                }
            }).then(e => {
                if(e?.data?.data?.upr) {
                    const upr = e.data.data.upr
                    commit('setStateObject', { name: 'upr', value: upr })
                }

                if(e?.data?.data?.uchr?.length) {
                    const uchrDataFormatted = e?.data?.data?.uchr
                        .sort((a, b) => [4, 1, 3, 2][a.status_type[0]] - [4, 1, 3, 2][b.status_type[0]])
                        .filter(e => e.stakeholder_type != 'HISTORY')
                    
                    commit('setStateObject', { name: 'uchr', value: uchrDataFormatted })
                }
            })
        },
        getHTMLForPrint({ state, dispatch }, printObj) {
            try {
                if(!printObj.html) throw new Error('HTML для распечатки не указан')
                const title = printObj.title ? printObj.title : 'ЛИК:Эксперт'
                const pdf = printObj.pdf ? '_pdf' : ''
                const style_folder = printObj.style_folder ? printObj.style_folder : printObj.style_name
                return `<!DOCTYPE html>
                <html>
                    <head>
                        <title>${title}</title>
                        <link rel="stylesheet" type="text/css" href="${document.location.origin}/css/print.css?ver=1" />
                        <link rel="stylesheet" type="text/css" href="${document.location.origin}/components/${style_folder}/${printObj.style_name}${pdf}_print.css?ver=6" />
                    </head>
                    <body>
                        <div id="print-wrapper">
                            ${!pdf ? '<div id="print-pdf-hint" class="noprint" media="print">' : ''}
                                ${!pdf ? 'Чтобы сохранить в PDF, в меню <a href="javascript:window.print()">печати</a> выберите: <b>Принтер » Сохранить как PDF</b>' : ''}
                            ${!pdf ? '</div>' : ''}
                            <h2 id="print-main-title">${title} | ${state.company.title}</h2>
                            <div id="print-content">${printObj.html}</div>
                        </div>
                  </body>
                </html>`
            } catch (e) {
                dispatch('showErrorPopUp', 'Ошибка распечатки: '+e, { root: true })
            }
        },
        printTile({ dispatch }, printObj) {
            printObj.pdf = false
            return dispatch('getHTMLForPrint', printObj).then((htmlForPrint) => {
                const printWin = window.open()
                printWin.document.write(htmlForPrint)
                printWin.document.close()
            }).catch(e => dispatch('showErrorPopUp', 'Ошибка распечатки: '+e, { root: true }))
        },
        saveTile({ state, dispatch, rootState }, saveContent) {
            if(!saveContent.html) { console.error('Ошибка сохранения - Передан пустой HTML документ!'); return }
            const saveObj = {
                html: saveContent.html,
                style_name: saveContent.style_name,
                title: saveContent.title ? saveContent.title : '',
                pdf: true,
                filename: saveContent.filename ? saveContent.filename : saveContent.style_name,
                style_folder: saveContent.style_folder ? saveContent.style_folder : saveContent.style_name
            }
            return dispatch('getHTMLForPrint', saveObj).then((htmlForPrint) => {
                return axios({
                    method: 'POST',
                    url: `${process.env.API_PRO}/util/html-to-pdf?demo=1`,
                    headers: rootState.axios.headers,
                    data: {
                        params: {
                            ogrn: state.company.ogrn,
                            filename: `${saveObj.filename}_${state.company.ogrn}`
                        },
                        html: htmlForPrint
                    },
                    responseType: 'blob'
                }).then(result => {
                    if(result.status != 200)
                        dispatch('showErrorPopUp', 'Ошибка API', { root: true })
                    else {
                        // Кроссбраузерность, метод для IE
                        if(navigator.msSaveOrOpenBlob) {
                            navigator.msSaveOrOpenBlob(result.data, `${saveObj.filename}_${state.company.ogrn}.pdf`)
                        } else {
                            const link = document.createElement('a')
                            link.style = 'display: none'
                            link.href = window.URL.createObjectURL(result.data)
                            link.download = `${saveObj.filename}_${state.company.ogrn}.pdf`
                            document.body.appendChild(link)
                            link.click()
                        }
                    }
                }).catch(e => dispatch('showErrorPopUp', 'Ошибка API при сохранении: '+e, { root: true }))
            }).catch(e => dispatch('showErrorPopUp', 'Ошибка создания HTML: '+e, { root: true }))
        },
        toggleGridUpdate(_, timeout) {
            $('#tiles').trigger('resize')
            setTimeout(() => UIkit.$html.trigger('changed.uk.dom'), timeout)
        },
        API_PRO({ rootState, dispatch }, { method, url }) {
            return axios({
                method,
                url: process.env.API_PRO + url,
                headers: rootState.axios.headers
            }).then(result => {
                if(result.data.status.code != 200) {
                    throw result.data.status
                } else {
                    dispatch('toggleGridUpdate', 200)
                    return (result.data.data)
                }
            })
        },
        getCertificates({ dispatch, commit }, data) {
            const d = {
                limit: data && data.limit ? data.limit : 100,
                offset: data && data.offset ? data.offset : 0,
                type: data && data.type ? data.type : '',
                status: data && data.status ? data.status : ''
            }
            return dispatch('API_PRO', {
                method: 'GET',
                url: `company/${window.company.ogrn}/sert_and_decl${d.type && d.status ? `/${d.type}/${d.status}` : ''}?limit=${d.limit}&offset=${d.offset}`
            }).then(result => {
                commit('setStateObject', { name: d.type ? 'card.certificates.modal.data' : 'card.certificates.tile', value: result }, { root: true })
            }).catch((e) => {
                if(!d.type) $('#certificates-tile').hide()
                if(e.code != 204) console.error('sert&decl:', e.message)
            })
        },
        getHistory({ getters, state, rootState }, { ogrn, source }) {
            const { apiSession } = rootState.user
            const url = getters.historyApiUrlv2({ ogrn, source })
            const { CID } = state

            return axios({
                url,
                headers: {
                    Authorization: `Bearer ${apiSession}`,
                    CID
                }
            })
        },
        getBaseAfilSchemeData({ commit }, ogrn) {
            axios({
                method: 'get',
                url: `${process.env.API_PRO}/company/${ogrn}/afil?type=company&demo=1`,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('apiSession')}`
                }
            }).then(e => {
                commit('setStateObject', { name: 'baseLevelAfilScheme', value: e?.data?.data })
            })
        },
        getUnfairSupplier({ dispatch, getters, commit }) {
            return dispatch('v3/API', {
                method: 'unfair_supplier',
                data: {
                    action: 'list',
                    ogrn: getters.ogrn
                }
            }, { root: true }).then(res => {
                commit('setStateObject', { name: 'unfair_supplier', value: res?.data?.data })
            })
        }

    },
    modules: {
        trademarks: trademarkStore,
        sro: sroStore,
        tax_deductions: taxDeductionsStore,
        leasing: leasingStore,
        pledge: pledgeStore,
        inspections: inspectionsStore,
        blockings: blockingsStore,
        complexReport: complexReportStore,
        customer_tenders: customerStore,
        participant_tenders: participantStore,
        business: businessStore,
        financial_statements: financialStore,
        arbitrage: arbitrageStore,
        license: licenseStore,
        certificates: certificatesStore,
        declarations: declarationsStore,
        estate: estateStore,
        executory: executoryStore,
        history: historyStore,
        fns: fnsStore,
        egrul: egrulStore,
        economicStatements: economicStatementsStore,
        moex: moexStore,
        shortResume: shortResumeStore
    }
}

export default cardStore
