import { set } from 'lodash'
import axios from 'axios'


export default {
    namespaced: true,
    state: {
        list: [],
        nothing: false,
        loading: false
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setNothingToShow(state, nothing) {
            state.nothing = nothing
        },
        addArray(state, array) {
            state.list = [...state.list, ...array]
        }
    },
    actions: {
        getModal({ commit, state, rootState, rootGetters }, { year, type, page, limit }) {
            commit('setLoading', true)
            commit('setNothingToShow', false)
            const SID = window.localStorage.getItem('apiSession')
            const CID = rootState.card.CID

            axios({
                method: 'get',
                url: `${process.env.API_PRO}/company/${rootGetters['card/ogrn']}/kad/${year}/${type}?offset=${page * limit}&limit=${limit}`,
                headers: {
                    'Application': 'aCjHHZY00nODFwxmLKrKRBpXECLXCfgQkn5lXB4xcx5Y42SXKzkLgJDib2F8ZKod',
                    'Authorization': 'Bearer ' + SID,
                    'Cid': CID
                }
            }).then((e) => {
                if(e?.data?.data) {
                    if(state.list.length && e?.data?.data?.length) {
                        commit('addArray', e?.data?.data)
                    } else if(!state.list.length && e?.data?.data?.length) {
                        commit('setStateObject', { name: 'list', value: e?.data?.data })
                    } else {
                        commit('setNothingToShow', true)
                    }
                } 
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        clearList({ commit }) {
            commit('setStateObject', { name: 'list', value: [] })
        }
    }
}

