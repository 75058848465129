import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        list: [],
        loading: false,
        pagination: null
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        addArray(state, array) {
            state.list = [...state.list, ...array]
        }
    },
    actions: {
        getData({ dispatch, commit, rootGetters, state }, { offset, limit }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'cert_and_decl',
                data: {
                    action: 'certInfo',
                    ogrn: Number(rootGetters['card/ogrn']),
                    type: 'all',
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                if(e.status === 200 && e.data?.code === 0) {
                    if(!state.list.length) {
                        commit('setStateObject', { name: 'list', value: e.data.data?.list || [] })
                    } else {
                        commit('addArray', e.data.data?.list || [])
                    }
                    commit('setStateObject', { name: 'pagination', value: e.data.data?.pagination })
                }
                commit('setLoading', false)
            }).catch(err => {
                console.error(err.message)
                commit('setLoading', false)
            })
        }
    }
}
