import styled from 'vue-styled-components'

const disabledBgc = props => props.disables ? '#ddd': '#fafafa'
const bgc = props => props.blue ? '#3b5998' : disabledBgc(props)

const disabledColor = props => props.disables ? '#888': '#212121'
const color = props => props.blue ? '#fff' : disabledColor(props)

export default styled('button', { blue: Boolean, disables: Boolean })`
    font-family: 'Montserrat', sans-serif;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 4px;
    font-weight: 400;
    padding: 0px 12px;
    font-size: 14px;
    background-origin: border-box;
    line-height: 28px;
    background-color: ${bgc};
    color: ${color};
    cursor: ${props => props.disables ? 'not-allowed' : 'pointer'};
    box-shadow: 1px 1px 2px gray;
    &:hover: {
        border: 1px solid rgba(0,0,0,.5);
    }
    &:active {
        transform: ${props => !props.disables ? 'translate(1px, 1px)' : '0'};
        box-shadow: ${props => !props.disables ? '0 0 2px gray' : '0'};
    }
`