import { set } from 'lodash'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        rawHTML: '',
        pagination: {},
        links: [],
        loading: false
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getEgrul({ commit, rootState, rootGetters }) {
            commit('setLoading', true)
            const SID = window.localStorage.getItem('apiSession')
            const CID = rootState.card.CID

            axios({
                method: 'get',
                url: `${process.env.API_PRO}/company/${rootGetters['card/ogrn']}/report/egrul?demo=1&result_type=html${window.refresh_cache ? '&_refresh_cache=true' : ''}`,
                headers: {
                    'Application': 'aCjHHZY00nODFwxmLKrKRBpXECLXCfgQkn5lXB4xcx5Y42SXKzkLgJDib2F8ZKod',
                    'Authorization': 'Bearer ' + SID,
                    'Cid': CID
                }
            }).then((e) => {
                if(e?.data) {
                    commit('setStateObject', { name: 'rawHTML', value: e?.data })
                } 
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getEcpList({ commit, dispatch, rootGetters }) {

            return dispatch('v3/API', {
                method: 'egrul_ecp',
                data: { 
                    action: 'listEgrulOgrn',
                    ogrn: rootGetters['card/ogrn'],
                    limit: 1000,
                    offset: 0
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'links', value: e?.data?.data?.list_egrul })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })

                return e?.data?.data
            })
        },
        getNewEcp({ dispatch, rootGetters, rootState }) {

            return dispatch('v3/API', {
                method: 'egrul_ecp',
                data: { 
                    action: 'getEgrul',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.data?.id_file) {
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = `${rootState.v3.downloadURL + e?.data?.data?.id_file}?sid=${window.localStorage.getItem('apiSession')}`
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }
            })
        }
    }
}
