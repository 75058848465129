import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        tax_deductions: {}

    },
    getters: {
        getMainChartList(state, getters) {
            const mainChart = {}
            if(Object.values(state.tax_deductions).length) {
                if(state.tax_deductions.income_and_costs[2]['summ']) {
                    mainChart['costs'] = {
                        label: 'Общие расходы',
                        val: []
                    }
                }
                mainChart['zp'] = {
                    label: 'Ср. уровень официальной з/п сотрудников',
                    val: []
                }
                if(Object.keys(state.tax_deductions.nalog).length) {
                    mainChart['offenses'] = {
                        label: 'Штраф за налоговые правонарушения',
                        val: []
                    }
                }
                if(state.tax_deductions.income_and_costs) {
                    mainChart['costs']['val'] =   state.tax_deductions.header.map(el => state.tax_deductions.income_and_costs[2]['summ'][el] ? state.tax_deductions.income_and_costs[2]['summ'][el] : 0)
                }
                if(state.tax_deductions.averageSalary && Object.values(state.tax_deductions.averageSalary).length) {
                    mainChart['zp']['val'] = state.tax_deductions.header.map(el => state.tax_deductions.averageSalary['summ'][el] ? state.tax_deductions.averageSalary['summ'][el] : 0)
                }
                if(state.tax_deductions.offenses && Object.keys(state.tax_deductions.offenses).length) {
                    mainChart['offenses']['val'] =  state.tax_deductions.header.map(el => state.tax_deductions.offenses[3]['summ'][el] ? state.tax_deductions.offenses[3]['summ'][el] : 0)
                }
                    
                Object.keys(state.tax_deductions.nalog).map(el => {
                    if(Object.prototype.hasOwnProperty.call(getters.chart_vocabulary, el)) {
                        mainChart[el] = {
                            label: getters.chart_vocabulary[el],
                            val: []
                        }
                    } else {
                        mainChart[el] = {
                            label: state.tax_deductions.nalog[el].type_description,
                            val: []
                        }
                    }
                    mainChart[el].val = state.tax_deductions.header.map(year => state.tax_deductions.nalog[el]['summ'][year] ? state.tax_deductions.nalog[el]['summ'][year] : 0)
                    
                })
            }
            return mainChart
        },
        getDeductionChartList(state, getters) {
            const deductionChart = {}
            if(state?.tax_deductions?.nedoimki && Object.keys(state?.tax_deductions?.nedoimki).length) {
                Object.keys(state?.tax_deductions?.nedoimki).forEach(el => {
                    if(Object.prototype.hasOwnProperty.call(getters.chart_vocabulary, el)) {
                        deductionChart[el] = {
                            label: getters.chart_vocabulary[el],
                            val: []
                        }
                    } else {
                        deductionChart[el] = {
                            label: state.tax_deductions.nedoimki[el].type_description,
                            val: []
                        }
                    }
                    deductionChart[el].val = state.tax_deductions.header.map(year => state.tax_deductions.nedoimki[el]['summ'][year] ? state.tax_deductions.nedoimki[el]['summ'][year] : 0)
                    
                })
            }
            return deductionChart
        },
        chart_vocabulary() {
            return {
                '1': 'Налог УСН',
                '2': 'Страховые взносы в ПФРФ',
                '3': 'ЕНВД',
                '4': 'Налог УСН',
                '5': 'Налог на прибыль',
                '6': 'НДС',
                '7': 'Неналоговые доходы',
                '8': 'Страховые взносы на ОСС',
                '9': 'Задолженность и перерасчеты по отмененным налогам',
                '10': 'Страховые взносы на ОМС',
                '11': 'Налог на имущество',
                '12': 'НДФЛ',
                '13': 'Земельный налог',
                '14': 'Транспортный налог',
                '15': 'ЕСХН',
                '16': 'Торговый сбор',
                '17': 'Водный налог',
                '18': 'НДПИ',
                '19': 'Налог на игорный бизнес',
                '20': 'Сборы за пользование ожм и овбр',
                '21': 'Утилизационный сбор',
                '25': 'Рег. платежи за добычу полезных ископаемых'
            }
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootGetters, rootState }) {
            commit('setLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'tax_deduction',
                data: { 
                    inn: rootState.card.info.inn,
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'tax_deductions', value: e?.data?.data })

                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    }
}




