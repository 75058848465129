import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        list: [],
        years: [],
        from_2005_to_2011: [],
        from_2011_to_2018: [],
        from_2019: [],
        showForm: '',
        lastYear: null,
        loading: false
    },
    getters: {
        years(state) {
            if(state && state.list) {
                return Object.keys(state.list)
            } else {
                return []
            }
        },
        from_2005_to_2011(state) {
            if(state && state.list) {
                return Object.keys(state.list).filter(e => parseInt(e) < 2011 )
            } else {
                return []
            }
        },
        from_2011_to_2018(state) {
            if(state && state.list) {
                return Object.keys(state.list).filter(e => parseInt(e) > 2011 && parseInt(e) < 2019 )
            } else {
                return []
            }
        },
        from_2019(state) {
            if(state && state.list) {
                return Object.keys(state.list).filter(e => parseInt(e) >= 2019 )
            } else {
                return []
            }
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ getters, commit, dispatch, rootState }, { lastYear }) {
            commit('setLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {

                method: 'economics',
                data: { 
                    action: 'boo_reports',
                    inn: rootState.card.info.inn,
                    yearFrom: 2005,
                    yearTo: lastYear
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list', value: e?.data?.data })

                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
                commit('setStateObject', { name: 'showForm', value: getters.years[getters.years.length - 1] })
            })
        },
        getBuch({ commit }, year) {
            commit('setStateObject', { name: 'showForm', value: year })
        },
        getLastYear({ commit, dispatch }) {

            return dispatch('v3/API', {
                method: 'economics',
                data: { 
                    action: 'last_boo_year'
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'lastYear', value: e?.data?.data?.last_boo_year })

                return e?.data?.data
            })
        },
        getEcp({ dispatch, rootState, rootGetters }, { year }) {
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'fns_bo',
                data: {
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    year
                }
            }, { root: true }).then(e => {
                if(e?.data?.code === 0 && e?.data?.data?.id_file) {
                    return e.data.data.id_file
                } else if(e.data?.code !== 0) {
                    dispatch('showErrorPopUp', 'При загрузке отчётности произошла ошибка', { root: true })
                }
                
            })
        }
    }
}
