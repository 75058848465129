<template>
    <div class="afil-network-toolip">
        <i class="fas fa-project-diagram" @click="networkClickHandle()"></i>

        <modal v-if="showModal" :close="() => { this.showModal = false }" :downloadButton="false" :showFullScreenIcon="true" :title="`Схема аффилированности ${fio}`">
            <div slot="modalHeader" class="modal-header">Схема аффилированности {{fio}} <span style="font-size: 14px">ИНН: {{inn}}</span></div>
            <div slot="modalBody" style="padding: 15px; height: 100%;">
                <shestirenka v-if="loading"/>
                <newAfil v-else :ogrn="inn" :group="personGroups[0].id" />
            </div>
        </modal>
    </div>
</template>

<script>
import modal from '@/components/ui/modal/modal.vue'
import shestirenka from '@/components/ui/shestirenkaLoader.vue'
import newAfil from '@/components/ui/afilNetworkTooltip/newAfil.vue'

export default {
    components: { modal, shestirenka, newAfil },
    props: ['inn', 'fio'],
    data() {
        return {
            loading: false,
            showModal: false
        }
    },
    methods: {
        networkClickHandle() {
            this.showModal = true
        }
    },
    computed: {
        personGroups() {
            return this.$store.state.user.groups.filter(e => e.rules.monitoring_fl)
        }
    }
}
</script>


<style lang="sass" scoped>
@import 'styles/global-variables.sass'

.afil-network-toolip
    display: inline-block
    .fas.fa-project-diagram
        @extend .blue-red-hover-text
        font-weight: 600
    .modal-header
        overflow: hidden
</style>