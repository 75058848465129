import { set } from 'lodash'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        loading: false,
        securities: null,
        hasDataToShow: null,
        candles: null
    },
    getters: {
        main_share: state => {
            const common_shares = state.securities?.filter(e => e.type == 'common_share')
            const preferred_shares = state.securities?.filter(e => e.type == 'preferred_share')

            if(common_shares && common_shares.length) {
                return common_shares[0]
            } else if(preferred_shares && preferred_shares.length) {
                return preferred_shares[0]
            }
        },
        current_price: state => {
            if(state.candles?.length) {
                return state.candles.reverse()[0]
            }
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getSecurities({ commit }, { inn }) {
            return axios({
                method: 'get',
                url: `https://iss.moex.com/iss/securities.json?q=${inn}`,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(e => {
                if(e?.data?.securities?.data?.length) {
                    const securities = e.data.securities.data.map(e => ({
                        id: e[0],
                        secid: e[1],
                        shortname: e[2],
                        regnumber: e[3],
                        name: e[4],
                        isin: e[5],
                        is_traded: e[6],
                        emitent_id: e[7],
                        emitent_title: e[8],
                        emitent_inn: e[9],
                        emitent_okpo: e[10],
                        gosreg: e[11],
                        type: e[12],
                        group: e[13],
                        primary_boardid: e[14],
                        marketprice_boardid: e[15]
                    }))

                    commit('setStateObject', { name: 'securities', value: securities })

                    return securities
                }
            })
        },
        getCandles({ commit, getters }) {
            if(getters.main_share) {
                const { secid } = getters.main_share
                
                const fiveHundredDaysBack = new Date(new Date().setDate(new Date().getDate() - 499))
                const year = fiveHundredDaysBack.getFullYear()
                const month = ('0' + (fiveHundredDaysBack.getMonth() + 1)).slice(-2)
                const day = ('0' + fiveHundredDaysBack.getDate()).slice(-2)

                return axios({
                    method: 'get',
                    url: `https://iss.moex.com/iss/engines/stock/markets/shares/securities/${secid}/candles.json?interval=24&from=${year}-${month}-${day}`,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(e => {
                    if(e?.data?.candles?.data?.length) {
                        commit('setStateObject', { name: 'candles', value: e.data.candles.data })
                        const formatted_candles = e.data.candles.data.map(e => ({
                            open: e[0],
                            close: e[1],
                            high: e[2],
                            low: e[3],
                            time: {
                                day: new Date(e[6]).getDate(),
                                month: new Date(e[6]).getMonth(),
                                year: new Date(e[6]).getFullYear()
                            }
                        }))
                        return formatted_candles
                    } else {
                        return false
                    }
                })
            }
        }
    }
}