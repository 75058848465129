import { menuRoutes } from '@/router.js'

const routerMenuLinkFallback = (el, vnode) => evt => {
    const route = $(el).attr('href')
    const protectedRoutes = menuRoutes.map(r => r.path).concat('/')

    evt.preventDefault()
    evt.stopPropagation()
    
    const currentPath = vnode.context.$route.path

    if(protectedRoutes.includes(currentPath)) {
        vnode.context.$router.push(route)
    } else {
        window.open(route, '_blank')
    }

    $(window).scrollTop(0)
}

export const routerLinkFallback = {
    bind(el, _, vnode) {
        if(vnode.context.$route.matched.length) {
            el.addEventListener('click', routerMenuLinkFallback(el, vnode))
        }
    },
    unbind(el) {
        el.removeEventListener('click', routerMenuLinkFallback)
    }
}