<template>
    <div>
        <div class="baner">
            <p>Мы используем <button class="modal-link" @click="showModal">файлы cookie.</button></p>
            <btn @click.native="agreeHandler">Понятно</btn>
            <cookie-modal v-if="isModal" @close="hideModal" />
        </div>
    </div>
</template>

<script>
import cookieModal from '@/components/ui/cookieBaner/cookieModal.vue'
import button from '@/components/ui/button/button.vue.js'

export default {
    components: { 
        cookieModal,
        btn: button 
    },
    data() {
        return {
            isModal: false
        }
    },
    methods: {
        showModal() { this.isModal = true },
        hideModal() { this.isModal = false },
        agreeHandler() { this.$emit('agree') }
    }
    
}
</script>

<style lang="sass" scoped>
@import 'styles/global-variables'

.baner
    width: max-content
    display: flex
    align-items: center
    gap: 10px
    padding: 15px 10px
    position: fixed
    bottom: 50px
    left: 50px
    box-shadow: 0 5px 23px -3px rgba(0, 0, 0, .3)
    background: #fff
    z-index: 1000
    border-radius: 4px
    transition: box-shadow 0.3s
    p
        padding: 0
        margin: 0
        color: #2a2a2b
    button
        background: none
        border: none
        cursor: pointer
    &:hover
        box-shadow: 0 5px 23px -3px rgba(0, 0, 0, .5)
    .modal-link
        @include blue-red-hover-text
        font-size: 16px
</style>