import VueRouter from 'vue-router'
const Search = () => import('@/components/search/search.vue')
const Profile = () => import('@/components/profile/profile.vue')
const Monitoring = () => import('@/components/monitoring/monitoring.vue')
const RelationsSearch = () => import('@/components/global/RelationsSearch/RelationsSearch.vue')
const Main = () => import('@/components/main/Main.vue')
const AddressCheck = () => import('@/components/global/addressCheck.vue')
const PassportCheck = () => import('@/components/global/passportCheck.vue')
const Card = () => import('@/components/card/Card.vue')
const Afil = () => import('@/components/card/tabs/afil/Afil.vue')
const ShortResume = () => import('@/components/card/tabs/short-resume/ShortResume.vue')
const Executory = () => import('@/components/card/tabs/executory/Executory.vue')
const FnsDocs = () => import('@/components/card/tabs/fns/FnsDocs.vue')
const Fns = () => import('@/components/card/tabs/fns/Fns.vue')
const EgrulDocs = () => import('@/components/card/tabs/fns/EgrulDocs.vue')
const Economics = () => import('@/components/card/tabs/economics/Economics.vue')
const Blockings = () => import('@/components/card/tabs/blockings/Blockings.vue')
const Arbitrage = () => import('@/components/card/tabs/arbitrage/Arbitrage.vue')
const CourtPractise = () => import('@/components/card/tabs/arbitrage/CourtPractise.vue')
const GeneralJurisdictionCourts = () => import('@/components/card/tabs/arbitrage/GeneralJurisdictionCourts.vue')
const History = () => import('@/components/card/tabs/history/History.vue')
const License = () => import('@/components/card/tabs/license/License.vue')
const LicenseList = () => import('@/components/card/tabs/license/licenseList/license-list.vue')
const Certificates = () => import('@/components/card/tabs/license/certificates/Certificates.vue')
const Declarations = () => import('@/components/card/tabs/license/declarations/Declarations.vue')
const SroList = () => import('@/components/card/tabs/license/sroList/sro-list.vue')
const Inspections = () => import('@/components/card/tabs/inspections/Inspections.vue')
const Realestate = () => import('@/components/card/tabs/realestate/Realestate.vue')
const Tenders = () => import('@/components/card/tabs/tenders/Tenders.vue')
const Customer = () => import('@/components/card/tabs/tenders/customer/customer.vue')
const Participant = () => import('@/components/card/tabs/tenders/participant/participant.vue')
const Ved = () => import('@/components/card/tabs/ved/Ved.vue')
const EgrulEgrip = () => import('@/components/card/tabs/egrul-egrip/EgrulEgrip.vue')
const Egrn = () => import('@/components/card/tabs/egrn/Egrn.vue')
const ComplexReport = () => import('@/components/card/tabs/KO/ComplexReport.vue')
const Pdo = () => import('@/components/card/tabs/pdo/Pdo.vue')
const SwitcherLP = () => import('@/components/card/tabs/leasing-pledge/SwitcherLP.vue')
const Leasing = () => import('@/components/card/tabs/leasing-pledge/leasing/Leasing.vue')
const Pledge = () => import('@/components/card/tabs/leasing-pledge/pledge/Pledge.vue')
const EconomicStatements = () => import('@/components/card/tabs/economics/economicStatements/EconomicStatements.vue')
const BusinessCoef = () => import('@/components/card/tabs/economics/businessCoef/BusinessCoef.vue')
const AfilScheme = () => import('@/components/card/tabs/afil/scheme/Afil.vue')
const Upr = () => import('@/components/card/tabs/afil/upr/Upr.vue')
const Uchr = () => import('@/components/card/tabs/afil/uchr/Uchr.vue')
const Owners = () => import('@/components/card/tabs/afil/owners/Owners.vue')
const Branches = () => import('@/components/card/tabs/afil/branches/Branches.vue')
const FinancialStatements = () => import('@/components/card/tabs/economics/financialStatements/FinancialStatements.vue')
const TaxDeductions = () => import('@/components/card/tabs/economics/taxDeductions/TaxDeductions.vue')
const Moex = () => import('@/components/card/tabs/economics/moex/Moex.vue')
const KonKon = () => import('@/components/card/tabs/kontragent-konkurent/KonKon.vue')
const Kontragent = () => import('@/components/card/tabs/kontragent-konkurent/kontragent/Kontragent.vue')
const Konkurent = () => import('@/components/card/tabs/kontragent-konkurent/konkurent/Konkurent.vue')
const HistoryFNS = () => import('@/components/card/tabs/history/HistoryFns.vue')
const HistoryRosstat = () => import('@/components/card/tabs/history/HistoryRosstat.vue')
const Trademarks = () => import('@/components/card/tabs/trademarks/Trademarks.vue')
const EgrnPage = () => import('@/components/global/EgrnPage.vue')
const flCheck = () => import('@/components/global/onlineFlCheck/flCheck.vue')
const fof = () => import('@/components/global/404.vue')
const BlackListError = () => import('@/components/global/blackListError.vue')

const economicsSubroutes = [
    { path: 'economic-statements', component: EconomicStatements },
    { path: 'tax-deductions', component: TaxDeductions },
    { path: 'business-coef', component: BusinessCoef },
    { path: 'financial-statements', component: FinancialStatements },
    { path: 'moex', component: Moex }
]

const afilSubroutes = [
    { path: 'scheme', component: AfilScheme },
    { path: 'owners', component: Owners, name: 'owners' },
    { path: 'uchr', component: Uchr },
    { path: 'upr', component: Upr },
    { path: 'branches', component: Branches }
]

const konKonSubroutes = [
    { path: '', redirect: 'kontragent' },
    { path: 'kontragent', component: Kontragent },
    { path: 'konkurent', component: Konkurent }
]

const switcherLPSubroutes = [
    { path: '', redirect: 'leasing' },
    { path: 'leasing', component: Leasing },
    { path: 'pledge', component: Pledge }
]

const licenseSroSubroutes = [
    { path: '', redirect: 'license-list' },
    { path: 'license-list', component: LicenseList },
    { path: 'sro-list', component: SroList },
    { path: 'certificates', component: Certificates },
    { path: 'declarations', component: Declarations }
]

const tenderSubroutes = [
    { path: '', redirect: 'participant' },
    { path: 'participant', component: Participant },
    { path: 'customer', component: Customer }
]

const historySubroutes = [
    { path: '', redirect: 'history' },
    { path: 'fns', component: HistoryFNS },
    { path: 'rosstat', component: HistoryRosstat }
]

const fnsSubroutes = [
    { path: '', redirect: 'fns' },
    { path: 'fns-docs', component: Fns },
    { path: 'egrul-docs', component: EgrulDocs }
]

const CourtPractiseSubroutes = [
    { path: '', redirect: 'court-practise' },
    { path: 'arbitrage', component: Arbitrage },
    { path: 'general-jurisdiction-courts', component: GeneralJurisdictionCourts }
]

const companyPersonInfoSubroutesChildren = [
    { path: '', redirect: 'short-resume' },
    { path: 'short-resume', name: 'short-resume', component: ShortResume },
    { path: 'afil', redirect: 'afil/scheme', name: 'afil', component: Afil, children: afilSubroutes },
    { path: 'kontragent-konkurent', redirect: 'kontragent-konkurent/kontragent', name: 'kontragent-konkurent', component: KonKon, children: konKonSubroutes },
    { path: 'executory', name: 'executory', component: Executory },
    { path: 'fns', redirect: 'fns/egrul-docs', name: 'fns', component: FnsDocs, children: fnsSubroutes },
    { path: 'economics', redirect: 'economics/economic-statements', name: 'economics', component: Economics, children: economicsSubroutes },
    { path: 'history', redirect: 'history/fns', name: 'history', component: History, children: historySubroutes },
    { path: 'blockings', name: 'blockings', component: Blockings },
    { path: 'court-practise', name: 'court-practise', component: CourtPractise, children: CourtPractiseSubroutes, redirect: 'court-practise/arbitrage' },
    { path: 'license', redirect: 'license/license-list', name: 'license', component: License, children: licenseSroSubroutes },
    { path: 'inspections', name: 'inspections', component: Inspections },
    { path: 'realestate', name: 'realestate', component: Realestate },
    { path: 'tenders', redirect: 'tenders/participant', name: 'tenders', component: Tenders, children: tenderSubroutes },
    { path: 'ved', name: 'ved', component: Ved },
    { path: 'egrul-egrip', name: 'egrul-egrip', component: EgrulEgrip },
    { path: 'egrn', name: 'egrn', component: Egrn },
    { path: 'complex-report', name: 'complex-report', component: ComplexReport },
    { path: 'pdo', name: 'pdo', component: Pdo },
    { path: 'trademarks', name: 'trademarks', component: Trademarks },
    { path: 'leasing-pledge', redirect: 'leasing-pledge/leasing', name: 'leasing-pledge', component: SwitcherLP, children: switcherLPSubroutes }
    
]

export const menuRoutes = [
    { path: '/profile', component: Profile, name: 'Profile' },
    { path: '/search', component: Search, name: 'Search' },
    { path: '/monitoring', component: Monitoring, name: 'Monitoring' },
    { path: '/egrn', component: EgrnPage, name: 'Egrn' },
    { path: '/passport-check', component: PassportCheck, name: 'PassportCheck' },
    { path: '/fl-check', component: flCheck, name: 'flCheck' },
    { path: '/flat-check', component: AddressCheck, name: 'AddressCheck' },
    { path: '/relations-search', component: RelationsSearch, name: 'RelationsSearch' }
]

const router = new VueRouter({
    mode: 'history',
    routes: [
        ...menuRoutes,
        { path: '/', component: Main, name: 'Main' },
        {
            path: '/company/:ogrn',
            component: Card,
            redirect: '/company/:ogrn/short-resume',
            children: companyPersonInfoSubroutesChildren,
            alias: ['/person/:ogrn', '/card/:ogrn']
        },
        {
            path: '*',
            component: fof
        },
        {
            path: '*/black-list',
            component: BlackListError,
            name: 'BlackList'
        }
    ]
})

export default router