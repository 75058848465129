import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        EGRN: [],
        ECP: [],
        history: [],
        pagination: {}
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getCombinedHistory({ commit, dispatch }, { offset = 0, limit = 50 }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'user_history',
                data: { 
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'history', value: e?.data?.data?.history })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getECP({ commit, dispatch, rootState }) {

            return dispatch('v3/API', {
                method: 'egrul_ecp',
                data: { 
                    action: 'listEgrulUser',
                    login: rootState.user?.user_data?.user_login
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'ECP', value: e?.data?.data.list_egrul })
                return e?.data?.data
            })
        },
        getEGRN({ commit, dispatch }) {

            return dispatch('v3/API', {
                method: 'egrn',
                data: { 
                    action: 'list_egrn'
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'EGRN', value: e?.data?.data.list_egrn })
                return e?.data?.data
            })
        }
    }
}
