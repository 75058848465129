import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        list: [],
        pagination: {},
        loading: false,
        status: ''
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getInfo({ commit, dispatch, rootState, rootGetters }, { offset = 0, limit = 10 }) {
            commit('setLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'submit_docs',
                data: { 
                    action: 'get_info',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list', value: e?.data?.data?.list_submit_docs })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                commit('setStateObject', { name: 'status', value: e?.data?.data?.status })

                if(e?.data?.data?.status == 0) {
                    setTimeout(() => dispatch('getInfo', { offset: 0, limit: 10 }), 10000)
                }
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        updateInfo({ dispatch, rootState, rootGetters }) {
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'submit_docs',
                data: { 
                    action: 'auto_upd_info',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn
                }
            }, { root: true }).then(e => {
                if(e?.data?.code == 0) {
                    dispatch('getInfo', { offset: 0, limit: 10 })
                }
            })
        }
    }
}


