import axios from 'axios'

export default {
    namespaced: true,
    state: {
        all_links: false,
        getSuggestionsTimeout: null,
        relationsLoading: false,
        inputData: {
            from: '',
            to: ''
        },
        suggestions: {
            from: [],
            to: []
        },
        hintSuggestion: {
            from: null,
            to: null
        },
        maxHops: 5
    },
    mutations: {
        setRelationsLoading(state, loading) {
            state.relationsLoading = loading
        },
        setAllLinks(state, value) {
            state.all_links = value
        },
        setSuggestion(state, { direction, suggestion }) {
            state.hintSuggestion[direction] = suggestion
        },
        setInputFieldData(state, { direction, inputData }) {
            state.inputData[direction] = inputData
        },
        clearInputFieldData(state, direction) {
            state.inputData[direction] = ''
        },
        setSuggestions(state, { hints, direction }) {
            state.suggestions[direction] = hints
        },
        clearSuggestions(state, { direction }) {
            state.suggestions[direction] = []
        },
        setMaxHops(state, maxHops) {
            if(maxHops >= 10) {
                state.maxHops = 10
            } else if(maxHops <= 1) {
                state.maxHops = 1
            } else if(maxHops < 10 && maxHops > 1) {
                state.maxHops = maxHops
            }
        },
        clearGetSuggestionsTimeoutAndCancelRequest(state) {
            const tokenSource = axios.CancelToken.source()
            clearTimeout(state.getSuggestionsTimeout)
            state.getSuggestionsCancelToken?.cancel()
            state.getSuggestionsCancelToken = tokenSource
        }
    },
    actions: {
        getSuggestions({ rootState, commit, dispatch, state }, direction) {
            const apiSession = rootState.user?.apiSession
            const inputData = state.inputData[direction]
            commit('clearGetSuggestionsTimeoutAndCancelRequest')
            if(inputData) {
                state.getSuggestionsTimeout = setTimeout(() => {
                    axios({
                        method: 'post',
                        url: process.env.API_MS_HINTS,
                        cancelToken: state.getSuggestionsCancelToken?.token,
                        data: {
                            auth: { sid: apiSession },
                            method: 'affil',
                            data: {
                                search: inputData,
                                limit: 5
                            }
                        }
                    }).then(e => {
                        if(e.data?.code == 105) {
                            dispatch('showErrorPopUp', 'Введены неверные параметры', { root: true })
                            commit('clearSuggestions', { direction })
                        }
                        if(e.data?.data?.hints) {
                            commit('setSuggestions', { hints: e.data?.data?.hints, direction })
                        } else {
                            commit('clearSuggestions', { direction })
                        }
                    }).catch(err => {
                        commit('clearSuggestions', { direction })
                        if(!axios.isCancel(err)) {
                            dispatch('showErrorPopUp', 'Неизвестная ошибка', { root: true })
                        }
                    })
                }, 400)
            } else {
                commit('clearSuggestions', { direction })
            }
        },
        searchRelations({ rootState, dispatch, state, commit }) {
            const apiSession = rootState.user?.apiSession

            const {
                hintSuggestion,
                maxHops,
                all_links
            } = state

            commit('setRelationsLoading', true)

            if(hintSuggestion?.from?.lic_id && hintSuggestion?.to?.lic_id) {
                return axios({
                    method: 'post',
                    url: process.env.API_MS_AFFIL,
                    data: {
                        auth: {
                            sid: apiSession
                        },
                        method: 'search',
                        data: {
                            all_links,
                            src: hintSuggestion.from.lic_id,
                            dst: hintSuggestion.to.lic_id,
                            max_hops: maxHops
                        }
                    }
                }).then(e => {
                    if(e?.data?.data?.nodes) {
                        return e.data.data
                    } else {
                        dispatch('showErrorPopUp', 'Взаимосвязей не найдено', { root: true })
                    }
                }).catch(() => {
                    dispatch('showErrorPopUp', 'Неизвестная ошибка', { root: true })
                }).finally(() => {
                    commit('setRelationsLoading', false)
                })
            }
        }
    }
}
