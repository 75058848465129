import '@babel/polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import globalStoreObject from '@/globalStoreObject.js'
import helpers from '@/helpers.js'
import VueClickOutsideElement from 'vue-click-outside-element'
import VueClickOutsideComponent from 'vue-click-outside-component'
import VueNotify from 'vue-notificationx'
import VCalendar from 'v-calendar'
import divInline from '@/components/ui/divInline.vue.js'
import rulerHelper from '@/components/ui/rulerHelper.vue'
import vfooter from '@/components/global/footer/footer.vue'
import vheader from '@/components/global/header/header.vue'
import router from '@/router.js'
import tooltip from '@/components/ui/tooltip.vue'
import { routerLinkFallback } from './directives.js'

import { cookieLib } from '@/helpers.js'

Vue.use(Vuex)
const store = new Vuex.Store(globalStoreObject)
Vue.use(VueRouter)
Vue.use(VueClickOutsideElement)
Vue.use(VueClickOutsideComponent)
Vue.use(VueNotify, {
    position: 'top-center',
    style: 'danger-light',
    timeout: 3000
})
Vue.use(VCalendar, {
    masks: {
        title: 'MMM YYYY',
        L: 'DD.MM.YYYY',
        input: 'DD.MM.YYYY'
    },
    locale: 'ru'
})

const mixin = Vue.mixin({
    components: {
        divInline
    },
    methods: {
        ...helpers
    },
    computed: {
        auth() { return JSON.parse(window.localStorage.getItem('auth')) },
        window() { return { location: window.location }},
        isDevMode() { return process.mode == 'development' },
        mobile() {
            return {
                isDesktop: window.screen.width > 850,
                isTablet: window.screen.width <= 850,
                isMobile: window.screen.width <= 480
            }
        }
    },
    filters: {
        toLacalDate(date) { return new Date(date).toLocaleDateString('ru') }
    }
})

Vue.config.ignoredElements = ['ymaps']

Vue.directive('focus', {
    inserted(el) { el.focus() }
})

Vue.directive('scroll', {
    inserted(el, binding) {
        const f = (evt) => {
            if(binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
})

Vue.directive('router-link-fallback', routerLinkFallback)

Vue.directive('check-input-length', {
    bind(el, binding) { 
        const updateInputHandler = (e, bindingValue) => {
            const value =  el.value.replace(/\s/g, '').slice(0, bindingValue)
            if(value !== el.value) {
                el.value = value
                el.dispatchEvent(new Event('input'))
            }
        }

        el.addEventListener('input', (e) => updateInputHandler(e, binding.value))
    }
})

Vue.component(tooltip.name, tooltip)

store.dispatch('init')

window.api_pro = process.env.API_PRO

window.onload = function() {

    if(!cookieLib.getCookie('isBaner')) {
        cookieLib.setCookie('isBaner', '1', 30)
    }

    if($('#licexpert').length) {
        new Vue({
            router,
            store,
            mixins: [mixin],
            components: {
                vheader,
                vfooter,
                rulerHelper
            }
        }).$mount('#licexpert')
    }
}