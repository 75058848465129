var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    [
      _c("div", { staticClass: "container" }, [
        _c("ul", { staticClass: "first_column" }, [
          _c("li", [
            _c("p", { attrs: { id: "name" } }, [
              _vm._v("© ЗАО «ЛИК», " + _vm._s(_vm.currentYear)),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("li", [
            _c(
              "button",
              {
                staticClass: "personal-data-politics-info",
                on: { click: _vm.showModal },
              },
              [_vm._v("Политика обработки персональных данных")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm.origin == "https://licexpert.by"
          ? _c("ul", { staticClass: "thrid_column" }, [
              _vm._m(5),
              _vm._v(" "),
              _vm._m(6),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(7),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "reestr-link",
          attrs: {
            href: "https://reestr.digital.gov.ru/reestr/515823/",
            target: "_blank",
          },
        },
        [
          _vm._v(
            "Сервис ЛИК:ЭКСПЕРТ включён в Единый реестр российских программ для ЭВМ и баз данных"
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "reestr-acred-companies" }, [
        _vm._v(
          'ЗАО "ЛИК" включено в реестр аккредитованных организаций, осуществляющих деятельность в области ИТ (№ АО-20220303-3736769171-3 от 04.03.2022)'
        ),
      ]),
      _vm._v(" "),
      _vm.isModal
        ? _c("personal-data-modal", { on: { close: _vm.hideModal } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isBaner
        ? _c("cookie-baner", { on: { agree: _vm.hideCookieBaner } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("p", [_vm._v("ИНН: 7719841799")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticStyle: { "padding-top": "5px" } }, [
        _vm._v("ОГРН: 1137746308039"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { target: "_blank", href: "http://1clicom.ru/about.php" } },
        [_c("p", [_vm._v("О компании")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { target: "_blank", href: "http://1clicom.ru/bay.php" } },
        [_c("p", [_vm._v("Где купить")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "second_column" }, [
      _c("li", [
        _c(
          "a",
          {
            attrs: { target: "_blank", href: "http://1clicom.ru/dogovor.php" },
          },
          [
            _c("p", { staticClass: "license" }, [
              _vm._v("Лицензионный договор"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("p", { staticClass: "other_product" }, [_vm._v("Другие продукты:")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "https://licexpert.ru/", target: "_blank" } },
          [_c("p", { staticClass: "other_project" }, [_vm._v("ЛИК:ЭКСПЕРТ")])]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "http://1clicom.ru/expert_1c.php",
              target: "_blank",
            },
          },
          [
            _c("p", { staticClass: "other_project" }, [
              _vm._v("ЛИК:ЭКСПЕРТ для 1С"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "http://1clicom.ru/api.php", target: "_blank" } },
          [_c("p", { staticClass: "other_project" }, [_vm._v("ЛИК: API")])]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/LicMobile.apk" } }, [
          _c("p", { staticClass: "other_project" }, [
            _vm._v("ЛИК:ЭКСПЕРТ для Android"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://stopfaktor.ru" } },
          [_c("p", { staticClass: "other_project" }, [_vm._v("Стоп×Фактор")])]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("p", { staticClass: "other_product" }, [_vm._v("Социальные сети:")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://vk.com/club123681586" } },
          [_vm._v("vk.com")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://www.youtube.com/channel/UCYf8c5jNfdLjg1wFUZYby9Q",
            },
          },
          [_vm._v("youtube.com")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", [
        _c("span", [_vm._v("+37529 684-44-89")]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n                    +37517 292-45-28\n                    "),
        _c("br"),
        _vm._v("\n                    с 9 до 18 по Минску\n                "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { attrs: { id: "adress" } }, [
        _vm._v(
          "\n                    Минск, 1-й Измайловский переулок, д. 51, корп. 3\n                    "
        ),
        _c("br"),
        _vm._v("\n                    info@pravovoy.by\n                "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "thrid_column" }, [
      _c("li", [
        _c("span", [_vm._v("8 800 500-40-18")]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n                c 9 до 18 по Москве\n            "),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n                г. Москва, ул. Электрозаводская\n                "
        ),
        _c("br"),
        _vm._v("\n                д. 29, стр. 1, офис 410\n                "),
        _c("br"),
        _vm._v("\n                inf@1clicom.ru\n            "),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("Тех поддержка:")]),
        _vm._v(
          "\n                Телефон: 8 800 500-40-18 (доб 2)\n                "
        ),
        _c("br"),
        _vm._v("\n                Почта: support@1clicom.ru\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }