var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { printButton: false, downloadButton: false, close: _vm.onClose },
    },
    [
      _c("h3", { attrs: { slot: "modalHeader" }, slot: "modalHeader" }, [
        _vm._v("Политика обработки персональных данных"),
      ]),
      _vm._v(" "),
      _c("ul", { attrs: { slot: "modalBody" }, slot: "modalBody" }, [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "http://1clicom.ru/policy/1_Политика_обработки_персональных_данных.pdf",
                target: "_blank",
              },
            },
            [_vm._v("Политика обработки персональных данных")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "http://1clicom.ru/policy/2_Согласие_на_обработку_персональных_данных.pdf",
                target: "_blank",
              },
            },
            [_vm._v("Согласие на обработку персональных данных")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "http://1clicom.ru/policy/3_Согласие_на_получение_новостной_и_рекламной_рассылки.pdf",
                target: "_blank",
              },
            },
            [_vm._v("Согласие на получение новостной и рекламной рассылки")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }