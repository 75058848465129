var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "afil-network" } },
    [
      _vm.auth
        ? _c("div", { staticClass: "full-screen-menu" }, [
            _c(
              "span",
              {
                staticClass: "full-screen-menu__item",
                on: { click: _vm.showLegendToggle },
              },
              [
                !_vm.showLegend
                  ? _c("span", [
                      _vm._v("Показать легенду"),
                      _c("i", {
                        staticClass: "uk-icon-plus-square-o",
                        staticStyle: { "margin-left": "5px" },
                      }),
                    ])
                  : _c("span", [
                      _vm._v("Скрыть легенду"),
                      _c("i", {
                        staticClass: "uk-icon-minus-square-o",
                        staticStyle: { "margin-left": "5px" },
                      }),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTableList == false,
                    expression: "showTableList == false",
                  },
                ],
                staticClass: "full-screen-menu__item afil-show-list",
              },
              [
                _vm._v("\n            Список "),
                _c("i", { staticClass: "uk-icon-file-text-o" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTableList == true,
                    expression: "showTableList == true",
                  },
                ],
                staticClass: "full-screen-menu__item",
                on: {
                  click: function () {
                    _vm.showTableList = false
                  },
                },
              },
              [
                _vm._v("Закрыть список "),
                _c("i", { staticClass: "uk-icon-minus-square-o" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "full-screen-menu__item",
                on: { click: _vm.printHTML },
              },
              [
                _vm._v("Распечатать"),
                _c("i", {
                  staticClass: "uk-icon-print",
                  staticStyle: { "margin-left": "5px" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "full-screen-menu__item",
                on: { click: _vm.savePdf },
              },
              [
                _vm._v("Сохранить"),
                _c("i", {
                  staticClass: "uk-icon-save",
                  staticStyle: { "margin-left": "5px" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "full-screen-menu__item afil__resetDefault" },
              [
                _vm._v("Сбросить"),
                _c("i", {
                  class: { "uk-icon-refresh": true, rotating: _vm.resetActive },
                  staticStyle: { "margin-left": "5px" },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("shestirenka") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTableList == false,
              expression: "showTableList == false",
            },
          ],
          attrs: { id: "af-wrap" },
        },
        [
          _c("div", {
            style: _vm.fullScreenStyleObject.af,
            attrs: { id: "af" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLegend,
              expression: "showLegend",
            },
          ],
          attrs: { id: "afil-legend" },
        },
        [
          _c("ul", [
            _c("li", { ref: "edge.UPR" }, [
              !_vm.legend.edge.UPR
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", [_vm._v("Руководитель, Упр. компания")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.UPR_HISTORY" }, [
              !_vm.legend.edge.UPR_HISTORY
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", [_vm._v("Бывший руководитель")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.UCHR" }, [
              !_vm.legend.edge.UCHR
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", [_vm._v("Учредитель")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.UCHR_HISTORY" }, [
              !_vm.legend.edge.UCHR_HISTORY
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", [_vm._v("Бывший учредитель")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.REGISTRY_HOLDER" }, [
              !_vm.legend.edge.REGISTRY_HOLDER
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("div", [_vm._v("Реестродержатель")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.PREDSH" }, [
              !_vm.legend.edge.PREDSH
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("div", [_vm._v("Предшественник")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.PREEM" }, [
              !_vm.legend.edge.PREEM
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _c("div", [_vm._v("Преемник")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.RODSTV" }, [
              !_vm.legend.edge.RODSTV
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("div", [_vm._v("Родственник")]),
            ]),
            _vm._v(" "),
            _c("li", { ref: "edge.STAFF" }, [
              !_vm.legend.edge.STAFF
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _c("div", [_vm._v("Сотрудник")]),
            ]),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", { ref: "node.ULRos" }, [
              !_vm.legend.node.ULRos
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-building" }),
              _vm._v("\n                Юридическое лицо\n            "),
            ]),
            _vm._v(" "),
            _c("li", { ref: "node.ULIn" }, [
              !_vm.legend.node.ULIn
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-globe" }),
              _vm._v(
                "\n                Иностранное юридическое лицо\n            "
              ),
            ]),
            _vm._v(" "),
            _c("li", { ref: "node.FL" }, [
              !_vm.legend.node.FL
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-male" }),
              _vm._v("\n                Физическое лицо\n            "),
            ]),
            _vm._v(" "),
            _c("li", { ref: "node.IP" }, [
              !_vm.legend.node.IP
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-user" }),
              _vm._v(
                "\n                Индивидуальный предприниматель\n            "
              ),
            ]),
            _vm._v(" "),
            _c("li", { ref: "node.PIF" }, [
              !_vm.legend.node.PIF
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-university" }),
              _vm._v("\n                Субъект РФ, ПИФ\n            "),
            ]),
            _vm._v(" "),
            _c("li", { ref: "status.green" }, [
              !_vm.legend.status.green
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "status-color-block status-color-block__green",
              }),
              _vm._v("\n                Действущее\n            "),
            ]),
            _vm._v(" "),
            _c("li", { ref: "status.orange" }, [
              !_vm.legend.status.orange
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "status-color-block status-color-block__orange",
              }),
              _vm._v("\n                В стадии ликвидации\n            "),
            ]),
            _vm._v(" "),
            _c("li", { ref: "status.red" }, [
              !_vm.legend.status.red
                ? _c("div", { staticClass: "legend__line-through" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "status-color-block status-color-block__red",
              }),
              _vm._v("\n                Ликвидированое\n            "),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _vm.showTableList
        ? _c(
            "div",
            { ref: "afilTable" },
            [
              _c("img", {
                style: {
                  display: "block",
                  margin: "0 auto 20px",
                  "border-radius": "5px",
                  border: "1px solid #ddd",
                  "max-width": "70%",
                  height: "50%",
                },
                attrs: { id: "canvasToImgAsBaseUrl" },
              }),
              _vm._v(" "),
              _vm._l(_vm.modalList, function (e, i) {
                return _c(
                  "ul",
                  {
                    key: i,
                    staticClass: "affiliantion-modal-list",
                    style: { "margin-left": 5 + 40 * e.level + "px" },
                  },
                  [
                    e.level
                      ? _c(
                          "li",
                          { staticClass: "affiliantion-modal-list__level" },
                          [
                            _vm._v(
                              "\n                Уровень " +
                                _vm._s(e.level) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "affiliantion-modal-list__header" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#aaa",
                              "font-weight": "500",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(i + 1) +
                                ".\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        e.ogrn
                          ? _c("card-link", { attrs: { ogrn: e.ogrn } }, [
                              _vm._v(_vm._s(e.title)),
                            ])
                          : e.inn
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "load_person_affil load_person_affil__tooltip uk-pseudolink",
                                attrs: {
                                  "data-uk-modal":
                                    "{ target: '#modal-company-person_affil' }",
                                  "data-inn": e.inn,
                                  "data-name": e.title,
                                },
                                on: {
                                  click: function () {
                                    _vm.showTableList = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(e.title))]
                            )
                          : _c("span", [_vm._v(_vm._s(e.title))]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    e.inn || e.ogrn
                      ? _c("li", [
                          e.inn ? _c("b", [_vm._v("ИНН: ")]) : _vm._e(),
                          _vm._v(" "),
                          e.inn
                            ? _c("span", [_vm._v(_vm._s(e.inn))])
                            : _vm._e(),
                          _vm._v(" "),
                          e.ogrn ? _c("b", [_vm._v("ОГРН: ")]) : _vm._e(),
                          _vm._v(" "),
                          e.ogrn
                            ? _c("span", [_vm._v(_vm._s(e.ogrn))])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData &&
                    (e.tooltipData.date_start || e.tooltipData.date_stop)
                      ? _c("li", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getStatusIcon(e.tooltipData.status_type)
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticStyle: {
                                "padding-left": "2px",
                                "font-style": "italic",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.statusTypeText(
                                      e.tooltipData.status_type
                                    )
                                  ) +
                                  "\n                    " +
                                  _vm._s(
                                    e.tooltipData.date_stop
                                      ? new Date(
                                          e.tooltipData.date_stop
                                        ).toLocaleDateString()
                                      : new Date(
                                          e.tooltipData.date_start
                                        ).toLocaleDateString()
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData && e.tooltipData.addr_full
                      ? _c("li", [
                          _c("b", [_vm._v("Адрес: ")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(e.tooltipData.addr_full))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData &&
                    e.tooltipData.upr &&
                    e.tooltipData.upr.length
                      ? _c(
                          "li",
                          [
                            e.tooltipData.upr[0].position
                              ? _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.lowerButFirst(
                                        e.tooltipData.upr[0].position
                                      )
                                    ) + ": "
                                  ),
                                ])
                              : _c("b", [_vm._v("Руководитель: ")]),
                            _vm._v(" "),
                            e.tooltipData.upr[0].ogrn
                              ? _c(
                                  "card-link",
                                  {
                                    attrs: { ogrn: e.tooltipData.upr[0].ogrn },
                                  },
                                  [_vm._v(_vm._s(e.tooltipData.upr[0].name))]
                                )
                              : e.tooltipData.upr[0].inn
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "load_person_affil load_person_affil__tooltip uk-pseudolink",
                                    attrs: {
                                      "data-uk-modal":
                                        "{ target: '#modal-company-person_affil' }",
                                      "data-inn": e.tooltipData.upr[0].inn,
                                      "data-name": e.tooltipData.upr[0].name,
                                    },
                                    on: {
                                      click: function () {
                                        _vm.showTableList = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.lowerButFirstSentence(
                                          e.tooltipData.upr[0].name
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.lowerButFirstSentence(
                                        e.tooltipData.upr[0].name
                                      )
                                    )
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData &&
                    e.tooltipData.uchr &&
                    e.tooltipData.uchr.length
                      ? _c(
                          "li",
                          [
                            _c("b", [_vm._v("Собственники: ")]),
                            _vm._v(" "),
                            _vm._l(
                              e.tooltipData.uchr.filter(function (e, i) {
                                return i < 5
                              }),
                              function (uchrElm, uchrIndex) {
                                return _c(
                                  "span",
                                  { key: uchrIndex },
                                  [
                                    uchrElm.ogrn
                                      ? _c(
                                          "card-link",
                                          { attrs: { ogrn: uchrElm.ogrn } },
                                          [_vm._v(_vm._s(uchrElm.name))]
                                        )
                                      : uchrElm.inn
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "load_person_affil load_person_affil__tooltip uk-pseudolink",
                                            attrs: {
                                              "data-uk-modal":
                                                "{ target: '#modal-company-person_affil' }",
                                              "data-inn": uchrElm.inn,
                                              "data-name": uchrElm.name,
                                            },
                                            on: {
                                              click: function () {
                                                _vm.showTableList = false
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(uchrElm.name))]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(uchrElm.name)),
                                        ]),
                                    _vm._v(" "),
                                    uchrIndex !=
                                    e.tooltipData.uchr.filter(function (e, i) {
                                      return i < 5
                                    }).length -
                                      1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "-2px",
                                            },
                                          },
                                          [_vm._v(", ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData && e.tooltipData.okved_title
                      ? _c("li", [
                          _c("b", [_vm._v("ОКВЭД: ")]),
                          _vm._v(
                            "\n                " +
                              _vm._s(e.tooltipData.okved_title) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData && e.tooltipData.auth_capital
                      ? _c("li", [
                          _c("b", [_vm._v("Уставный капитал: ")]),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.moneyFormat(e.tooltipData.auth_capital)
                              ) +
                              " руб.\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    e.tooltipData &&
                    e.tooltipData.fin_years &&
                    e.tooltipData.fin_rations &&
                    e.tooltipData.fin_rations.viruchka &&
                    e.tooltipData.fin_years.length
                      ? _c("li", [
                          _c("b", [
                            _vm._v(
                              "Выручка за " +
                                _vm._s(
                                  e.tooltipData.fin_years[
                                    e.tooltipData.fin_years.length - 1
                                  ]
                                ) +
                                " год:"
                            ),
                          ]),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.moneyFormat(
                                  e.tooltipData.fin_rations.viruchka
                                )
                              ) +
                              " руб.\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__upr" },
      [_c("div", { staticClass: "afil-legend-edge__text" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__lupr" },
      [_c("div", { staticClass: "afil-legend-edge__text" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__uchr" },
      [_c("div", { staticClass: "afil-legend-edge__text" }, [_vm._v("100%")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__luchr" },
      [_c("div", { staticClass: "afil-legend-edge__text" }, [_vm._v("Учр.")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__reestr" },
      [
        _c("div", { staticClass: "afil-legend-edge__text" }, [
          _vm._v("Реестродерж."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__predsh" },
      [_c("div", { staticClass: "afil-legend-edge__text" }, [_vm._v("Предш")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__preem" },
      [_c("div", { staticClass: "afil-legend-edge__text" }, [_vm._v("Преем.")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__rodstv" },
      [
        _c("div", { staticClass: "afil-legend-edge__text" }, [
          _vm._v("Родств."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "afil-legend-edge afil-legend-edge__staff" },
      [
        _c("div", { staticClass: "afil-legend-edge__text" }, [
          _vm._v("Сотруд."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "affiliation-tile-node-info-wrap" } }, [
      _c("div", { attrs: { id: "affiliation-tile-node-info-close" } }, [
        _c("i", { staticClass: "uk-clsoe" }),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "affiliation-tile-node-header" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "affiliation-tile-node-info" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }