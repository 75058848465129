var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: "status-" + _vm.classes[_vm.status],
      style: "cursor: help; color: " + _vm.colors[_vm.status] + ";",
      attrs: { title: _vm.titles[_vm.status] },
    },
    [_c("i", { class: "uk-icon-" + _vm.icons[_vm.status] })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }