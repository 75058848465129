import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        bankRequisites: null,
        isBankrupt: false,
        isIntentions: false,
        restrictedList: [],
        sanctions: null
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setBankruptcyStatus(state, value) {
            state.isBankrupt = value
        },
        setIntentionStatus(state, value) {
            state.isIntentions = value
        }
    },
    actions: {
        getBankRequisites({ dispatch, commit, rootGetters }) {
            if(!rootGetters['card/inn']) { return }

            dispatch('v3/API', {
                method: 'invoices',
                data: {
                    inn: rootGetters['card/inn'],
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.data?.data?.length) {
                    commit('setStateObject', { name: 'bankRequisites', value: e.data.data.data[0] })
                }
            })
        },
        getBankruptStatus({ dispatch, commit, rootGetters }) {
            if(!rootGetters['card/inn']) { return }

            dispatch('v3/API', {
                method: 'bankrupt',
                data: {
                    action: 'status',
                    inn: rootGetters['card/inn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.code == 0 && e?.data?.data?.status == 1) {
                    commit('setBankruptcyStatus', true)
                }
            })
        },        
        getIntentions({ dispatch, commit, rootGetters }, { offset = 0, limit = 1 }) {
            dispatch('v3/API', {
                method: 'bankrupt_i',
                data: {
                    ogrn: rootGetters['card/ogrn'],
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                if(e?.data?.data?.list?.length) {
                    commit('setIntentionStatus', true)
                }
            })
        },
        getRestrictedUl({ dispatch, commit, rootGetters }) {
            dispatch('v3/API', {
                method: 'restrict_on_part_ul',
                data: {
                    action: 'get_info',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.code == 0 && e?.data?.data?.list?.length) {
                    commit('setStateObject', { name: 'restrictedList', value: e?.data?.data?.list })
                }
            })
        },
        getSanctions({ dispatch, commit, rootGetters }) {
            if(rootGetters['card/inn']) {
                dispatch('v3/API', {
                    method: 'sanctions',
                    data: {
                        action: 'get_info',
                        ogrn: rootGetters['card/ogrn'],
                        inn: rootGetters['card/inn']
                    }
                }, { root: true }).then(e => {
                    
                    commit('setStateObject', { name: 'sanctions', value: e?.data?.data })
                })
            }
        }
    },
    getters: {
        getFormattedList(state) {
            if(state.restrictedList) {
                const arr = []
                state.restrictedList.forEach(el => {
                    if(arr.some(e => e.inn_fl == el.inn_fl) && arr.indexOf(el) == -1) {
                        const elem = arr.find(a => a.inn_fl == el.inn_fl)
                        const index = arr.indexOf(elem)
                        arr[index].implication = `${arr[index].implication}/${el.implication}`
                    } else {
                        arr.push(el)
                    }
                })
                return arr
            }
        }
    }
}
