import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        loading: false,
        modalLoading: false,
        tendersData: [],
        list: {},
        statisticYears: [],
        pagination: {},
        products: [],
        done: false
    },
    getters: {
        statisticYears(state) {
            return Object.keys(state.list).slice(0, Object.keys(state.list).length - 1).reverse()
        },
        totalQuantity(state) {
            let box = []
            const list = Object.values(state.list).reverse()
            box = list.map(el => el.lots_not_contr)
            return box.slice(1)
        },
        totalPrices(state) {
            let box = []
            const list = Object.values(state.list).reverse()
            box = list.map(el => el.sum_not_contr)
            return box.slice(1)
        },
        doneQuantity(state) {
            let box = []
            const list = Object.values(state.list).reverse()
            box = list.map(el => el.lots_with_contr)
            return box.slice(1)
        },
        donePrices(state) {
            let box = []
            const list = Object.values(state.list).reverse()
            box = list.map(el => el.sum_with_contr)
            return box.slice(1)
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setModalLoading(state, loading) {
            state.modalLoading = loading
        },
        setItDone(state, done) {
            state.done = done
        }
    },
    actions: {
        getData({ commit, dispatch, rootGetters }, { offset = 0, limit = 50, year, total }) {
            commit('setModalLoading', true)

            return dispatch('v3/API', {
                method: 'tenders',
                data: { 
                    action: 'full_info',
                    ogrn: rootGetters['card/ogrn'],
                    type: 'customers',
                    year,
                    total,
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'tendersData', value: e?.data?.data?.list_tenders })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })

                return e?.data?.data
            }).finally(() => {
                commit('setModalLoading', false)
            })
        },
        getStats({ commit, dispatch, rootGetters }) {
            commit('setLoading', true)
            commit('setItDone', false)

            return dispatch('v3/API', {
                method: 'tenders',
                data: { 
                    action: 'stats_l',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list', value: e?.data?.data?.stats?.customer })

                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
                commit('setItDone', true)
            })
        },
        getProduct({ commit, dispatch, rootGetters }) {

            return dispatch('v3/API', {
                method: 'tenders',
                data: { 
                    action: 'stats_p',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.data && e?.data?.data?.stats && Object.values(e?.data?.data?.stats?.customer).length > 0) {
                    commit('setStateObject', { name: 'products', value: e?.data?.data?.stats?.customer })
                }

                return e?.data?.data
            })
        }
    }
}
