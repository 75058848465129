var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ogrn
    ? _c(
        "a",
        { attrs: { href: "/company/" + _vm.ogrn, target: "_blank" } },
        [_vm._t("default")],
        2
      )
    : _c("span", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }