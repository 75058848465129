<template>
    <footer>
        <div class="container">
            <ul class="first_column">
                <li><p id="name">© ЗАО «ЛИК», {{currentYear}}</p></li>
                <li><p>ИНН: 7719841799</p></li>
                <li><p style="padding-top: 5px;">ОГРН: 1137746308039</p></li>
                <li><a target="_blank" href="http://1clicom.ru/about.php"><p>О компании</p></a></li>
                <li><a target="_blank" href="http://1clicom.ru/bay.php"><p>Где купить</p></a></li>
                <li><button @click="showModal" class="personal-data-politics-info">Политика обработки персональных данных</button></li>
            </ul>
            <ul class="second_column">
                <li><a target="_blank" href="http://1clicom.ru/dogovor.php"><p class="license">Лицензионный договор</p></a></li>
                <li><p class="other_product">Другие продукты:</p></li>
                <li><a href="https://licexpert.ru/" target="_blank"><p class="other_project">ЛИК:ЭКСПЕРТ</p></a></li>
                <li><a href="http://1clicom.ru/expert_1c.php" target="_blank"><p class="other_project">ЛИК:ЭКСПЕРТ для 1С</p></a></li>
                <li><a href="http://1clicom.ru/api.php" target="_blank"><p class="other_project">ЛИК: API</p></a></li>
                <li><a href="/LicMobile.apk" ><p class="other_project">ЛИК:ЭКСПЕРТ для Android</p></a></li>
                <li><a target="_blank" href="https://stopfaktor.ru"><p class="other_project">Стоп×Фактор</p></a></li>
                <li><p class="other_product">Социальные сети:</p></li>
                <li><a target="_blank" href="https://vk.com/club123681586">vk.com</a></li>
                <li><a target="_blank" href="https://www.youtube.com/channel/UCYf8c5jNfdLjg1wFUZYby9Q">youtube.com</a></li>
            </ul>
            <ul v-if="origin == 'https://licexpert.by'" class="thrid_column">
                <li>
                    <p>
                        <span>+37529 684-44-89</span>
                        <br/>
                        +37517 292-45-28
                        <br/>
                        с 9 до 18 по Минску
                    </p>
                </li>
                <li>
                    <p id="adress">
                        Минск, 1-й Измайловский переулок, д. 51, корп. 3
                        <br/>
                        info@pravovoy.by
                    </p>
                </li>
            </ul>
            <ul class="thrid_column">
                <li>
                    <span>8 800 500-40-18</span>
                    <br>
                    c 9 до 18 по Москве
                </li>
                <li>
                    г. Москва, ул. Электрозаводская
                    <br>
                    д. 29, стр. 1, офис 410
                    <br/>
                    inf@1clicom.ru
                </li>
                <li>
                    <br>
                    <p>Тех поддержка:</p>
                    Телефон: 8 800 500-40-18 (доб 2)
                    <br>
                    Почта: support@1clicom.ru
                </li>
            </ul>
        </div>
        <a class="reestr-link" href="https://reestr.digital.gov.ru/reestr/515823/" target="_blank">Сервис ЛИК:ЭКСПЕРТ включён в Единый реестр российских программ для ЭВМ и баз данных</a>
        <p class="reestr-acred-companies">ЗАО "ЛИК" включено в реестр аккредитованных организаций, осуществляющих деятельность в области ИТ (№ АО-20220303-3736769171-3 от 04.03.2022)</p>
        <personal-data-modal v-if="isModal" @close="hideModal" />
        <cookie-baner v-if="isBaner" @agree="hideCookieBaner" />
    </footer>
</template>

<script>
import personalDataModal from '@/components/global/footer/personalDataModal.vue'
import cookieBaner from '@/components/ui/cookieBaner/cookieBaner.vue'

import { cookieLib } from '@/helpers.js'

export default {
    computed: {
        currentYear() { return new Date().getFullYear() },
        origin() { return window.location.origin},
        isBaner() { return Number(this.banerState) === 1 }
    },
    data() {
        return {
            isModal: false,
            banerState: '0'
        }
    },
    methods: {
        showModal() { this.isModal = true },
        hideModal() { this.isModal = false },
        hideCookieBaner() {
            cookieLib.setCookie('isBaner', '0', 30)
            this.banerState = cookieLib.getCookie('isBaner')
        }
    },
    components: { 
        personalDataModal,
        cookieBaner 
    },
    mounted() {
        this.banerState = cookieLib.getCookie('isBaner') || 1
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'
@import 'styles/global-variables.sass'

$footer-color: #fff
$footer-background-color: $licexpert-blue

footer
    font-weight: 500
    font-family: $montseratt
    background-color: $footer-background-color
    color: $light-text-color
    +mobile.desktop
        padding: 20px 0
    .first_column
        padding-left: 0
        p
            padding-top: 16px
        p#name
            padding: 0
    .thrid_column
        line-height: 24px
    .fourth_column
        a
            border: 0
    .container
        // width: 1200px
        text-align: center
        ul
            text-align: left
            margin: 0 35px
            vertical-align: top
            +mobile.desktop
                text-align: center
                display: block
                margin: 0 auto
                padding: 0
                &.thrid_column
                    margin-top: 20px
    ul
        padding-bottom: 40px
        padding-top: 24px

ul.first_column,
ul.second_column,
ul.thrid_column,
ul.fourth_column
    display: inline-block
    list-style: none
    margin: 0

.first_column p,
.second_column p,
.thrid_column p,
.fourth_column p
    margin: 0

.first_column
    padding-left: 274px
    font-size: 14px

.first_column a
    text-decoration: none
    color: $footer-color

.second_column
    padding-left: 69px
    font-size: 14px

.second_column a
    text-decoration: none
    color: $footer-color
    line-height: 1

.second_column p.other_product
    padding-top: 13px
    line-height: 1.5

.second_column p.other_project
    font-weight: 500
    color: $footer-color
    line-height: 1.2
    text-transform: uppercase
    margin-top: 12px

.thrid_column
    padding-left: 81px
    font-size: 14px

.thrid_column p
    line-height: 1.1

.thrid_column span
    color: $footer-color
    font-weight: 900
    font-size: 26px

footer .thrid_column p#adress
    padding-top: 16px
    font-size: 12px

.fourth_column
    padding-left: 67px

.fourth_column a.youtube
    padding-left: 16px

.fourth_column img
    width: 37px
    height: 37px

.fourth_column img
    width: 37px
    height: 37px
    margin-left: 12px

.fourth_column li
    display: inline-block

.reestr-link
    display: block
    width: 100%
    text-align: center
    color: #fff
    padding: 0 5px
.reestr-acred-companies
    display: block
    width: 100%
    text-align: center
    color: #fff
    padding: 0 5px
    font-size: 16px
.personal-data-politics-info
    margin: 0
    padding: 0
    background: none
    border: none
    cursor: pointer
    color: #fff
    font-family: "Montserrat", sans-serif
    font-size: 14px
    font-weight: 500
    margin-top: 16px
    &:hover
        color: #ff3232
+mobile.tablet
    footer .container ul
        margin: 0 !important
        padding: 10px 0
        width: 100%
        text-align: center !important
</style>