import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
class FeatherIcon extends Vue.extend({}) {
    @Prop(String) icon
    
    mounted() {
        window.feather.replace()
    }

    render() {
        return <i data-feather={this.icon}></i>
    }
}

export default FeatherIcon