<template>
    <div>
        <p><b>Всего: </b> {{afilArray.length}}</p>
        <p>
            <span><b>Действующие: </b> {{operating.length}} </span>
            <span><b>Ликвидированные: </b> {{liquidated.length}} </span>
            <span><b>Находятся в стадии ликвидации: </b>{{liquidation_process.length}} </span><br>
            <span v-if="(currentStakeholder.length || currentStakeholder.length === 0) && type !== 'history'"><b>Действующий {{typeString}}: </b> {{currentStakeholder.length}}</span>
            <span v-if="(former || former === 0) && type !== 'history'"><b>Бывший {{typeString}}: </b> {{former}}</span>
            
        </p>
    </div>
</template>

<script>
export default {
    props: {
        afilArray: Array,
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        typeString() {
            if(this.$props.type == 'upr' || this.$props.type == 'mbupr' || this.$props.type == 'upr_history') {
                return 'руководитель'
            } else if(this.$props.type == 'uchr' || this.$props.type == 'mbuchr' || this.$props.type == 'uchr_history') {
                return 'учредитель'
            }
        },
        former() {
            return this.$props.afilArray.length - this.currentStakeholder.length
        },
        currentStakeholder() {
            if(this.$props.type !== 'history') {
                return this.$props.afilArray.filter(e => e.stakeholder_type ? e.stakeholder_type === 'CURRENT' && !Number.isInteger(e.date_stop) : false)
            } else if(this.$props.type === 'history') {
                return []
            }
        },
        operating() {
            if(this.$props.type !== 'history') {
                return this.$props.afilArray.filter( e => e.status_type ? e.status_type[0] === 1 : false)
            } else if(this.type === 'history') {
                return this.$props.afilArray.filter(e => e.status ? e.status === 1 : false)
            }
        },
        liquidated() {
            if(this.$props.type !== 'history') {
                return this.$props.afilArray.filter( e => e.status_type ? e.status_type[0] === 0 : false)
            } else if(this.$props.type === 'history') {
                return this.$props.afilArray.filter(e => e.status ? e.status === 0 : false)
            }
        },
        liquidation_process() {
            if(this.$props.type !== 'history') {
                return this.$props.afilArray.filter( e => e.status_type ? e.status_type[0] === 2 : false)
            } else if(this.$props.type === 'history') {
                return this.$props.afilArray.filter(e => e.status ? e.status === 2 : false)
            }
        }
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'

p
    margin: 0
    padding: 0
    font-size: 14px
b
    font-weight: 600

+mobile.mobile
    span
        display: block
</style>