var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "tooltip", staticClass: "tooltip" }, [
    _c("img", {
      staticClass: "tooltip-img",
      attrs: { src: "/images/tooltip_inactive.png" },
      on: { click: _vm.openToolTip },
    }),
    _vm._v(" "),
    _vm.showToolTip
      ? _c(
          "div",
          {
            directives: [
              {
                name: "click-outside-component",
                rawName: "v-click-outside-component",
                value: _vm.closeToolTip,
                expression: "closeToolTip",
              },
            ],
            staticClass: "tooltip-block",
          },
          [
            _c("i", {
              staticClass: "uk-close",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeToolTip.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            !_vm.auth
              ? _c("div", { staticClass: "demo-message" }, [
                  _vm._v(
                    "\n            Информация в демо-режиме недоступна\n        "
                  ),
                ])
              : _vm.indexData
              ? _c("div", { staticClass: "tooltip-block-content" }, [
                  _c(
                    "div",
                    { staticClass: "tooltip-block-title" },
                    [
                      _vm.indexData.ogrn
                        ? _c(
                            "a",
                            {
                              staticClass: "blue-red-hover-text",
                              attrs: {
                                href: "/company/" + _vm.indexData.ogrn,
                                target: "_blank",
                              },
                            },
                            [
                              _vm.indexData.name_short
                                ? [_vm._v(_vm._s(_vm.indexData.name_short))]
                                : [_vm._v(_vm._s(_vm.indexData.name_full))],
                            ],
                            2
                          )
                        : [
                            _vm.indexData.name_short
                              ? [_vm._v(_vm._s(_vm.indexData.name_short))]
                              : [_vm._v(_vm._s(_vm.indexData.name_full))],
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "tooltip-block-ogrn-inn" }, [
                    _c("b", [_vm._v("ИНН")]),
                    _vm._v(": " + _vm._s(_vm.indexData.inn) + " "),
                    _c("b", [_vm._v("ОГРН")]),
                    _vm._v(
                      ": " + _vm._s(_vm.indexData.ogrn) + "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tooltip-block-date" },
                    [
                      _vm.indexData.date_stop
                        ? [
                            _c("i", {
                              staticClass: "uk-icon-times-circle",
                              staticStyle: { color: "#e74c3c", cursor: "help" },
                              attrs: { title: "Ликвидированно" },
                            }),
                            _vm._v(" "),
                            [
                              _c("i", [_vm._v("ликвидированно:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    new Date(
                                      _vm.indexData.date_stop
                                    ).toLocaleDateString()
                                  )
                              ),
                            ],
                          ]
                        : [
                            _c("i", {
                              staticClass: "uk-icon-check-circle",
                              staticStyle: { color: "#2ecc71", cursor: "help" },
                              attrs: { title: "Действующее" },
                            }),
                            _vm._v(" "),
                            [
                              _c("i", [_vm._v("зарегистрировано:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    new Date(
                                      _vm.indexData.date_start
                                    ).toLocaleDateString()
                                  )
                              ),
                            ],
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.indexData.addr_full
                    ? _c("div", { staticClass: "tooltip-block-address" }, [
                        _c("b", [_vm._v("Адрес:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.indexData.addr_full) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.indexData.upr[0] &&
                  _vm.indexData.upr.length &&
                  _vm.indexData.upr[0].position
                    ? _c(
                        "div",
                        { staticClass: "tooltip-block-upr" },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.lowerButFirst(_vm.indexData.upr[0].position)
                              ) + ":"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.indexData.upr[0].inn
                            ? _c(
                                "person-afil",
                                {
                                  staticClass: "stay-on-this-row",
                                  attrs: {
                                    fio: _vm.indexData.upr[0].name,
                                    inn: _vm.indexData.upr[0].inn,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.correctFio(
                                          _vm.indexData.upr[0].name
                                        ) +
                                          " (ИНН " +
                                          _vm.indexData.upr[0].inn +
                                          ")"
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.correctFio(_vm.indexData.upr[0].name)
                                  )
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm.indexData.upr[0]
                    ? _c(
                        "div",
                        [
                          _c("b", [_vm._v("Управляющая компания:")]),
                          _vm._v(" "),
                          _vm.indexData.upr[0].ogrn || _vm.indexData.upr[0].inn
                            ? _c(
                                "card-link",
                                {
                                  attrs: {
                                    ogrn:
                                      _vm.indexData.upr[0].ogrn ||
                                      _vm.indexData.upr[0].inn,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.indexData.upr[0].name) +
                                      " "
                                  ),
                                  _vm.indexData.upr[0].inn
                                    ? _c("span", [
                                        _vm._v(
                                          "(ИНН " +
                                            _vm._s(_vm.indexData.upr[0].inn) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm.indexData.upr[0].name
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.indexData.upr[0].name)),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tooltip-block-uchr" },
                    [
                      _c("b", [_vm._v("Собственники: ")]),
                      _vm._v(" "),
                      _vm.indexData.uchr && _vm.indexData.uchr.length
                        ? _vm._l(_vm.indexData.uchr, function (e, i) {
                            return _c(
                              "span",
                              { key: i },
                              [
                                Number(e.ogrn) ||
                                (e.inn && String(e.inn).length === 10)
                                  ? _c(
                                      "card-link",
                                      {
                                        attrs: {
                                          ogrn: Number(e.ogrn) || e.inn,
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(e.name)),
                                        e.inn
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(" (ИНН " + e.inn + ")")
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : e.inn
                                  ? _c(
                                      "person-afil",
                                      {
                                        staticClass: "stay-on-this-row",
                                        attrs: { fio: e.name, inn: e.inn },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.correctFio(e.name) +
                                                " (ИНН " +
                                                e.inn +
                                                ")"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v(_vm._s(e.name))]),
                                _vm._v(" "),
                                i != _vm.indexData.uchr.length - 1
                                  ? [_vm._v(", ")]
                                  : _vm._e(),
                              ],
                              2
                            )
                          })
                        : _c("span", [_vm._v("-")]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.indexData.okved_title
                    ? _c("div", { staticClass: "tooltip-block-okved" }, [
                        _c("b", [_vm._v("ОКВЭД:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.indexData.okved_title) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.indexData.auth_capital
                    ? _c("div", { staticClass: "tooltip-block-capital" }, [
                        _c("b", [_vm._v("Уставный капитал:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.moneyFormat(_vm.indexData.auth_capital)
                            ) +
                            " руб.\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.indexData.fin_years &&
                  _vm.indexData.fin_years.length &&
                  _vm.indexData.viruchka
                    ? _c(
                        "div",
                        { staticClass: "tooltip-block-viruchka" },
                        [
                          _c("b", [
                            _vm._v(
                              "Выручка за " +
                                _vm._s(
                                  _vm.indexData.fin_years[
                                    _vm.indexData.fin_years.length - 1
                                  ]
                                ) +
                                " год:"
                            ),
                          ]),
                          _vm._v(" "),
                          [
                            _vm._v(
                              _vm._s(_vm.moneyFormat(_vm.indexData.viruchka))
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm.isLoading && !_vm.indexData
              ? _c("loading")
              : !_vm.isLoading && !_vm.indexData
              ? _c("div", [_vm._v("\n            Нет данных\n        ")])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }