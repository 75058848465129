var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { printButton: false, downloadButton: false, close: _vm.onClose },
    },
    [
      _c("h3", { attrs: { slot: "modalHeader" }, slot: "modalHeader" }, [
        _vm._v("Файлы cookie"),
      ]),
      _vm._v(" "),
      _c("p", { attrs: { slot: "modalBody" }, slot: "modalBody" }, [
        _vm._v(
          "\n        Продолжая использовать сайт, Вы даете ЗАО «ЛИК» (ИНН 7719841799) согласие на обработку файлов cookies в т.ч. данных, собираемых посредством агрегаторов статистики посетителей веб-сайтов в целях ведения статистики посещений сайта. \n        Если Вы не хотите, чтобы Ваши вышеперечисленные данные обрабатывались, просим отключить обработку файлов cookies и сбор пользовательских данных в настройках Вашего браузера или покинуть сайт.\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }