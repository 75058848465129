<template>
    <div v-if="isDevMode">
        <span>{{width}} x {{height}}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            width: 0,
            height: 0
        }
    },
    methods: {
        resizeEvt() {
            this.width = window.innerWidth
            this.height = window.innerHeight
        }
    },
    mounted() {
        this.resizeEvt()
        window.addEventListener('resize', this.resizeEvt, true)
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeEvt)
    }
}
</script>

<style lang="sass" scoped>
div
    position: fixed
    z-index: 123456
    bottom: 10px
    left: 10px
    padding: 2px 5px
    background-color: rgba(0,0,0,.3)
    color: #fff
    font-weight: 500
    font-size: 12px
</style>