var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "afil-network-toolip" },
    [
      _c("i", {
        staticClass: "fas fa-project-diagram",
        on: {
          click: function ($event) {
            return _vm.networkClickHandle()
          },
        },
      }),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                close: function () {
                  this$1.showModal = false
                },
                downloadButton: false,
                showFullScreenIcon: true,
                title: "Схема аффилированности " + _vm.fio,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-header",
                  attrs: { slot: "modalHeader" },
                  slot: "modalHeader",
                },
                [
                  _vm._v("Схема аффилированности " + _vm._s(_vm.fio) + " "),
                  _c("span", { staticStyle: { "font-size": "14px" } }, [
                    _vm._v("ИНН: " + _vm._s(_vm.inn)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { padding: "15px", height: "100%" },
                  attrs: { slot: "modalBody" },
                  slot: "modalBody",
                },
                [
                  _vm.loading
                    ? _c("shestirenka")
                    : _c("newAfil", {
                        attrs: { ogrn: _vm.inn, group: _vm.personGroups[0].id },
                      }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }