<template>
    <modal
        :printButton="false"
        :downloadButton="false"
        :close="onClose"
    >
        <h3 slot="modalHeader">Политика обработки персональных данных</h3>
        <ul slot="modalBody">
            <li><a href="http://1clicom.ru/policy/1_Политика_обработки_персональных_данных.pdf" target="_blank">Политика обработки персональных данных</a></li>
            <li><a href="http://1clicom.ru/policy/2_Согласие_на_обработку_персональных_данных.pdf" target="_blank">Согласие на обработку персональных данных</a></li>
            <li><a href="http://1clicom.ru/policy/3_Согласие_на_получение_новостной_и_рекламной_рассылки.pdf" target="_blank">Согласие на получение новостной и рекламной рассылки</a></li>
        </ul>
    </modal>
</template>

<script>
import modal from '@/components/ui/modal/modal.vue'

export default {
    components: { modal },
    methods: {
        onClose() { this.$emit('close') }
    }
}
</script>

<style lang="sass" scoped>
    ul
        margin-top: 25px
</style>