import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        estate: null,
        vladenie: [],
        arenda: [],
        zalog: [],
        arendaEncumb: [],
        zalogEncumb: [],
        loading: false
    },
    getters: {
        hasEstate: state => !!state.estate?.total?.curr || !!state.estate?.total?.history
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootState, rootGetters }) {
            if(!rootState.card.info.inn) { return }
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'realestate',
                data: {
                    action: 'list_amount',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'estate', value: e?.data?.data })
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getObjectInfo({ commit, dispatch, rootState, rootGetters }, type) {
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'realestate',
                data: { 
                    action: 'additional_' + type,
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    offset: 0,
                    limit: 999999
                }
            }, { root: true }).then(e => {
                if(type == 'all' && e) {
                    commit('setStateObject', { name: 'vladenie', value: e.data?.data?.ownership?.list })
                    if(e.data?.data?.ownership?.list) {
                        const arendaTemp = []
                        const zalogTemp = []
                        e.data?.data?.ownership?.list.forEach(e => {
                            if(e.encumbranches) {
                                e.encumbranches.forEach(i => {
                                    if(i.type == 'Аренда (в том числе субаренда)' && e.encumbranches && e.encumbranches.length) {
                                        arendaTemp.push(e)
                                    } else if(i.type == 'Залог' && e.encumbranches && e.encumbranches.length) {
                                        zalogTemp.push(e)
                                    }
                                })
                            }
                        })
                        commit('setStateObject', { name: 'arendaEncumb', value: arendaTemp })
                        commit('setStateObject', { name: 'zalogEncumb', value: zalogTemp })    
                    }
                    commit('setStateObject', { name: 'arenda', value: e.data?.data?.rent?.list })
                    commit('setStateObject', { name: 'zalog', value: e.data?.data?.pledge?.list })
                }
            })
        }
    }
}

