import axios from 'axios'
import { set } from 'lodash'

const descriptiveTiles = {
    contacts: {
        name: 'Контактные данные и реквизиты',
        checked: true
    },
    stop: {
        name: 'Стоп факторы',
        checked: true
    },
    stakeholders: {
        name: 'Сведения о собственниках',
        checked: true
    },
    afil: {
        name: 'Аффилированность',
        checked: true
    },
    pre: {
        name: 'Предшественники и преемники',
        checked: true
    },
    uchr: {
        name: 'Учреждённые юридические лица',
        checked: true
    },
    upr: {
        name: 'Управляемые юридические лица',
        checked: true
    },
    fin_data: {
        name: 'Основные экономические показатели',
        checked: true
    },
    boo: {
        name: 'Бухгалтерская отчётность',
        checked: true
    },
    tax_deduction: {
        name: 'Расходы, отчисления и недоимки',
        checked: true
    },
    buisiness: {
        name: 'Бизнес-коэффициенты',
        checked: true
    },
    kad: {
        name: 'Арбитражная практика',
        checked: true
    },
    tender: {
        name: 'Тендеры',
        checked: true
    },
    executory: {
        name: 'Исполнительные производства',
        checked: true
    },
    realestate: {
        name: 'Объекты недвижимости',
        checked: true
    },
    license: {
        name: 'Лицензии',
        checked: true
    },
    sert_and_decl: {
        name: 'Сертификаты и декларации',
        checked: true
    },
    branches: {
        name: 'Филиалы и представительства',
        checked: true
    },
    kk: {
        name: 'Контрагенты / Конкуренты',
        checked: true
    },
    block_invoices: {
        name: 'Решения о блокировке расчётных счетов',
        checked: true
    },
    pledge: {
        name: 'Залоги',
        checked: true
    },
    leasing: {
        name: 'Лизинги',
        checked: true
    },
    okveds: {
        name: 'Заявленные виды деятельности',
        checked: true
    },
    history: {
        name: 'История изменений',
        checked: true
    },
    inspection: {
        name: 'Проверки',
        checked: true
    },
    codes: {
        name: 'Официальные коды',
        checked: true
    },
    ved: {
        name: 'Внешнеэкономическая деятельность',
        checked: true
    },
    reg_data: {
        name: 'Регистрационные действия',
        checked: true
    },
    trademark: {
        name: 'Товарные знаки',
        checked: true
    },
    msp_pp: {
        name: 'Меры поддержки',
        checked: true
    }
}

export default {
    namespaced: true,
    state: {
        tiles: descriptiveTiles,
        loading: false,
        complexHTML: ''
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setCheckedTiles(state, tiles) {
            state.tiles = tiles
        }
    },
    actions: {
        downloadKOV3({ state, getters,  dispatch, rootGetters, rootState }) {
            const filename = `ComplexReport_${rootGetters['card/ogrn']}.pdf`
            const title = `
                <h2 style="font-size: 25px; text-align: center; margin: 20px 0 15px 0;">
                    Комплексный отчёт | ${rootState.card.info.name_short}, ${getters.currentDate}
                </h2
            `
            
            dispatch('downloadPDF', {
                html: state.complexHTML,
                title,
                filename,
                target: 'ko'
            }, { root: true })
        },
        getComplex({ commit, state, rootGetters, rootState, dispatch }) {
            commit('setLoading', true)
            
            return axios({
                url: `${process.env.API_COMPLEX}/report`,
                method: 'post',
                data: {
                    auth: {
                        sid: window.localStorage.getItem('apiSession'),
                        cid: rootState.card.CID,
                        demo: false
                    },
                    method: 'plain_html',
                    data: {
                        ogrn: rootGetters['card/ogrn'],
                        modules: Object.keys(state.tiles).filter(key => state.tiles[key].checked)
                    }
                }
            }).then(res => {
                if(Number(res.data?.request?.code) === 18) {
                    dispatch('card/getCID')
                        .then(() => {
                            dispatch('getComplex')
                        })
                } else if(res.data) {
                    commit('setStateObject', { name: 'complexHTML', value: res.data })
                    commit('setLoading', false)
                    return res.data
                }
                
            }).catch(err => {
                console.error(err.message)
            })
        }
    },
    getters: {
        currentDate() { return new Date().toLocaleDateString('ru') }
    }
}