import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        loading: false,
        list_block_invoices: [],
        history_list: {},
        showCaptcha: false,
        actualDate: '',
        pagination: {},
        captcha: '',
        token: '',
        status: 2
    },
    getters: {
        actualList(state) {
            if(state && state.list_block_invoices) {
                return state?.list_block_invoices?.filter(e => e.actuality != 0 )
            } else {
                return []
            }
        },
        archivedList(state) {
            if(state && state.list_block_invoices) {
                return state?.list_block_invoices?.filter(e => e.actuality == 0 )
            } else {
                return []
            }
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setCaptcha(state, captcha) {
            state.showCaptcha = captcha
        }
    },
    actions: {
        getInfo({ commit, dispatch, rootState, rootGetters }, { offset = 0, limit = 25 }) {
            if(!rootState.card.info.inn) { return }
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'block_invoices',
                data: { 
                    action: 'get_info',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list_block_invoices', value: e?.data?.data?.list_block_invoices })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                commit('setStateObject', { name: 'status', value: parseInt(e?.data?.data?.status) })
                commit('setStateObject', { name: 'actualDate', value: e?.data?.data?.actual_date })

                if(e?.data?.data?.status == 0) {
                    setTimeout(() => dispatch('getInfo', { offset: 0, limit: 25 }), 10000)
                }
                return e?.data?.data
            }).finally(() => {
                dispatch('getHistory')
                commit('setLoading', false)
            })
        },
        getHistory({ commit, dispatch, rootState, rootGetters }) {
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'block_invoices',
                data: { 
                    action: 'get_history',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'history_list', value: e?.data?.data?.list_history_bi.reverse() })

                return e?.data?.data
            })
        },
        updateInfo({ commit, dispatch, state, rootState, rootGetters }, { captchaValue }) {
            commit('setCaptcha', false)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'block_invoices',
                data: { 
                    action: captchaValue ? 'upd_info' : 'auto_upd_info',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    captcha: captchaValue ? captchaValue : null,
                    token: state.token
                }
            }, { root: true }).then(e => {
                if(e?.data?.code == 0) {
                    dispatch('getInfo', { offset: 0, limit: 25 })
                }
            })
        },
        getCaptcha({ commit, dispatch, rootState, rootGetters }) {
            if(!rootState.card.ifo.inn) { return }

            return dispatch('v3/API', {
                method: 'block_invoices',
                data: { 
                    action: 'get_captcha',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn
                }
            }, { root: true }).then(e => {
                if(e?.data?.data) {
                    commit('setStateObject', { name: 'captcha', value: e?.data?.data?.captcha })
                    commit('setStateObject', { name: 'token', value: e?.data?.data?.token })
                    commit('setCaptcha', true)
                }
            })
        }
    }
}
