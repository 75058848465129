import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        modalLoading: false,
        inspections_list: {},
        list: null,
        pagination: {}
    },
    getters: {
        years(state) {
            return Object.keys(state?.inspections_list).reverse().slice(1)
        },
        total(state) {
            return state?.inspections_list?.total
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setModalLoading(state, loading) {
            state.modalLoading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootState, rootGetters }) {
            commit('setLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'inspection',
                data: { 
                    action: 'short',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'inspections_list', value: e?.data?.data })
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getModal({ commit, dispatch, rootState, rootGetters }, { offset = 0, limit = 25, year }) {
            commit('setModalLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'inspection',
                data: { 
                    action: 'full',
                    year,
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn,
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list', value: e?.data?.data?.list })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                return e?.data?.data
            }).finally(() => {
                commit('setModalLoading', false)
            })
        }
    }
}