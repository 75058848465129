import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        economics: null
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getEconomics({ commit, dispatch, rootGetters }) {
            commit('setLoading', true)
            if(!rootGetters['card/inn']) { return }

            return dispatch('v3/API', {
                method: 'economics',
                data: {
                    action: 'economic_indicators',
                    inn: rootGetters['card/inn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.data && e.data.data.constructor == Object) {
                    commit('setStateObject', { name: 'economics', value: Object.values(e.data.data) })
                }
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    }
}