var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "baner" },
      [
        _c("p", [
          _vm._v("Мы используем "),
          _c(
            "button",
            { staticClass: "modal-link", on: { click: _vm.showModal } },
            [_vm._v("файлы cookie.")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "btn",
          {
            nativeOn: {
              click: function ($event) {
                return _vm.agreeHandler.apply(null, arguments)
              },
            },
          },
          [_vm._v("Понятно")]
        ),
        _vm._v(" "),
        _vm.isModal
          ? _c("cookie-modal", { on: { close: _vm.hideModal } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }