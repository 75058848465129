<template>
    <div class="tooltip" ref="tooltip">
        <img class="tooltip-img" src="/images/tooltip_inactive.png" @click="openToolTip">

        <div class="tooltip-block" v-if="showToolTip" v-click-outside-component="closeToolTip">
            <i class="uk-close" @click.stop="closeToolTip"></i>
            <div v-if="!auth" class="demo-message" >
                Информация в демо-режиме недоступна
            </div>
            <div v-else-if="indexData" class="tooltip-block-content">
                <div class="tooltip-block-title">
                    <a v-if="indexData.ogrn" :href="`/company/${indexData.ogrn}`" target="_blank" class="blue-red-hover-text">
                        <template v-if="indexData.name_short">{{indexData.name_short}}</template>
                        <template v-else>{{indexData.name_full}}</template>
                    </a>
                    <template v-else>
                        <template v-if="indexData.name_short">{{indexData.name_short}}</template>
                        <template v-else>{{indexData.name_full}}</template>
                    </template>
                </div>
                <div class="tooltip-block-ogrn-inn">
                    <b>ИНН</b>: {{indexData.inn}} <b>ОГРН</b>: {{indexData.ogrn}}
                </div>

                <div class="tooltip-block-date">
                    <template v-if="indexData.date_stop">
                        <i style="color: #e74c3c; cursor: help;" title="Ликвидированно" class="uk-icon-times-circle"></i>
                        <template> <i>ликвидированно:</i> {{new Date(indexData.date_stop).toLocaleDateString()}}</template>
                    </template>
                    <template v-else>
                        <i style="color: #2ecc71; cursor: help;" title="Действующее" class="uk-icon-check-circle"></i>
                        <template> <i>зарегистрировано:</i> {{new Date(indexData.date_start).toLocaleDateString()}}</template>
                    </template>
                </div>
                <div class="tooltip-block-address" v-if="indexData.addr_full">
                    <b>Адрес:</b> {{indexData.addr_full}}
                </div>
                <div class="tooltip-block-upr" v-if="indexData.upr[0] && indexData.upr.length && indexData.upr[0].position">
                    <b>{{lowerButFirst(indexData.upr[0].position)}}:</b>
                    <person-afil
                        class="stay-on-this-row"
                        v-if="indexData.upr[0].inn"
                        :fio="indexData.upr[0].name"
                        :inn="indexData.upr[0].inn"
                    >
                        {{ correctFio(indexData.upr[0].name) + ` (ИНН ${indexData.upr[0].inn})` }}
                    </person-afil>
                    <span v-else>{{ correctFio(indexData.upr[0].name) }}</span>
                </div>
                <div v-else-if="indexData.upr[0]">
                    <b>Управляющая компания:</b>
                    <card-link v-if="indexData.upr[0].ogrn || indexData.upr[0].inn" :ogrn="indexData.upr[0].ogrn || indexData.upr[0].inn">
                        {{indexData.upr[0].name}} <span v-if="indexData.upr[0].inn">(ИНН {{indexData.upr[0].inn}})</span>
                    </card-link>
                    <span v-else-if="indexData.upr[0].name">{{indexData.upr[0].name}}</span>
                    <span v-else>-</span>
                </div>
                <div class="tooltip-block-uchr" >
                    <b>Собственники: </b>
                    <template v-if="indexData.uchr && indexData.uchr.length">
                        <span v-for="(e, i) in indexData.uchr" :key="i">
                            <card-link v-if="Number(e.ogrn) || (e.inn && String(e.inn).length === 10)" :ogrn="Number(e.ogrn) || e.inn">{{ e.name }}<span v-if="e.inn">{{ ` (ИНН ${e.inn})` }}</span></card-link>
                            <person-afil
                                v-else-if="e.inn"
                                class="stay-on-this-row"
                                :fio="e.name"
                                :inn="e.inn"
                            >
                                {{ correctFio(e.name) + ` (ИНН ${e.inn})` }}
                            </person-afil>
                            <span v-else>{{e.name}}</span>
                            <template v-if="i != indexData.uchr.length - 1">, </template>
                        </span>
                    </template>
                    <span v-else>-</span>
                </div>
                <div class="tooltip-block-okved" v-if="indexData.okved_title">
                    <b>ОКВЭД:</b> {{indexData.okved_title}}
                </div>
                <div class="tooltip-block-capital" v-if="indexData.auth_capital">
                    <b>Уставный капитал:</b> {{moneyFormat(indexData.auth_capital)}} руб.
                </div>
                <div class="tooltip-block-viruchka" v-if="indexData.fin_years && indexData.fin_years.length && indexData.viruchka">
                    <b>Выручка за {{indexData.fin_years[indexData.fin_years.length - 1]}} год:</b>
                    <template>{{moneyFormat(indexData.viruchka)}}</template>
                </div>
            </div>
            <loading v-else-if="isLoading && !indexData"/>
            <div v-else-if="!isLoading && !indexData">
                Нет данных
            </div>
           
            
        </div>
       
    </div>
</template>

<script>
import axios from 'axios'
import personAfil from '@/components/ui/personAfil/personAfil.vue'
import cardLink from '@/components/ui/cardLink.vue'
import shestirenkaLoader from '@/components/ui/shestirenkaLoader.vue'

export default {
    name: 'tooltip',
    props: ['ogrn'],
    components: {
        personAfil,
        cardLink,
        loading: shestirenkaLoader
    },
    data() {
        return {
            showToolTip: false,
            indexData: null,
            isLoading: false
        }
    },
    methods: {
        closeToolTip() { this.showToolTip = false },
        openToolTip() {
            this.getCompanyIndexData()
            this.showToolTip = true
        },
        getCompanyIndexData() {
            this.isLoading = true
            if(!this.indexData) {
                axios({
                    method: 'get',
                    url: process.env.API_PRO + '/company/' + this.$props.ogrn + '/index',
                    headers: {
                        'Authorization': `Bearer ${window.localStorage.getItem('apiSession')}`
                    }
                }).then(e => {
                    if(e?.data?.status?.code == 200) {
                        this.indexData = e.data.data
                    }
                }).finally(() => { this.isLoading = false })
            }
        }
    },
    computed: {
        auth() {
            return localStorage.getItem('auth') === 'true'
        }
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'
@import 'styles/global-variables.sass'

.tooltip
    position: relative
    display: inline-block
    white-space: initial
    &-img
        cursor: pointer
        opacity: 0.5
        transition: 0.1s all linear
        &:hover
            opacity: 0.75
    &-block
        position: absolute
        z-index: 20
        background: #f5f5f5
        width: 350px
        box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5)
        padding: 15px
        border: 1px #c5c5c5 solid
        border-radius: 4px
        color: black
        font-size: 12px
        font-weight: 400
        +mobile.mobile
            position: fixed
            top: 170px
            left: 10px
            width: calc(100% - 20px)
        &-title
            font-weight: 700
            font-size: 14px
        .uk-close
            display: inline-block
            position: absolute
            right: 10px
            top: 10px
            font-size: 25px
        .demo-message
            padding: 10px
            font-size: 13px
        &-content
            display: flex
            flex-direction: column
            gap: 5px
            width: 300px
        &-ogrn-inn
            font-style: italic
.stay-on-this-row
    display: inline
</style>