import { set } from 'lodash'
import { objectMerger } from '@/helpers'

export default {
    namespaced: true,
    state: {
        historyFnsEgrul: {},
        historyRosstat: {}
    },
    getters: {
        list({ historyFnsEgrul }) {
            const dictionary = {
                ruk: {
                    name: 'Руководитель',
                    container: []
                },
                namep: { 
                    name: 'Наименование организации',
                    container: []
                },
                names: { 
                    name: 'Короткое наименование организации',
                    container: []
                },        
                addr: {
                    name: 'Адрес', 
                    container: []
                },
                ustCap: {
                    name: 'Уставной капитал',
                    container: []
                },
                okved: {
                    name: 'Основной вид деятельности',
                    container: []
                },
                Usumma: { 
                    name: 'Доля организации',
                    container: []
                },    
                uch_fio_fl: { 
                    name: 'ФИО совладельцев',
                    container: []
                },
                uchr_dol: { 
                    name: 'Совладельцы',
                    container: []
                },
                uchr_inn: { 
                    name: 'ИНН совладельцев',
                    container: []
                },
                uchr: { 
                    name: 'Совладельцы',
                    container: []
                },
                uchr_namep: { 
                    name: 'Наименование совладельцев',
                    container: []
                },
                upr_inn: {
                    name: 'ИНН управляющей организации',
                    container: []
                },
                upr_namep: {
                    name: 'Наименование управляющей организации',
                    container: []
                },
                upr: {
                    name: 'Управляющая организация',
                    container: []
                },
                pred_inn: {
                    name: 'ИНН предшественника',
                    container: []
                },
                pred_namep: {
                    name: 'Наименование предшественника',
                    container: []
                },
                pred: {
                    name: 'Предшественники',
                    container: []
                },
                preem_inn: {
                    name: 'ИНН преемника',
                    container: []
                },
                preem_namep: {
                    name: 'Наименование преемника',
                    container: []
                },
                preem: {
                    name: 'Преемники',
                    container: []
                }
            }
            if(historyFnsEgrul?.length) {
                historyFnsEgrul.forEach(el => {
                    dictionary[el.changes.title.value].container.push(el)
                })
            }

            return dictionary
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getHistoryFns({ commit, dispatch, rootGetters }) {

            return dispatch('card/getHistory', {
                source: 'egrul',
                ogrn: rootGetters['card/ogrn']  
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'historyFnsEgrul', value: e?.data?.data.map(e => ({ changes: objectMerger(e.changes) })) })

                return e?.data?.data
            })
        },
        getHistoryRosstat({ commit, dispatch, rootGetters }) {

            return dispatch('card/getHistory', {
                source: 'rosstat',
                ogrn: rootGetters['card/ogrn']  
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'historyRosstat', value: e?.data?.data })

                return e?.data?.data
            })
        }
    }
}
