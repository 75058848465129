<template>
    <div class="person-afil">
        <span id="person-affil-btn" class="blue-red-hover-text" @click="FIOclickHandle"><slot/></span>

        <afilNetWorkTooltip v-if="showAfilNetworkTooltip" :inn="inn" :fio="fio" />

        <modal 
            v-if="showModal" 
            :close.stop="closeModal" 
            :title="`Аффилированность ${this.inn_get.length > 1?'всех собственников-ФЛ с долей более 10%' : ''}`" 
            :hasFooter="true"
            :printButton="true"
            :downloadButton="true"
        >
            <div slot="modalHeader">Аффилированность {{this.inn_get.length > 1?'всех собственников-ФЛ с долей более 10%' : ''}}</div>
            <div slot="modalBody" style="padding: 15px 15px 5px">
                <div class="affil-link-table" v-if="inn_get && inn_get.length > 1">
                    <span class="blue-red-hover-text" v-for="(e,i) in inn_get" :key="i" @click="scrollIntoView(inn_get[i])">
                        {{firstCapitalLetter(fio_get[i])}}<br>
                    </span>
                </div>
                <div v-for="(e,i) in inn_get" :key="i">
                    <div :id="'inn-anchor-' + inn_get[i]"><b>ФИО:</b> {{firstCapitalLetter(fio_get[i])}}</div>
                    <div><b>ИНН:</b> {{inn_get[i]}}</div>
                    <div>
                        <div v-if="loading">
                            <shestirenka/>
                        </div>
                        <div v-else-if="afilData[i]">
                            <div v-if="afilData[i].upr.length && afilData[i].uchr.length">
                                <massFactor v-if="afilData[i].uchr.filter(e => !e.stakeholder_date_end).length >= 5">
                                    Является учредителем в {{afilData[i].uchr.filter(e => !e.stakeholder_date_end || e.date_end).length}} ЮЛ и имеет признаки «массового учредителя».
                                </massFactor>
                                <massFactor v-if="afilData[i].upr.filter(e => !e.stakeholder_date_end || e.date_end    ).length >= 10">
                                    Является руководителем в {{afilData[i].upr.filter(e => !e.stakeholder_date_end).length}} ЮЛ и имеет признаки «массового руководителя».
                                </massFactor>
                            </div>
                            <div v-if="afilData[i].upr && afilData[i].upr.length">
                                <p class="type-header">{{typeName("upr")}}</p>
                                <personAfilTable :afilArray="afilData[i].upr"/>
                                <personAfilStats :afilArray="afilData[i].upr" :type="'upr'"/>
                            </div>
                            <div v-if="afilData[i].uchr && afilData[i].uchr.length">
                                <p class="type-header">{{typeName("uchr")}}</p>
                                <personAfilTable :afilArray="afilData[i].uchr"/>
                                <personAfilStats :afilArray="afilData[i].uchr" :type="'uchr'"/>
                            </div>
                            <div v-if="afilData[i].mbupr && afilData[i].mbupr.length">
                                <p class="type-header">{{typeName("mbupr")}}</p>
                                <personAfilTable :afilArray="afilData[i].mbupr"/>
                                <personAfilStats :afilArray="afilData[i].mbupr" :type="'mbupr'"/>
                            </div>
                            <div v-if="afilData[i].mbuchr && afilData[i].mbuchr.length">
                                <p class="type-header">{{typeName("mbuchr")}}</p>
                                <personAfilTable :afilArray="afilData[i].mbuchr"/>
                                <personAfilStats :afilArray="afilData[i].mbuchr" :type="'mbuchr'"/>
                            </div>
                            <div v-if="afilData[i].upr_history && afilData[i].upr_history.length">
                                <p class="type-header">{{typeName("upr_history")}}</p>
                                <personAfilTable :afilArray="afilData[i].upr_history"/>
                                <personAfilStats :afilArray="afilData[i].upr_history" :type="'upr_history'"/>
                            </div>
                            <div v-if="afilData[i].uchr_history && afilData[i].uchr_history.length">
                                <p class="type-header">{{typeName("uchr_history")}}</p>
                                <personAfilTable :afilArray="afilData[i].uchr_history"/>
                                <personAfilStats :afilArray="afilData[i].uchr_history" :type="'uchr_history'"/>
                            </div>
                            <div v-if="history[i]">
                                <p class="type-header">Исторические связи</p>
                                <personAfilTable :afilArray="history[i].ul"/>
                                <personAfilStats :afilArray="history[i].ul" :type="'history'"/>
                            </div> 
                            <br>
                        </div>
                    </div>    
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import styled from 'vue-styled-components'
import modal from '@/components/ui/modal/modal.vue'
import shestirenka from '@/components/ui/shestirenkaLoader.vue'
import afilNetWorkTooltip from '@/components/ui/afilNetworkTooltip/afilNetworkTooltip.vue'
import personAfilTable from './personAfilTable.vue'
import personAfilStats from './personAfilStats.vue'
import axios from 'axios'
// import modal from 'modal'

const massFactor = styled.div`
    color: red;
    border: 0;
    margin: 10px 0;
    font-weight: 300;
`

export default {
    components: {
        modal,
        shestirenka,
        personAfilTable,
        personAfilStats,
        afilNetWorkTooltip,
        massFactor
    },
    props: {
        inn: [String, Array],
        fio: [String, Array], 
        showAfilNetworkTooltip: Boolean
    },
    data() {
        return {
            showModal: false,
            loading: false,
            afilData: [],
            history: []
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
            this.$emit('close', null)
        },
        scrollIntoView(inn) {
            $('#inn-anchor-'+inn)[0].scrollIntoView()
        },
        FIOclickHandle() {
            if(this.showModal) { return }
            this.loading = false
            this.showModal = true
            for(var i in this.inn_get) {
                axios({
                    url: `${process.env.API_PRO}/company/${this.inn_get[i]}/afil?demo=1&rtype=fl&group_by=shtype&name=${this.fio_get[i]}${window.refresh_cache ? '&_refresh_cache=true' : ''}`,
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('apiSession')}`
                    }
                }).then(res => {
                    if(res?.data?.status?.code == 204 || res?.data?.data?.length == 0) {
                        this.$store.dispatch('showErrorPopUp', 'Данные не найдены')
                    } else if(res?.data?.status?.code != 200) {
                        this.$store.dispatch('showErrorPopUp', 'Ошибка API')
                    } else {
                        this.afilData.push(res?.data?.data)
                    }
                }).finally(() => {                 
                    this.loading = false
                })

                if(this.inn_get[i]) {
                    this.$store.dispatch('v3/API', {
                        method: 'affil_history',
                        data: { inn_fl: this.inn_get[i].toString() }
                    }).then(e => {
                        if(e?.data?.data && e?.data?.data?.count) {
                            this.history.push(e.data.data)
                        }
                    })
                }
            } 
                           
        },
        typeName(type) {
            switch(type) {
                case 'mbuchr': return 'Может являться учредителем (совпадение по ФИО)'
                case 'mbupr': return 'Может являться руководителем (совпадение по ФИО)'
                case 'uchr': return 'Является/являлся учредителем (совпадение по ИНН)'
                case 'upr': return 'Является/являлся руководителем (совпадение по ИНН)'
                case 'uchr_history': return 'Являлся учредителем (совпадение по ИНН)'
                case 'upr_history': return 'Являлся руководителем (совпадение по ИНН)'
            }
        }
    },
    computed: {
        inn_get() {
            return (typeof this.inn) === 'string' ? [this.inn] : this.inn
        },
        fio_get() {
            return (typeof this.fio) === 'string' ? [this.fio] : this.fio
        }
    }
}

</script>

<style lang="sass" scoped>
@import 'styles/global-variables.sass'

.person-afil
    color: initial
    font-weight: initial
    .fas.fa-project-diagram
        @extend .blue-red-hover-text
        font-weight: 600
    .type-header
        border-bottom: 1px solid #005fb3
        color: #005fb3
        font-size: 20px
        margin: 30px 0 10px 0
.button-wrapper
    padding: 25px 0 5px 0
.affil-link-table
    margin: 0 0 20px 0 !important    
</style>