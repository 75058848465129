import axios from 'axios'
import { set } from 'lodash'
import {
    getQueryStringValue,
    htmlStringToPrint,
    translit,
    Base64,
    downloadBinaryFile,
    downloadByUrl
} from '@/helpers.js'
import searchStateModule from '@/components/search/searchStateModule.js'
import cardStore from '@/components/card/cardStore.js'
import monitoringStore from '@/components/monitoring/monitoringStore.js'
import profileStore from '@/components/profile/profileStore.js'
import headerStore from '@/components/global/header/headerStore.js'
import relationsSearchStore from '@/components/global/RelationsSearch/relationsSearchStore.js'
import newMainStore from './components/main/newMainStore'
import userModule from './modules/userModule'
import { cookieLib } from '@/helpers.js'

const dadataModule = {
    namespaced: true,
    state: {
        token: process.env.DADATA_TOKEN
    },
    actions: {
        getAddress({ getters }, payload) {
            return getters.api('address', payload)
        },
        getSuggestions({ getters }, payload) {
            return getters.api('party', payload)
        }
    },
    getters: {
        api: ({ token }) => (action, query, count) => {
            return axios({
                url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${action}`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Token ${token}`
                },
                data: {
                    query,
                    count
                }
            }).then(e => e.data)
        }
    }
}

const v3Module = {
    namespaced: true,
    name: 'v3',
    state: {
        downloadURL: process.env.API_DOWNLOAD
    },
    actions: {
        API({ dispatch, rootState, rootGetters }, { method, data, reqOpts, isRecursion = false }) {
            return axios({
                baseURL: `${process.env.API}/?${method}`,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                ...reqOpts,
                data: {
                    auth: {
                        sid: window.localStorage.getItem('apiSession') || cookieLib.getCookie('user_sid'),
                        cid: rootState.card.CID,
                        demo: false
                    },
                    method,
                    data
                }
            }).then(res => {
                if(res.data?.auth?.code === 18 && isRecursion !== true) {
                    return dispatch('card/getCID', rootGetters['card/ogrn'], { root: true })
                        .then(() => {
                            return dispatch('API', { method, data, reqOpts, isRecursion: true })
                        })
                    
                } else {
                    switch(Number(res?.data?.code)) {
                        case 105:
                            dispatch('showErrorPopUp', `Введены неверные параметры ${method}`, { root: true })
                            break
                        case 101:
                            dispatch('showErrorPopUp', `Неизвестная ошибка API ${method}`, { root: true })
                            break
                        case 130:
                            dispatch('showErrorPopUp', `
                                С вашего IP адреса обнаружены автоматизированные запросы.
                                Обратитесь в техподдержку по телефону <a href="tel:8 800 500-40-18">8 800 500-40-18</a> или <a href="emailto:support@1clicom.ru">support@1clicom.ru.</a> 
                            `)
                            break
                        default:
                            return res
                    }
                }
            }).catch(err => {
                if(!err.status) {
                    dispatch('showErrorPopUp', `Неизвестная ошибка ${method}`, { root: true })
                }
            })
        }
    }
}

const globalStore = {
    state: {
        desktopResolution: 960,
        axios: {
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem('apiSession'),
                'Cid': window.cid
            }
        },
        news: {
            data: [],
            total: null,
            status: false
        },
        specialPriviliges: {
            employeeCount: [
                'TechAviaM_LE',
                'TechnoAvia_LE',
                'Profile12',
                'Program018',
                'rvv2016',
                'YugoPolis',
                'program017',
                'visor1',
                'visor2',
                'visor3',
                'visor4',
                'visor5',
                'Starikov_LE',
                'TechAviaU_LE'
            ].map(e => e.toLowerCase())
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        }
    },
    actions: {
        async init({ dispatch }) {
            dispatch('getSidQueryParamFromOldLicexpert')
            await dispatch('user/status')
            if(JSON.parse(window.localStorage.getItem('auth'))) {
                await dispatch('user/getUserInfo')
                dispatch('monitoring/getAllCompanies')
                dispatch('profile/getCombinedHistory', { offset: 0, limit: 50 })
                dispatch('profile/getEGRN')
                dispatch('profile/getECP')
            }
        },
        getSidQueryParamFromOldLicexpert() {
            const sidFromQueryString = getQueryStringValue('sid')
            if(sidFromQueryString) {
                window.localStorage.setItem('apiSession', sidFromQueryString)
            }
        },
        downloadPDF({ dispatch, getters }, { title, html, filename = null, target = 'tile', style = '' }) {
            const htmlCode = htmlStringToPrint(title, html, `${style} canvas {display: none !important;} table {border-collapse: collapse; width: 100%;} #print-pdf-hint { display: none } .no_print {display: none !important} .no_print-pre {white-space: pre-line;} .bold__date{font-weight: bold;} td, th{padding: 10px 0; text-align: center; border-width: 1px; border-style: solid;border-collapse: collapse; border-color: #777778;} .fa-check, .print-green{color: #27ae60;} .fa-times{color: #e74c3c;} .print-red {text-decoration: line-through; color: #e74c3c;} .print-table{width: 100%;} .print-image{max-width: 100px} .tax__table_green{color: #3cc274;} .tax__table_red{color: #D24135;} .sro__item_inactive {color: #D24135;} .sro__item_active{color: #3cc274;}` )
            const currentFilename = filename || `${translit(title)}.pdf`
            
            return dispatch('v3/API', {
                method: 'save_file',
                data: {
                    save_to_history: false, 
                    source: process.env.PRODUCT_CODE,
                    format: 'pdf',
                    landspace: false,
                    target,
                    out_name: currentFilename,
                    ogrn: parseInt(getters['card/ogrn']),
                    html: Base64.encode(htmlCode)
                },
                reqOpts: {
                    responseType: 'arraybuffer'
                }
            }, { root: true }).then(res => {
                if(res?.status === 200) {
                    downloadBinaryFile(res.data, currentFilename)
                } else {
                    dispatch('showErrorPopUp', 'Во время загрузки файла произошла ошибка.', { root: true })
                }
            })
        },
        print(_, { title, html }) {
            const htmlCode = htmlStringToPrint(title, html, 'canvas {display: none !important;} table {border-collapse: collapse; width: 100%;} #print-pdf-hint { display: none } .no_print {display: none !important} .no_print-pre {white-space: pre-line;} .bold__date{font-weight: bold;} td, th{padding: 10px 5px; text-align: center; border-width: 1px; border-style: solid;border-collapse: collapse; border-color: #777778;} .fa-check, .print-green{color: #27ae60;} .fa-times{color: #e74c3c;} .print-red {text-decoration: line-through; color: #e74c3c;} .print-table{width: 100%;} .print-image{max-width: 100px} .tax__table_green{color: #3cc274;} .tax__table_red{color: #D24135;} .sro__item_inactive {color: #D24135;} .sro__item_active{color: #3cc274;}')
            const printWin = window.open()
            printWin.document.write(htmlCode)
        },
        downloadFile(_, { url }) {
            downloadByUrl(url)
        },
        showErrorPopUp(_, payload) {
            this._vm.$notify(payload)
        }
    },
    modules: {
        relations: relationsSearchStore,
        search: searchStateModule,
        card: cardStore,
        dadata: dadataModule,
        v3: v3Module,
        user: userModule,
        monitoring: monitoringStore,
        profile: profileStore,
        header: headerStore,
        main: newMainStore
    }
}



export default globalStore