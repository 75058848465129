var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDevMode
    ? _c("div", [
        _c("span", [_vm._v(_vm._s(_vm.width) + " x " + _vm._s(_vm.height))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }