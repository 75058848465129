import axios from 'axios'
import { set } from 'lodash'

const monitoringModule = {
    namespaced: true,
    state: {
        company: [],
        allCompanies: [],
        pagination: null,
        loading: false
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        }
    },
    actions: {
        getAllCompanies({ rootState, commit }, payload = { limit: 50, offset: 0, category_id: 0 }) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''
            const category_id = payload.category_id ? `&category_id=${payload.category_id}` : ''

            return axios({
                method: 'get',
                url: `${process.env.API_PRO}/monitor/company?demo=true&user_group_id=${userGroup}${category_id}`,
                headers: { Authorization: `Bearer ${apiSession}` }
            }).then(e => {
                if(e.data.data) {
                    commit('setStateObject', { name: 'allCompanies', value: e.data.data })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getCompanies({ rootState, commit }, payload = { limit: 50, offset: 0, category_id: 0 }) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''
            const { limit, offset } = payload
            const category_id = payload.category_id ? `&category_id=${payload.category_id}` : ''
            commit('setStateObject', { name: 'loading', value: true })

            return axios({
                method: 'get',
                url: `${process.env.API_PRO}/monitor/company?demo=true&limit=${limit}&offset=${offset}&user_group_id=${userGroup}${category_id}`,
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e) {
                    if(e.data.data) {
                        commit('setStateObject', { name: 'company', value: e.data.data })
                    }
                    if(e.data.pagination) {
                        commit('setStateObject', { name: 'pagination', value: e.data.pagination })
                    }
                }
                commit('setStateObject', { name: 'loading', value: false })
            }).catch(err => {
                console.log(err)
                commit('setStateObject', { name: 'loading', value: false })
            })
        },
        async addCompany({ rootState, dispatch }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            const addCompanyResult = await axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/insert?demo=true&limit=100&offset=0&user_group_id=${userGroup}&category_id=1&ogrns[]=${payload}`,
                headers: { Authorization: `Bearer ${apiSession}` }
            })

            if(addCompanyResult?.data?.status?.code == 201) {
                await dispatch('getAllCompanies')
            } else if(addCompanyResult?.data?.status?.message == 'Возможно, закончился лимит по подписке') {
                dispatch('showErrorPopUp', 'Закончился лимит по подписке',  { root: true })
            }

            return addCompanyResult
        },
        async removeCompany({ rootState, dispatch }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            const removeCompanyResult = await axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/delete?demo=true&limit=100&offset=0&user_group_id=${userGroup}&category_id=1&ogrns[]=${payload}`,
                headers: { Authorization: `Bearer ${apiSession}` }
            })

            if(removeCompanyResult?.data?.status?.code == 201) {
                await dispatch('getAllCompanies')
            }

            return removeCompanyResult
        },
        getCompanyChanges({ rootState, dispatch }, { company_id, offset, limit }) {
            const apiSession = rootState.user.apiSession

            return axios({
                method: 'get',
                url: `${process.env.API_PRO}/monitor/company/${company_id}?demo=true&limit=${limit}&offset=${offset}`,
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e && e.data && e.data.data) {
                    dispatch('getCompanies')
                    dispatch('getAllCompanies')
                    return e.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        removeCompanies({ rootState, dispatch }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            return axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/delete?demo=true&limit=100&offset=0&user_group_id=${userGroup}`,
                data: {
                    ogrns: payload
                },
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e.data.status && e.data.status.code == 201) {
                    dispatch('getCompanies')
                    dispatch('getAllCompanies')
                    dispatch('showErrorPopUp', 'Компании удалены',  { root: true })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        addCompanies({ rootState, dispatch }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            return axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/insert?demo=true&limit=100&offset=0&user_group_id=${userGroup}&category_id=1`,
                data: { ogrns: payload },
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e.data.status && e.data.status.code == 201) {
                    dispatch('getCompanies')
                    dispatch('getAllCompanies')
                    dispatch('showErrorPopUp', 'Компании добавлены',  { root: true })
                    return e
                } else if(e.data.error) {
                    dispatch('showErrorPopUp', 'Ошибка, компании не добавлены',  { root: true })
                } else if(e.data.status && e.data.status.message == 'Возможно, закончился лимит по подписке') {
                    dispatch('showErrorPopUp', 'Закончился лимит по подписке',  { root: true })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        downloadXLSX({ rootState }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''
            const ogrn = payload ? `/${payload}` : ''

            return axios({
                method: 'get',
                url: `${process.env.API_PRO}/monitor/company${ogrn}?demo=true&user_group_id=${userGroup}&result_type=xlsx`,
                headers: { 'Authorization': `Bearer ${apiSession}` }
            }).then(res => {
                if(res && res.data && res.data.data && res.data.data.file && res.data.data.file.link && res.data.data.file.link.url) {
                    window.location.replace(res.data.data.file.link.url)
                }
            })
        },
        setCategory({ rootState, dispatch }, { companies, category_id }) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            return axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/set_category?demo=true&limit=100&offset=0&user_group_id=${userGroup}&category_id=${category_id}`,
                data: { ogrns: companies },
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e.data.status && e.data.status.code == 201) {
                    dispatch('getCompanies')
                    dispatch('getAllCompanies')
                    dispatch('showErrorPopUp', 'Категория присвоена',  { root: true })
                    return e
                } else if(e.data.error) {
                    dispatch('showErrorPopUp', 'Ошибка, категория не присвоена',  { root: true })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        watchCompanies({ rootState, dispatch }, payload) {
            const apiSession = rootState.user.apiSession
            const userGroup = rootState.user.groups && rootState.user.groups.length && rootState.user.groups[0].id ? rootState.user.groups[0].id : ''

            return axios({
                method: 'post',
                url: `${process.env.API_PRO}/monitor/company/watch?demo=true&limit=100&offset=0&user_group_id=${userGroup}&category_id=1`,
                data: { ogrns: payload },
                headers: {
                    Authorization: `Bearer ${apiSession}`
                }
            }).then(e => {
                if(e.data.status && e.data.status.code == 201) {
                    dispatch('getCompanies')
                    dispatch('getAllCompanies')
                    dispatch('showErrorPopUp', 'Компании отмечены как прочитанные',  { root: true })
                    return e
                } else if(e.data.error) {
                    dispatch('showErrorPopUp', 'Ошибка, компании не добавлены',  { root: true })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getGroups() {}
    },
    getters: {
        countUnseen: ({ company }) => {
            if(company.length) {
                return company.map(e => parseInt(e.count_unseen)).reduce((a, b) => a + b, 0)
            }
        },
        countTotal: ({ company }) => {
            if(company.length) {
                return company.map(e => parseInt(e.count_total)).reduce((a, b) => a + b, 0)
            }
        },
        allCountUnseen: ({ allCompanies }) => {
            if(allCompanies.length) {
                return allCompanies.map(e => parseInt(e.count_unseen)).reduce((a, b) => a + b, 0)
            }
        },
        allCountTotal: ({ allCompanies }) => {
            if(allCompanies.length) {
                return allCompanies.map(e => parseInt(e.count_total)).reduce((a, b) => a + b, 0)
            }
        },
        allCompaniesOgrns: ({ allCompanies }) => {
            if(allCompanies.length) {
                return allCompanies.map(e => e.company_id)
            }
        },
        getCategoryNameById: () => ({
            // "1": 'Без категории',
            '2': 'Поставщик',
            '7': 'Недобросовестный поставщик',
            '4': 'Клиент',
            '3': 'Конкурент',
            '5': 'Должник',
            '8': 'Аффилированная структура',
            '6': 'VIP'
        })
    }
}

export default monitoringModule