<template lang="pug">
    a(v-if="ogrn" :href="`/company/${ogrn}`" target="_blank")
        slot
    span(v-else)
        slot
</template>

<script>
export default {
    props: ['ogrn']
}
</script>

<style lang="sass" scoped>
@import 'styles/global-variables.sass'

a
    @extend .blue-red-hover-text
</style>