<template>
    <div id="modal-signin" class="uk-modal uk-open">
        <div class="uk-modal-dialog">
            <span class="fas fa-times" @click="close"></span>
            <div class="uk-modal-header"><h3>Вход</h3></div>
            <form autocomplete="on">
                <div class="uk-overflow-container">
                    <div class="uk-form">
                        <div class="uk-form-row">
                            <input v-model="user_name" type="text" placeholder="Логин" id="modal-signin-login" class="uk-width-1-1 uk-form-large" />
                        </div>
                        <div class="uk-form-row">
                            <input v-model="user_pass" @keydown.13="login" type="password" placeholder="Пароль" id="modal-signin-password" class="uk-width-1-1 uk-form-large" />
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer" style="text-align: right;">
                    <!--div class="uk-button">Напомнить пароль</div-->
                    <button @click="login" class="uk-button uk-button-primary uk-button-large">Войти</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['close'],
    data() {
        return {
            user_name: '',
            user_pass: ''
        }
    },
    methods: {
        login(e) {
            e.preventDefault()
            e.stopPropagation()
            this.$store.dispatch('user/login', {
                user_name: this.user_name,
                user_pass: this.user_pass,
                product_code: process.env.PRODUCT_CODE
            })
        }

    },
    mounted() {
        $('#modal-signin-login').val('').removeClass('uk-form-danger')
        $('#modal-signin-password').val('').removeClass('uk-form-danger')
    }
}
</script>

<style lang="sass" scoped>
@import 'styles/global-variables.sass'

#modal-signin
    display: block

input#modal-signin-password
    margin-bottom: 15px

.fa-times
    float: right
    font-size: 20px
    line-height: 30px
    color: #333
    cursor: pointer
</style>