import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        loading: false,
        rotating: false,
        pledge_list: {},
        pagination: {},
        status: 0
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setRotating(state, rotating) {
            state.rotating = rotating
        }
    },
    actions: {
        getData({ commit, dispatch, rootGetters }, { offset = 0, limit = 10 }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'pledge',
                data: { 
                    action: 'list_pledge',
                    ogrn: rootGetters['card/ogrn'],
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'status', value: e?.data?.data?.status })
                commit('setStateObject', { name: 'pledge_list', value: e?.data?.data?.list_pledges })
                commit('setStateObject', { name: 'pagination', value: e?.data?.data?.pagination })
                commit('setStateObject', { name: 'actualDate', value: e?.data?.data?.actual_date })
                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        update({ commit, dispatch, rootGetters }) {
            commit('setRotating', true)

            return dispatch('v3/API', {
                method: 'pledge',
                data: { 
                    action: 'update_pledge',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(() => {
                dispatch('getData', { offset: 0, limit: 10 })
            }).finally(() => {
                commit('setRotating', false)
            })
        }
    }
}