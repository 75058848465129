<template lang="pug">
    span(
        :style="`cursor: help; color: ${colors[status]};`"
        :class="`status-${classes[status]}`"
        :title="titles[status]")
        i(:class="`uk-icon-${icons[status]}`")
</template>

<script>
export default {
    props: {
        status: {
            type: Number,
            default: 4,
            required: true
        },
        titles: {
            type: Array,
            default: () => [
                'Ликвидированное',
                'Действующее',
                'В стадии ликвидации',
                'Действующее',
                'Статус не определен'
            ],
            required: false
        },
        colors: {
            type: Array,
            default: () => [
                '#e74c3c',
                '#2ecc71',
                '#e7843c',
                '#2ecc71',
                '#ddd'
            ],
            required: false
        },
        icons: {
            type: Array,
            default: () => [
                'times-circle',
                'check-circle',
                'minus-circle',
                'check-circle',
                'circle-o'
            ],
            required: false
        },
        classes: {
            type: Array,
            default: () => [
                'inactive',
                'active',
                'liqidating',
                'active',
                'unknown'
            ],
            required: false
        }
    }
}
</script>
