<template>
    <modal
        :printButton="false"
        :downloadButton="false"
        :close="onClose"
    >
        <h3 slot="modalHeader">Файлы cookie</h3>
        <p slot="modalBody">
            Продолжая использовать сайт, Вы даете ЗАО «ЛИК» (ИНН 7719841799) согласие на обработку файлов cookies в т.ч. данных, собираемых посредством агрегаторов статистики посетителей веб-сайтов в целях ведения статистики посещений сайта. 
            Если Вы не хотите, чтобы Ваши вышеперечисленные данные обрабатывались, просим отключить обработку файлов cookies и сбор пользовательских данных в настройках Вашего браузера или покинуть сайт.
        </p>
    </modal>
</template>

<script>
import modal from '@/components/ui/modal/modal.vue'

export default {
    components: { modal },
    methods: {
        onClose() { this.$emit('close') }
    }
}
</script>

<style lang="sass" scoped>
p
    padding: 25px 15px
    color: #2a2a2b
</style>