<template>
    <table>
        <thead>
            <tr>
                <th>ИНН</th>
                <th>Наименование</th>
                <th>Период</th>
                <th>Адрес</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(e,i) in afilArray" :key="i">
                <td>{{e.inn ? e.inn : "—"}}</td>
                <td>
                    <template v-if="e.status_type">
                        <status-icon :status="e.status_type[0]" />
                    </template>
                    <template v-else-if="typeof e.status == 'number'">
                        <status-icon :status="e.status" />
                    </template>
                    <template v-if="e.ogrn">
                        <card-link :ogrn="e.ogrn">{{e.name_short ? e.name_short : e.name_full}}</card-link>
                        <tooltip :ogrn="e.ogrn"/>
                    </template>
                    <span v-else>{{e.name_short ? e.name_short : e.name_full}}</span>
                </td>
                <td>
                    <span v-if="e.stakeholder_type == 'CURRENT' && !checkInt(e.date_stop)">по н.в.</span>
                    <span class="per_nowrap" v-else-if="e.stakeholder_date_end">по {{date_format(e.stakeholder_date_end)}} г.</span>
                    <span class="per_nowrap" v-else-if="checkInt(e.date_stop)">
                        по {{date_format(new Date(e.date_stop.toString().slice(0, 4), e.date_stop.toString().slice(4, 6) - 1, e.date_stop.toString().slice(6)))}} г.
                    </span>
                    <span class="per_nowrap" v-else-if="e.year">{{ e.year }}</span>
                    <span class="per_nowrap" v-else>прекращено</span>
                </td>
                <td>
                    <span v-if="e.addr_full">{{e.addr_full}}</span>
                    <span v-else-if="e.address">{{e.address}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import statusIcon from '@/components/ui/statusIcon.vue'
import cardLink from '@/components/ui/cardLink.vue'

export default {
    props: {
        afilArray: Array
    },
    components: {
        'status-icon': statusIcon,
        'card-link': cardLink
    },
    methods: { 
        checkInt(value) { return Number.isInteger(value) } 
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'
@use 'styles/global-variables.sass'

table
    @include global-variables.simple-table($fz: 16px)
    font-family: "Montserrat", sans-serif
    +mobile.mobile
        thead
            display: none
        tbody
            tr
                display: block
                border-bottom: 1px solid #aaa
                td
                    padding: 2px 0
                    display: block
                    width: 100%
                    border-bottom: 0
                    span
                        font-family: 'Montserrat', sans-serif

                    &:nth-child(1)
                        &::before
                            content: "ИНН: "
                    &:nth-child(4)
                        &::before
                            content: "Адрес: "
.per_nowrap
    white-space: nowrap                            
</style>