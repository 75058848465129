var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vheader-menu" }, [
    _c(
      "div",
      {
        staticClass: "vheader-menu-item hide-on-tablet",
        attrs: { id: "old-interface-link" },
      },
      [
        _c(
          "a",
          {
            staticClass: "licexpert__text",
            attrs: { href: _vm.oldInterfaceLink, target: "_blank" },
          },
          [
            _vm._v("\n            Перейти на\n            "),
            _c("br"),
            _vm._v("\n            новый интерфейс\n        "),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "vheader-menu-item vheader-menu-item-profile",
        on: {
          mouseover: function ($event) {
            _vm.showProfileMenu = true
          },
          mouseleave: function ($event) {
            _vm.showProfileMenu = false
          },
        },
      },
      [
        _vm.userName ? _c("span", { staticClass: "lnr lnr-user" }) : _vm._e(),
        _vm._v(" "),
        _vm.userName
          ? _c(
              "span",
              { staticClass: "vheader-menu-item-profile__user-name" },
              [_vm._v("\n            " + _vm._s(_vm.userName) + "\n        ")]
            )
          : _c("div", { on: { click: _vm.showSignin } }, [_vm._v("Войти")]),
        _vm._v(" "),
        _vm.showProfileMenu && _vm.userName
          ? _c("div", { staticClass: "vheader-menu-item-submenu" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "router-link-fallback",
                      rawName: "v-router-link-fallback",
                    },
                  ],
                  attrs: { href: "/profile" },
                },
                [_vm._v("Профиль")]
              ),
              _vm._v(" "),
              _c("a", { attrs: { href: "#" }, on: { click: _vm.signout } }, [
                _vm._v("Выход"),
              ]),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "vheader-menu-item vheader-menu-item-burger",
        on: {
          mouseover: function ($event) {
            _vm.showBurgerMenu = true
          },
          mouseleave: function ($event) {
            _vm.showBurgerMenu = false
          },
        },
      },
      [
        _c("i", { staticClass: "uk-icon-th uk-icon-small" }),
        _vm._v(" "),
        _vm.showBurgerMenu
          ? _c(
              "div",
              {
                staticClass:
                  "vheader-menu-item-submenu vheader-menu-item-burger-submenu",
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "router-link-fallback",
                        rawName: "v-router-link-fallback",
                      },
                    ],
                    attrs: { href: "/search" },
                  },
                  [_vm._v("Подбор клиентов")]
                ),
                _vm._v(" "),
                _vm.userName
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        attrs: { href: "/monitoring" },
                      },
                      [
                        _vm._v("\n                Мониторинг"),
                        _vm.allCountUnseen
                          ? _c(
                              "i",
                              { staticClass: "monitoring-notification" },
                              [_vm._v(_vm._s(_vm.allCountUnseen))]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userName && _vm.hasAccessToRelationsSearch
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        staticClass: "beta",
                        attrs: { href: "/relations-search" },
                      },
                      [_vm._v("Поиск связей")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userName
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        attrs: { href: "/passport-check" },
                      },
                      [_vm._v("Проверка паспорта")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userName && _vm.checkFlAccess
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        staticClass: "new",
                        attrs: { href: "/fl-check" },
                      },
                      [_vm._v("Проверка ФЛ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userName
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        attrs: { href: "/flat-check" },
                      },
                      [_vm._v("Проверка квартиры")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userName
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "router-link-fallback",
                            rawName: "v-router-link-fallback",
                          },
                        ],
                        attrs: { href: "/egrn" },
                      },
                      [_vm._v("Выписка ЕГРН")]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }