import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        list: [],
        nothing: false,
        loading: false,
        modalLoading: false,
        executory: {},
        years: [],
        totalOpened: 0,
        totalClosed: 0,
        totalSum: 0
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setModalLoading(state, loading) {
            state.modalLoading = loading
        },
        setNothingToShow(state, nothing) {
            state.nothing = nothing
        },
        addArray(state, array) {
            state.list = [...state.list, ...array]
        }
    },
    actions: {
        getData({ dispatch, commit, rootGetters }) {
            commit('setLoading', true)

            return dispatch('v3/API', {
                method: 'fssp',
                data: {
                    action: 'stat',
                    ogrn: Number([rootGetters['card/ogrn']])
                }
            }, { root: true }).then(e => {
                if(e.status === 200 && e.data?.code === 0 && Object.keys(e.data?.data ?? {}).length) {
                    commit('setStateObject', { name: 'executory', value: e?.data?.data })
                    commit('setStateObject', { name: 'years', value: Object.keys(e?.data?.data).filter(y => !isNaN(Number(y))).reverse() })
                    commit('setStateObject', { name: 'totalOpened', value: e.data.data.total.opened.count_total })
                    commit('setStateObject', { name: 'totalClosed', value: e.data.data.total.closed.count_total })
                    commit('setStateObject', { name: 'totalSum', value: e.data.data.total.opened.sum_total + e.data.data.total.closed.sum_total })
                }
            }).catch(err => {
                console.error(err.message)
                commit('setLoading', false)
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getModal({ dispatch, commit, rootGetters, state }, { offset = 0, limit = 25, year, status }) {
            commit('setModalLoading', true)

            return dispatch('v3/API', {
                method: 'fssp',
                data: {
                    action: 'info',
                    ogrn: Number(rootGetters['card/ogrn']),
                    year,
                    status,
                    offset,
                    limit
                }
            }, { root: true }).then(e => {
                if(e.status === 200 && e.data?.code === 0 && e.data?.data?.list?.length) {
                    if(state.list.length) {
                        commit('addArray', e.data.data.list)
                    } else if(!state.list.length) {
                        commit('setStateObject', { name: 'list', value: e.data.data.list })
                    } else {
                        commit('setNothingToShow', true)
                    }
                } else {
                    commit('setNothingToShow', true)
                }
            }).catch(err => {
                console.error(err.message)
                commit('setNothingToShow', true)
            }).finally(() => {
                commit('setModalLoading', false)
            })
        },
        clearList({ commit }) {
            commit('setStateObject', { name: 'list', value: [] })
        }
    },
    getters: {
        sum: state => {
            if(state.years.length) {
                return state.years.map(y => state.executory[y].opened.sum_total + state.executory[y].closed.sum_total)
            } else {
                return []
            }
        }
    }
}
