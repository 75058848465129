import axios from 'axios'
import { set } from 'lodash'

export default {
    namespaced: true,
    state: {
        partyList: null,
        addressList: null,
        cardsList: null,
        isDemoModalOpen: false,
        demoType: '',
        recipients: 'zaitsev@isoa.ru, rsb@1clicom.ru, zea@1clicom.ru, partner@1clicom.ru',
        isLoading: false,
        isFastSearchOpen: false,
        news: {
            newsList: [],
            newsTotal: null,
            newsStatus: false
        },
        newsModal: null
    },
    mutations: { 
        clearList(state, type) {
            if(type === 'party') { state.partyList = null }
            if(type === 'address') { state.addressList = null }
            if(type === 'cards') { state.cardsList = null }
        },
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        demoTypeChange(state, value) {
            state.demoType = value
        },
        demoModalChangeState(state, value) {
            state.isDemoModalOpen = value            
        },
        changeLoadingStatus(state, value) {
            state.isLoading = value
        },
        changeFastSearchState(state, value) {
            state.isFastSearchOpen = value
        },
        setNewsList(state, array) {
            state.news.newsList = [...state.news.newsList, ...array]
        },
        setNewsModalData(state, data) {
            state.newsModal = data
        }
    },
    actions: {
        searchRequest({ commit }, { token, type, data }) {
            return axios({
                method: 'POST',
                url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${type}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ token }`
                },
                data: {
                    query: data
                }
            }).then(result => {
                if(result.status === 200 && result.data?.suggestions.length > 0) {
                    commit('setStateObject', { name: type + 'List', value: result?.data?.suggestions })
                }
            }).catch((e) => {
                console.error(e.message)
            })  
        },
        sendDemoOrder({ commit, dispatch }, data) {
            const successNotify =  'Ваша заявка принята. В ближайшее время с Вами свяжется наш специалист.'
            const dangerNotify = 'Не удалось отправить заявку. Попробуйте повторить позднее или свяжитесь с нашими менеджерами по телефону: 8(800)500-40-18'
            const mailTheme = `Заявка с сайта ${window.location.hostname} на демо доступ`
            console.log(this)
            const text = [
                `${mailTheme} <br />`,
                `Компания ${data.companyName} <br />`,
                `ФИО: ${data.name} <br />`,
                `Телефон: ${data.phoneNumber} <br />`,
                `E-mail: ${data.email}<br>`,
                `Промокод: ${data.promo} <br />`,
                `Откуда: ${window.location.hostname} <br />`,
                `Продукт: ${this.state.main.demoType}`
            ].join('')

            const formData = new FormData()
            formData.append('mails', 'zaitsev@isoa.ru, rsb@1clicom.ru, zea@1clicom.ru, partner@1clicom.ru, kaa@1clicom.ru')
            formData.append('theme', `Заявка с сайта ${window.location.hostname} на демо доступ`)
            formData.append('text', text)
            axios({
                method: 'post',
                url: process.env.EMAIL_SENDER,
                data: formData
            }).then(e => {
                commit('demoModalChangeState', false)
                
                if(e.data.request.result) {
                    dispatch('showErrorPopUp', successNotify, { root: true })
                } else {
                    dispatch('showErrorPopUp', dangerNotify, { root: true })
                }
            }).catch(() => {
                dispatch('showErrorPopUp', dangerNotify, { root: true })
            })
        },
        cardsSearch({ commit, dispatch }, { apiSession, fias, company, address }) {
            const data = {
                token: apiSession ? apiSession : null,
                fias: fias ? fias : [],
                company: company ? company : '',
                address: address ? address : ''
            }
            return axios({
                method: 'POST',
                url: `${process.env.API_PRO}/search/company?limit=100&offset=0&demo=1`,
                headers: {
                    authorization: `Bearer ${data.token}`
                },
                data: {
                    request: data.company,
                    search_types: { row: false },
                    search_fields: { address: data.address },
                    classificator: { fias: [...data.fias] },
                    uchr: 'no'
                }
            }).then(result => {
                if(result.data?.data?.length > 1) {
                    commit('changeLoadingStatus', false)
                    commit('setStateObject', { name: 'cardsList', value: result.data.data })
                    commit('changeFastSearchState', true)
                } else if(result.data?.data?.length === 1) {
                    window.open(`${window.location.origin}/company/${result.data.data[0].ogrn}`)
                    commit('changeLoadingStatus', false)
                } else {
                    dispatch('showErrorPopUp', 'По вашему запросу ничего не найдено', { root: true })
                    commit('changeLoadingStatus', false)
                }
            }).catch(e => {
                console.error(e)
                commit('changeLoadingStatus', false)
            })
    
        },
        getNews({ commit, rootState }, data) {
            const requestSettings = {
                newsLimit: data.newsLimit ? data.newsLimit : 4,
                page: data.page ? data.page : 1
            }

            axios({
                method: 'GET',
                url: `https://licweb.ru/le_news/getNews.php?limit=${requestSettings.newsLimit}&offset=${(requestSettings.page - 1) * requestSettings.newsLimit}`,
                headers: rootState.axios.headers
            }).then(result => {
                if(result.data.total > 0) {
                    commit('setStateObject', { name: 'news.newsStatus', value: true })
                    commit('setStateObject', { name: 'news.newsTotal', value: result.data.total })
                    commit('setNewsList', result.data.news)
                    window.dispatchEvent(new Event('resize'))
                }
            }).catch(e => {
                console.error(e)
            })
        }
    }
}