import axios from 'axios'
import { uniqBy, get } from 'lodash'

const pagination = () => ({
    page: 1,
    current_page: 1,
    offset: 0,
    limit: 100,
    rows_per_page: 100,
    rows_count: 0
})

const filterData = () => ({
    address: '',
    raw: true,
    request: '',
    searchtype: 'tsv_all',
    rectype: 0,
    status: 'both',
    phone: false,
    site: false,
    email: false,
    bankrupts: 0,
    msp: 0,
    boo_year: '',
    kad: { type: 'noselect', year: '' },
    ved: { type: '', year: '' },
    registration_adress_contact: false, // связь по бух адресу (flags[4])
    nalog_otchet: false, // налог отчетность (flags[5])
    nalog_zadolj: false, // налог задолженность (flags[6])
    license_alco: false, // Росалкогольрегулирование 
    license_kom: false, // Роскомнадзор 
    license_potreb: false, // Роспотребнадзор 
    license_prirod: false, // Росприроднадзор 
    license_teh: false, // Ростехнадзор 
    license_obr: false, // Рособрнадзор 
    license_zdrav: false, // Росздравнадзор 
    license_min_cult: false, // МинКультуры 
    license_min_promtorg: false, // МинПромторг 
    license_roskomnadzor: false, 
    license_rosselhoznadzor: false,
    license_rosreestr: false,
    dateStart: { from: '', to: '' },
    employeeCount: { from: '', to: '' },
    dateStop: { from: '', to: '' },
    auth_capital: { from: '', to: '', multiplier: '1000' }, // уставный капитал
    p21103: { from: '', to: '', multiplier: '1000' }, // выручка
    p11503: { from: '', to: '', multiplier: '1000' }, // основные средства
    p12103: { from: '', to: '', multiplier: '1000' }, // запасы
    p12303: { from: '', to: '', multiplier: '1000' }, // дебиторская задолженность
    p15203: { from: '', to: '', multiplier: '1000' }, // кредиторская задолженность
    p24003: { from: '', to: '', multiplier: '1000' }, // чистая прибыль
    p22003: { from: '', to: '', multiplier: '1000' }, // прибыль от продаж
    p23003: { from: '', to: '', multiplier: '1000' }, // прибыль до налога
    checkedClassificator: {
        fias: [],
        okfs: [],
        okopf: [],
        okved: []
    }
    // license: [],
})
const csvExportParams = value => (
    {
        ogrn: value,
        inn: value,
        name_short: value,
        name_full: value,
        okpo: value,
        okato: value,
        okfs: value,
        okopf: value,
        kpp: value,
        date_start: value,
        date_stop: value,
        okved: value,
        rukovod: value,
        address: value,
        phone: value,
        site: value,
        email: value,
        auth_capital: value,
        viruch: value,
        osn_sreds: value,
        zapas: value,
        deb_zadolj: value,
        kred_zadolj: value,
        chist_pribil: value,
        pribil: value,
        pribil_do_nalog: value,
        employee_counts: value,
        msp: value
    }
)

const getSimpleCsvExportParams = () => {
    return {
        ogrn: true,
        inn: true,
        name_short: true,
        name_full: true,
        okpo: false,
        okato: false,
        okfs: false,
        okopf: false,
        kpp: false,
        date_start: false,
        date_stop: false,
        okved: false,
        rukovod: true,
        address: true,
        phone: true,
        site: true,
        email: true,
        auth_capital: false,
        viruch: false,
        osn_sreds: false,
        zapas: false,
        deb_zadolj: false,
        kred_zadolj: false,
        chist_pribil: false,
        pribil: false,
        pribil_do_nalog: false,
        employee_counts: false,
        msp: false
    }
}

const searchStateModule = {
    namespaced: true,
    state: {
        previousSearchArray: [],
        classificator: {
            fias: [],
            okfs: [],
            okopf: [],
            okved: []
        },
        classificatorRequest: {
            fias: '',
            okfs: '',
            okopf: '',
            okved: ''
        },
        counter: 1,
        idlist: '',
        showResultsButton: true,
        resultView: 'list',
        loading: true,
        showExportCsv: false,
        rows: [],
        searchMore: false,
        pagination: pagination(),
        filterData: filterData(),
        shownFilter: {
            fias: false,
            okved: false,
            okfs: false,
            okopf: false,
            all: false
        },
        csvExportParams: csvExportParams(true)
    },
    mutations: {
        resetPage(state) { state.pagination.page = 1 },
        setClassificator(state, { registry, array }) {
            state.classificator[registry] = array
        },
        addToClassificator(state, { rows, type }) {
            state.classificator[type] = uniqBy([...state.classificator[type], ...rows], 'code')
        },
        updateClassificator(state, { rows, type }) {
            state.classificator[type] = rows
        },
        setValueInsideFilterDataObject(state, { value, type, object_key }) {
            state.filterData[type][object_key] = value
        },
        setFilterData(state, obj) {
            state.filterData[obj.type] = obj.value
        },
        setFullFilterData(state, obj) {
            Object.assign(state.filterData, { ...filterData(), ...obj })
        },
        changeRows(state, newRows) {
            state.rows = newRows
        },
        searchLoading(state, loading) {
            state.loading = loading
        },
        showExportCsv(state, show) {
            state.showExportCsv = show
        },
        changeResultListView(state, view) {
            state.resultView = view
        },
        changeLimit(state, limit) {
            state.pagination.limit = limit
        },
        resetPagination(state) {
            Object.assign(state.pagination, pagination())
        },
        changePagination(state, pagination) {
            state.pagination = { ...state.pagination, ...pagination }
        },
        updatePage(state, page) {
            state.pagination.page = page
        },
        updateSearchObject(state, { obj, value }) {
            state[obj] = value
        },
        setStateObject(state, { obj, value }) {
            state[obj] = value
        },
        resetFilterData(state) {
            Object.assign(state.filterData, filterData())
        },
        counterRand(state) {
            state.counter = Math.random() >= 0.5 ? state.counter + 1 : state.counter - 1
        },
        populateClassificator(state, { registry, children, idOfNode }) {
            const find = (classificator, id, children) => {
                if(classificator) {
                    classificator.forEach(e => {
                        if(e.id == id) {
                            e['children'] = children
                        } else {
                            find(e.children, id, children)
                        }
                    })
                }
            }
            find(state.classificator[registry], idOfNode, children)
        },
        classificatorNodeLoading(state, { registry, node, loading }) {
            const find = (classificator, id) => {
                if(classificator) {
                    classificator.forEach(e => {
                        if(e.id == id) {
                            e['loading'] = loading
                        } else {
                            find(e.children, id)
                        }
                    })
                }
            }
            find(state.classificator[registry], node.id)
        },
        classificatorNodeExpand(state, { registry, node, expand }) {
            const find = (classificator, id) => {
                if(classificator) {
                    classificator.forEach(e => {
                        if(e.id == id) {
                            e.expand = expand
                        } else {
                            find(e.children, id)
                        }
                    })
                }
            }
            find(state.classificator[registry], node.id)
        },
        uncheckAllClassificators(state) {
            const find = (arr) => {
                if(arr) {
                    arr.forEach(e => {
                        e.checked = false
                        find(e.children)
                    })
                }
            }
            find(state.classificator.fias)
            find(state.classificator.okved)
            find(state.classificator.okopf)
            find(state.classificator.okfs)
        },
        checkboxClassificatorButWithoutChildren(state, { registry, node, checked }) {
            const find = (arr, id) => {
                if(arr) {
                    arr.forEach(e => {
                        if(e.id == id) {
                            e.checked = checked
                        } else {
                            find(e.children, id)
                        }
                    })
                }
            }
            find(state.classificator[registry], node.id)
        },
        checkboxClassificator(state, { registry, node, checked }) {
            const find = (arr, id) => {
                if(arr) {
                    arr.forEach(e => {
                        if(e.id == id) {
                            e.checked = checked
                            findWithoutId(e.children)
                        } else {
                            find(e.children, id)
                        }
                    })
                }
            }
            const findWithoutId = (arr) => {
                if(arr) {
                    arr.forEach(e => {
                        e.checked = checked
                        findWithoutId(e.children)
                    })
                }
            }
            find(state.classificator[registry], node.id)
        },
        updateCheckedClassificator(state, registry) {
            const checkedClassificatorArray = []
            // const checkedClassificatorArray = state.filterData.checkedClassificator[registry]
            const find = (arr) => {
                if(arr) {
                    arr.forEach(e => {
                        if(e.checked) {
                            checkedClassificatorArray.push(e)
                        }
                        find(e.children)
                    })
                }
            }
            find(state.classificator[registry])
            state.filterData.checkedClassificator[registry] = checkedClassificatorArray
            // state.filterData.checkedClassificator[registry] = Array.from(new Set([...checkedClassificatorArray, ...state.filterData.checkedClassificator[registry]]))
            
        },
        deleteItemFromChecked(state, { registry, id }) {
            // удаление node из чекнутого массива
            state.filterData.checkedClassificator[registry] = state.filterData.checkedClassificator[registry].filter(e => e.id != id)
        },
        setCsvExportParam(state, { key, value }) {
            state.csvExportParams[key] = value
        },
        resetCsvExportParams(state) { state.csvExportParams = csvExportParams(true) },
        unsetCsvExportParams(state) { state.csvExportParams = csvExportParams(false) },
        simpleCsvExport(state) { state.csvExportParams = getSimpleCsvExportParams() }
    },
    actions: {
        resetFilterData({ commit }) {
            commit('resetFilterData')
            commit('uncheckAllClassificators')
            commit('counterRand')
        },
        changePagination(context, payload) {
            const { commit, dispatch } = context
            const { type, data } = payload

            switch(type) {
                case 'limit':
                    commit('changeLimit', data)
                    break
            }
            dispatch('getCards', { dont_save: true })
        },
        exportCsvXls(context, payload) {
            const { rootState, dispatch, getters } = context
            const { searchUrl, searchData } = getters
            const {
                result_type,
                list_fields
            } = payload
            const result_type_url = searchUrl + `&result_type=${result_type}`
            const data = {
                list_fields,
                ...searchData
            }
            
            axios({
                method: 'POST',
                url: result_type_url,
                headers: rootState.axios.headers,
                data
            }).then(e => {
                const url = e.data.data.file.link.url
                if(url) {
                    dispatch('downloadFile', { url }, { root: true })
                }
            }).catch(err => {
                console.log(err)
                dispatch('showErrorPopUp', 'Не удалось скачать выгрузку', { root: true })
            })
        },
        getCards(context, payload) {
            const { commit, rootState, getters, dispatch } = context
            const { searchUrl, searchData } = getters
            const dont_save = get(payload, 'dont_save') || false
            console.log('dont_save', dont_save)

            commit('searchLoading', true)

            axios({
                method: 'POST',
                url: searchUrl,
                headers: rootState.axios.headers,
                data: searchData
            }).then(e => {
                commit('searchLoading', false)
                if(e.data.data.length) {
                    commit('changeRows', e.data.data)
                    commit('changePagination', e.data.pagination)
                } else {
                    commit('changeRows', e.data.data)
                    dispatch('showErrorPopUp', 'По заданным условиям поиска данных не найдено', { root: true })
                }
            }).catch(err => {
                console.log(err)
                commit('searchLoading', false)
                dispatch('showErrorPopUp', 'Ошибка API', { root: true })
            })
        },
        checkboxClassificator({ commit }, { registry, node }) {
            if(!node.checked) {
                commit('checkboxClassificator', { registry, node, checked: true })
            } else {
                commit('checkboxClassificator', { registry, node, checked: false })
            }
            commit('updateCheckedClassificator', registry)
            commit('counterRand')
        },
        deleteItemFromChecked({ commit }, { registry, id }) {
            commit('checkboxClassificatorButWithoutChildren', { registry, node: { id }, checked: false })
            commit('deleteItemFromChecked', { registry, id })
            commit('counterRand')
        },
        // функия нажатия на стрелку в классификаторе
        expandClassificator({ commit, state }, { registry, node }) {
            if(node.loading) { return }
            if(node.children && node.children.length) {
                commit('classificatorNodeExpand', { registry, node, expand: !node.expand })
                commit('counterRand')
            } else {
                commit('classificatorNodeLoading', { registry, node, loading: true })
                commit('counterRand')
                axios({
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('apiSession')
                    },
                    url: `${process.env.API_PRO}/registry/${registry}?${node.id ? `parent_id=${node.id}` : ''}&demo=1`
                }).then(e => {
                    commit('populateClassificator', {
                        registry,
                        idOfNode: node.id,
                        children: e.data.data.map(e => ({ ...e, checked: node.checked || state.filterData.checkedClassificator[registry].map(e => e.id).includes(e.id) }))
                    })
                    commit('classificatorNodeLoading', { registry, node, loading: false })
                    commit('classificatorNodeExpand', { registry, node, expand: true })
                    commit('updateCheckedClassificator', registry)
                    commit('counterRand')
                })
            }
            if(node.expanded) { /* node.expanded = false */ }
            // если у node есть дети, то просто открываем, если закрыто (expanded = true) и закрываем если закрыто
            // если у node детей нету и положительный children_count, то...
            // node.loading = true
        },
        getClassificator({ commit, state }, { registry, parent_id, request }) {

            let CancelToken = undefined
            
            if(CancelToken) {
                CancelToken.cancel('canceling')
            }

            CancelToken = axios.CancelToken.source()

            return new Promise((resolve, reject) => {
                axios({
                    cancelToken: CancelToken.token,
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('apiSession')
                    },
                    url: `${process.env.API_PRO}/registry/${registry}?${parent_id ? `parent_id=${parent_id}` : ''}${request ? `request=${request}` : ''}&demo=1`
                }).then(e => {
                    resolve(e)
                    commit('setClassificator', {
                        registry,
                        array: e.data.data.map(e => ({
                            ...e,
                            expand: false,
                            children: [],
                            count_children: request ? 0 : e.count_children,
                            title: request && e.full_title ? e.full_title : e.title,
                            checked: state.filterData.checkedClassificator[registry].length ? state.filterData.checkedClassificator[registry].map(elem => elem.id).includes(e.id) : false
                        }))
                    })
                }).catch(err => {
                    reject(err)
                    console.log(err)
                })
            })
        },
        getAllClassificators({ dispatch }) {
            dispatch('getClassificator', { registry: 'okved' })
            dispatch('getClassificator', { registry: 'fias' })
            dispatch('getClassificator', { registry: 'okfs' })
            dispatch('getClassificator', { registry: 'okopf' })
        }
    },
    getters: {
        searchUrl: state => {
            return `${process.env.API_PRO}/search/company?demo=1&limit=${state.pagination.limit}${state.pagination.page > 1 ? `&offset=${state.pagination.limit * state.pagination.page - state.pagination.limit}` : ''}`
        },
        maxPages: state => {
            if(state.pagination.rows_count_is_accurate) {
                return state.pagination.pages_count
            } else {
                return 1000
            }
        },
        searchData: ({ filterData, idlist }) => {
            const {
                boo_year,
                bankrupts,
                msp,
                request,
                rectype,
                raw,
                status,
                phone,
                site,
                email,
                registration_adress_contact,
                nalog_otchet,
                nalog_zadolj,
                license_alco,
                license_kom,
                license_potreb,
                license_prirod,
                license_teh,
                license_obr,
                auth_capital,
                p21103,
                p11503,
                p12103,
                p12303,
                p15203,
                p24003,
                p22003,
                p23003,
                searchtype,
                license_zdrav,
                license_min_cult,
                license_min_promtorg,
                license_roskomnadzor,
                license_rosselhoznadzor,
                license_rosreestr,
                address,
                employeeCount
            } = filterData

            const classificator = {
                fias: filterData.checkedClassificator.fias.map(e => e.code ? e.code : false),
                okfs: filterData.checkedClassificator.okfs.map(e => e.code ? e.code : false),
                okopf: (!rectype ? filterData.checkedClassificator.okopf.map(e => e.code ? e.code : false) : []),
                okved: filterData.checkedClassificator.okved.map(e => e.code ? e.code : false)
            }

            const flags = [
                phone ? 1 : false,
                site ? 2 : false,
                email ? 3 : false,
                registration_adress_contact ? 4 : false,
                nalog_otchet ? 5 : false,
                nalog_zadolj ? 6 : false,
                msp ? msp: false
            ].filter(Boolean)

            const license = [
                license_alco ? 1 : false,
                license_zdrav ? 2 : false,
                license_kom ? 3 : false,
                license_potreb ? 4 : false,
                license_prirod ? 5 : false,
                license_teh ? 6 : false,
                license_obr ? 7 : false,
                license_min_cult ? 8 : false,
                license_min_promtorg ? 9 : false,
                license_roskomnadzor ? 10 : false,
                license_rosselhoznadzor ? 12: false,
                license_rosreestr ? 13: false
            ].filter(Boolean)

            const recType = () => {
                switch(rectype) {
                    case 0:
                        return 0
                    case 1:
                        return 'ul'
                    case 2:
                        return 'ip'
                }
            }

            const bankRupts = () => {
                switch(bankrupts) {
                    case 0:
                        return ''
                    case 1:
                        return 'yes'
                    case 2:
                        return 'no'
                }
            }

            const dateStop = () => {
                var from = filterData.dateStop.from.split('.')
                var to = filterData.dateStop.to.split('.')
                return {
                    from: from.length == 3 ? Number(from[2] + from[1] + from[0]) : '',
                    to: to.length == 3 ? Number(to[2] + to[1] + to[0]) : ''
                }
            }

            const dateStart = () => {
                var from = filterData.dateStart.from.split('.')
                var to = filterData.dateStart.to.split('.')
                return {
                    from: from.length == 3 ? Number(from[2] + from[1] + from[0]) : '',
                    to: to.length == 3 ? Number(to[2] + to[1] + to[0]) : ''
                }
            }

            const kad = () => {
                const type = filterData.kad.type == 'noselect' ? null : filterData.kad.type
                const year = filterData.kad.year
                if(year || type) {
                    return {
                        type,
                        year
                    }
                } else {
                    return null
                }
            }

            const ved = () => {
                const type = filterData.ved.type
                const year = filterData.ved.year
                if(year || type) {
                    return {
                        type,
                        year
                    }
                } else {
                    return null
                }
            }

            const employee_counts = employeeCount

            const search_fields = () => address ? ({ address }) : ({})

            return {
                request,
                employee_counts,
                search_fields: search_fields(),
                idlist: idlist.length ? idlist.split('\n') : [],
                bankrupts: bankRupts(),
                rectype: recType(),
                status,
                flags,
                classificator,
                license,
                dateStart: dateStart(),
                dateStop: dateStop(),
                auth_capital: { from: parseInt(auth_capital.from) * auth_capital.multiplier, to: parseInt(auth_capital.to) * auth_capital.multiplier },
                fin_data: {
                    p21103: { from: parseInt(p21103.from) * p21103.multiplier, to: parseInt(p21103.to) * p21103.multiplier },
                    p11503: { from: parseInt(p11503.from) * p11503.multiplier, to: parseInt(p11503.to) * p11503.multiplier },
                    p12103: { from: parseInt(p12103.from) * p12103.multiplier, to: parseInt(p12103.to) * p12103.multiplier },
                    p12303: { from: parseInt(p12303.from) * p12303.multiplier, to: parseInt(p12303.to) * p12303.multiplier },
                    p15203: { from: parseInt(p15203.from) * p15203.multiplier, to: parseInt(p15203.to) * p15203.multiplier },
                    p24003: { from: parseInt(p24003.from) * p24003.multiplier, to: parseInt(p24003.to) * p24003.multiplier },
                    p22003: { from: parseInt(p22003.from) * p22003.multiplier, to: parseInt(p22003.to) * p22003.multiplier },
                    p23003: { from: parseInt(p23003.from) * p23003.multiplier, to: parseInt(p23003.to) * p23003.multiplier }
                },
                boo_year,
                kad: kad(),
                ved: ved(),
                searchtype,
                search_types: {
                    raw
                }
            }
        },
        selectedFiltersCount: ({ filterData }) => {
            const {
                ved,
                kad,
                bankrupts,
                msp,
                boo_year,
                status,
                rectype,
                phone,
                site,
                email,
                registration_adress_contact,
                nalog_otchet,
                nalog_zadolj,
                license_alco,
                license_kom,
                license_potreb,
                license_prirod,
                license_teh,
                license_obr,
                license_zdrav,
                license_min_cult,
                license_min_promtorg,
                license_roskomnadzor,
                license_rosreestr,
                license_rosselhoznadzor,
                dateStart,
                dateStop,
                auth_capital,
                p21103,
                p11503,
                p12103,
                p12303,
                p15203,
                p24003,
                p22003,
                p23003,
                request,
                checkedClassificator,
                employeeCount
            } = filterData

            let restFiltersLength = 0

            if(boo_year) restFiltersLength += 1
            if(bankrupts) restFiltersLength += 1
            if(msp) restFiltersLength += 1
            if(status != 'both') restFiltersLength += 1
            // if(rectype) restFiltersLength += 1
            if(phone) restFiltersLength += 1
            if(email) restFiltersLength += 1
            if(site) restFiltersLength += 1
            if(license_alco) restFiltersLength += 1
            if(license_kom) restFiltersLength += 1
            if(license_potreb) restFiltersLength += 1
            if(license_prirod) restFiltersLength += 1
            if(license_teh) restFiltersLength += 1
            if(license_obr) restFiltersLength += 1
            if(license_zdrav) restFiltersLength += 1
            if(license_min_cult) restFiltersLength += 1
            if(license_min_promtorg) restFiltersLength += 1
            if(license_roskomnadzor) restFiltersLength += 1
            if(license_rosreestr) restFiltersLength += 1
            if(license_rosselhoznadzor) restFiltersLength += 1
            if(registration_adress_contact) restFiltersLength += 1
            if(nalog_otchet) restFiltersLength += 1
            if(nalog_zadolj) restFiltersLength += 1
            if(dateStop.from) restFiltersLength += 1
            if(dateStop.to) restFiltersLength += 1
            if(dateStart.from) restFiltersLength += 1
            if(dateStart.to) restFiltersLength += 1
            if(kad.type != 'noselect') restFiltersLength += 1
            if(kad.year) restFiltersLength += 1
            if(ved.type) restFiltersLength += 1
            if(ved.year) restFiltersLength += 1

            if(auth_capital.from) restFiltersLength += 1
            if(auth_capital.to) restFiltersLength += 1
            if(p21103.from) restFiltersLength += 1
            if(p21103.to) restFiltersLength += 1
            if(p11503.from) restFiltersLength += 1
            if(p11503.to) restFiltersLength += 1
            if(p12103.from) restFiltersLength += 1
            if(p12103.to) restFiltersLength += 1
            if(p12303.from) restFiltersLength += 1
            if(p12303.to) restFiltersLength += 1
            if(p15203.from) restFiltersLength += 1
            if(p15203.to) restFiltersLength += 1
            if(p24003.from) restFiltersLength += 1
            if(p24003.to) restFiltersLength += 1
            if(p22003.from) restFiltersLength += 1
            if(p22003.to) restFiltersLength += 1
            if(p23003.from) restFiltersLength += 1
            if(p23003.to) restFiltersLength += 1
            if(employeeCount.from) restFiltersLength += 1
            if(employeeCount.to) restFiltersLength += 1

            return {
                restFiltersLength,
                checkedClassificator,
                rectype,
                status,
                allFiltersLength: request.length + checkedClassificator.okfs.length + checkedClassificator.fias.length + checkedClassificator.okved.length + (checkedClassificator.okopf.length + (rectype ? 1 : 0)) + restFiltersLength
            }
        },
        selectedFiltersText: ({ filterData }) => {
            const cls = filterData.checkedClassificator

            const showFn = (type) => {
                var classificatorLength = cls[type].length

                var mappedFilteredClassificator = cls[type]
                    .map(e => ((type == 'okved' ? `${e.code.replace('2014:', '')} - ` : '') + (e.full_title ? e.full_title : e.title)))
                    .filter((_e, i) => i < 5)

                const rectype = filterData.rectype

                if(type == 'okopf' && rectype) {
                    return `${rectype == 1 ? 'только компании' : 'только ИП'}`
                } else {
                    if(mappedFilteredClassificator.length) {
                        if(cls[type].length <= 5) {
                            const str = mappedFilteredClassificator.join('; ')
                            return str
                        } else {
                            const str = [...mappedFilteredClassificator, `и ещё ${classificatorLength - 5}`].join('; ')
                            return str
                        }
                    } else {
                        switch(type) {
                            case 'fias':
                                return 'вся Россия'
                            case 'okfs':
                                return 'не выбрана'
                            case 'okved':
                                return 'все виды'
                            case 'okopf':
                                return 'все компании и ИП'
                        }
                        return ''
                    }
                }
            }

            // прочие фильтры

            const status = () => {
                switch(filterData.status) {
                    default:
                        return false
                    case 'curr':
                        return 'действующие'
                    case 'liqu':
                        return 'ликвидированные'
                }
            }

            const dateStart = () => {
                return filterData.dateStart.from || filterData.dateStart.to ? `дата регистрации${filterData.dateStart.from ? ` c ${filterData.dateStart.from}` : ''}${filterData.dateStart.to ? ` по ${filterData.dateStart.to}` : ''}` : false
            }

            const dateStop = () => {
                return filterData.dateStop.from || filterData.dateStop.to ? `дата ликвидирования${filterData.dateStop.from ? ` c ${filterData.dateStop.from}` : ''}${filterData.dateStop.to ? ` по ${filterData.dateStop.to}` : ''}` : false
            }

            const booleanCheck = (varName, text) => {
                return varName ? text : false
            }

            const finDataCheck = (finp, text) => {
                const multiplierText = () => {
                    switch(finp.multiplier + '') {
                        case '1000':
                            return 'тыс. руб.'
                        case '1000000':
                            return 'млн. руб.'
                        case '1000000000':
                            return 'млрд. руб.'
                        case '1000000000000':
                            return 'трлн. руб.'
                    }
                }
                if(finp.from || finp.to) {
                    return `${text} ${finp.from ? `от ${finp.from}` : ''}${finp.to ? ` до ${finp.to}` : ''} ${multiplierText()}`
                }
            }

            const bankrupts = () => {
                switch(filterData.bankrupts) {
                    default:
                        return false
                    case 1:
                        return 'есть иски о банкротстве'
                    case 2:
                        return 'нет исков о банкротстве'
                }
            }
            const boo = filterData.boo_year ? `имеет бух. отчётность за ${filterData.boo_year} год` : false

            const kad = () => {
                if(filterData.kad.type != 'noselect' || filterData.kad.year) {
                    const type = () => {
                        switch(filterData.kad.type) {
                            case '0':
                                return 'истец'
                            case '1':
                                return 'ответчик'
                            case '2':
                                return '3я сторона'
                        }
                    }
                    // return `${type() ? type() : ''} в арбитражах ${filterData.kad.year ? ` за ${filterData.kad.year} год` : ''}`
                    return `арбитражи${type() ? `: ${type()}` : ''}${filterData.kad.year ? ` за ${filterData.kad.year} год` : ''}`
                }
            }
            const ved = () => {
                if(filterData.ved.type || filterData.ved.year) {
                    const type = () => {
                        switch(filterData.ved.type) {
                            case '1':
                                return ' контрактодержателем'
                            case '2':
                                return ' декларантом'
                            case '3':
                                return ' получателем'
                            case '4':
                                return ' отправителем'
                        }
                    }
                    return `участие в ВЭД${filterData.ved.type ? type() : ''}${filterData.ved.year ? ` за ${filterData.ved.year} год` : ''}`
                }
            }

            const msp = () => {
                switch(filterData.msp) {
                    case 10:
                        return 'микро размер предприятия'
                    case 11:
                        return 'малый размер предприятия'
                    case 12:
                        return 'средний размер предприятия'
                    default:
                        return false
                }
            }

            const restFiltersText = () => {
                const restFiltersLengthString = [
                    status(),
                    dateStart(),
                    dateStop(),
                    booleanCheck(filterData.phone, 'с телефоном'),
                    booleanCheck(filterData.site, 'с сайтом'),
                    booleanCheck(filterData.email, 'c эл. почтой'),
                    booleanCheck(filterData.registration_adress_contact, 'связь по юр. адресу'),
                    booleanCheck(filterData.nalog_otchet, 'сдана налог. отчётность'),
                    booleanCheck(filterData.nalog_zadolj, 'без задолженности по налогам'),
                    booleanCheck(filterData.license_alco, 'лицензия Росалкогольрегулирования'),
                    booleanCheck(filterData.license_kom, 'лицензия Роскомнадзора'),
                    booleanCheck(filterData.license_potreb, 'лицензия Роспотребнадзора'),
                    booleanCheck(filterData.license_prirod, 'лицензия Росприроднадзора'),
                    booleanCheck(filterData.license_teh, 'лицензия Ростехнадзора'),
                    booleanCheck(filterData.license_obr, 'лицензия Рособрнадзора'),
                    booleanCheck(filterData.license_zdrav, 'лицензия Росздравнадзора'),
                    booleanCheck(filterData.license_min_cult, 'лицензия МинКультуры'),
                    booleanCheck(filterData.license_min_promtorg, 'лицензия МинПромторга'),
                    booleanCheck(filterData.license_roskomnadzor, 'лицензия Роскомнадзора (ОПД)'),
                    booleanCheck(filterData.license_rosselhoznadzor, 'лицензия Россельхознадзора'),
                    booleanCheck(filterData.license_rosreestr, 'лицензия Росреестра'),
                    finDataCheck(filterData.auth_capital, 'уставный капитал'),
                    finDataCheck(filterData.p21103, 'выручка'),
                    finDataCheck(filterData.p11503, 'основные средства'),
                    finDataCheck(filterData.p12103, 'запасы'),
                    finDataCheck(filterData.p12303, 'дебиторская задолженность'),
                    finDataCheck(filterData.p15203, 'кредиторская задолженность'),
                    finDataCheck(filterData.p24003, 'чистая прибыль'),
                    finDataCheck(filterData.p22003, 'прибыль от продаж'),
                    finDataCheck(filterData.p23003, 'прибыль до налога'),
                    bankrupts(),
                    ved(),
                    kad(),
                    msp(),
                    boo
                ].filter(Boolean)

                return restFiltersLengthString.length ? restFiltersLengthString.join('; ') : 'не заданы'
            }

            return {
                fias: showFn('fias'),
                okfs: showFn('okfs'),
                okved: showFn('okved'),
                okopf: showFn('okopf'),
                restFiltersText: restFiltersText()
            }
        }
    }
}

export default searchStateModule