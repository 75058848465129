const capitalize = str => str[0].toUpperCase() + str.substring(1)

const formatOkved = str => {
    if(str) {
        str = str[0].toUpperCase() + str.substring(1).toLowerCase()
    }
    return str
}

const formatCode = str => {
    if(str) {
        str = str.split(':')[1]
    }
    return str
}

const getQueryStringValue = key =>
    decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))

const date_format = (date, type) => {
    const dt = new Date(date)
    if(!dt.valueOf()) return date
    const month = dt.getMonth() * 1 + 1

    const str_date = `${dt.getDate()*1 < 10 ? `0${dt.getDate()}` : dt.getDate()}`
    const str_year = dt.getFullYear()
    if(!type) {
        const str_month = `${month < 10 ? `0${month}` : month}`
        return `${str_date}.${str_month}.${str_year}`
    } else {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
        const str_month = months[month - 1]
        return `${str_date} ${str_month} ${str_year}`
    }
}

const monthDiff =  (date1, date2) => {
    let months
    let newDate = date1.split('.')
    newDate = date1[2] + '-' + date1[1] + '-' + date1[0]
    const dt1 = new Date(newDate)
    const dt2 = new Date(date2)
    const days_month_dt1 = 33 - new Date(dt1.getFullYear(), dt1.getMonth(), 33).getDate()
    const days_month_dt2 = 33 - new Date(dt2.getFullYear(), dt2.getMonth(), 33).getDate()

    months = (dt2.getFullYear() - dt1.getFullYear()) * 12
    months += dt2.getMonth() - dt1.getMonth()
    months += (dt2.getDate() / days_month_dt2) - (dt1.getDate() / days_month_dt1)

    !months || months <= 0 ? 0 : months
    return months > 0 && months < 12 ? months * 1.0 : 12
}

const dateParser = (date) => {
    const reg = /^\d{2}\.\d{2}\.\d{4}$/gm
    if(reg.test(date)) return date
    return new Date(date).toLocaleDateString('ru')
}

const firstCapitalLetter = name => name.toLowerCase().split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')

const lowerButFirst = function(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str
}

const lowerButFirstSentence = function(str) {
    return str.split(' ').map(lowerButFirst).join(' ')
}

const getStatusIcon = function(status_id, text) {
    text = text || ['Ликвидированное', 'Действующее', 'В стадии ликвидации', 'Статус не определен']

    switch(status_id.toString()) {
        case '0':
            return '<span style="color: #e74c3c;cursor: help;" title="' + text[0] + '"><i class="uk-icon-times-circle"></i></span>'
        case '1':
            return '<span style="color: #2ecc71;cursor: help;" title="' + text[1] + '"><i class="uk-icon-check-circle"></i></span>'
        case '3':
            return '<span style="color: #2ecc71;cursor: help;" title="' + text[1] + '"><i class="uk-icon-check-circle"></i></span>'
        case '2':
            return '<span style="color: #e7843c;cursor: help;" title="' + text[2] + '"><i class="uk-icon-minus-circle"></i></span>'
        default:
            return '<span style="color: #ddd;cursor: help;" title="' + text[3] + '"><i class="uk-icon-circle-o"></i></span>'
    }
}

const statusTypeText = function(status_type) {
    switch(status_type) {
        case 0:
            return 'ликвидировано'
        case 1:
            return 'зарегистрировано'
        case 2:
            return 'в стадии ликвидации'
        case 3:
            return 'зарегистрировано'
    }
}

const moneyFormat = function(n, decimal_places) {
    if(isNaN(parseFloat(n))) {
        return n
    }

    if(decimal_places) {
        return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ', ')
    }
    return parseFloat(n).toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1 ')
}

const translit = function(text) {
    var arrru = new Array ('Я', 'я', 'Ю', 'ю', 'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 'Ж', 'ж', 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'З', 'з', 'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ы', 'ы', 'Ь', 'ь', 'Ъ', 'ъ', 'Э', 'э')
    var arren = new Array ('Ya', 'ya', 'Yu', 'yu', 'Ch', 'ch', 'Sh', 'sh', 'Sh', 'sh', 'Zh', 'zh', 'A', 'a', 'B', 'b', 'V', 'v', 'G', 'g', 'D', 'd', 'E', 'e', 'E', 'e', 'Z', 'z', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'F', 'f', 'H', 'h', 'C', 'c', 'Y', 'y', '`', '`', '\'', '\'', 'E', 'e')

    for(var i = 0; i<arrru.length; i++) {
        var reg = new RegExp(arrru[i], 'g')
        text = text.replace(reg, arren[i])
    }

    return text
}

const htmlStringToPrint = function(title, html, style) {
    return `
        <!DOCTYPE html>
        <html>
            <head>
                <title>${title}</title>
                <meta charset="utf-8">
                <link rel="stylesheet" type="text/css" href="/assets/lib/uikit/css/uikit.gradient.min.css" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
                <style>
                    html, body {
                        font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
                    }
                    #print-pdf-hint {
                        font-family: sans-serif;
                        font-size: 20px;
                        border-radius: 15px;
                        background-color: 
                        #ddddde;
                        padding: 25px;
                        line-height: 28px;
                        border: 3px dashed
                        #eee;
                    }
                    .print-main-title {
                        border-bottom: 1px solid #4b6365;
                        color: #4b6365;
                        font-size: 25px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        text-align: center;
                        margin-bottom: 15px;
                        margin-top: 20px;

                    }
                    ul {
                        list-style-type: none;
                    }
                    ul.affiliantion-modal-list {
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                    ul.affiliantion-modal-list:nth-child(2n) {
                        background: #eee;

                    }
                    div.tooltip {
                        display: none;
                    }
                    ${style}
                </style>
            </head>
            <body bgcolor="white" style="width: 100%; max-width: 960px; margin: 20px auto; font-size: 14px;">
                <h2 class="print-main-title hide">${title}</h2>
                <div id="print-content">${html}</div>
            </body>
        </html>
    `
}

const getHoursAndMinutesFomDateObjectString = (dateObject) => {
    const date = new Date(dateObject)
    const hrs = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    return `${hrs}:${minutes}`
}

const detectIEEdge = () => {
    const ua = window.navigator.userAgent

    const msie = ua.indexOf('MSIE ')
    if(msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    const trident = ua.indexOf('Trident/')
    if(trident > 0) {
        const rv = ua.indexOf('rv:')
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }

    const edge = ua.indexOf('Edge/')
    if(edge > 0) {
        // Edge => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }

    return false
}

const cookieLib = {
    setCookie: (name, value, days = 30) => {
        var expires = ''
        if(days) {
            var date = new Date()
            date.setTime(date.getTime() + (days*24*60*60*1000))
            expires = '; expires=' + date.toUTCString()
        }
        let path = ''
        if(!(process.mode == 'development')) path = 'licexpert.ru'
        document.cookie = name + '=' + (value || '')  + expires + '; path=/; domain=' + path
    },
    getCookie: (name) => {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for(var i=0;i < ca.length;i++) {
            var c = ca[i]
            while(c.charAt(0)==' ') c = c.substring(1, c.length)
            if(c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    },
    eraseCookie: (name) => {
        document.cookie = name+'=; Max-Age=-99999999;'
    }
}

const isOgrnUL = ogrn => ogrn.toString().length == 13
const isOgrnIP = ogrn => ogrn.toString().length == 15

const transcribeFinancialStatement = codeStr => {
    if(codeStr == 'p21103') { return 'Выручка'}
    if(codeStr == 'p11503') { return 'Основные средства'}
    if(codeStr == 'p12103') { return 'Запасы'}
    if(codeStr == 'p12303') { return 'Деб. задолженность'}
    if(codeStr == 'p15203') { return 'Кред. задолженность'}
    if(codeStr == 'p24003') { return 'Чистая прибыль'}
    if(codeStr == 'p22003') { return 'Прибыль от продаж'}
    if(codeStr == 'p23003') { return 'Прибыль до налога'}
    if(codeStr == 'p13103') { return 'Уставный капитал'}
}

const isThereAnyValueInThisObject = obj => Object.values(obj).reduce((a, b) => a + b, '')

const testEmailString = str => {
    const mailPattern = /^[\w.\d-_]+@[\w.\d-_]+\.\w{2,}$/i
    return mailPattern.test(str)
}

const stripCompanyName = name => {
    let arr = name.trim().split(' ')
    const org = ['ПАО', 'ЗАО', 'АО', 'ООО', 'ОАО', 'ГУП', 'ГОСКОРПОРАЦИЯ', 'ФГУП', 'МК', 'КАО', 'ФГБУ']
    if(arr[0] == 'Индивидуальный' && arr[1] == 'предприниматель') {
        const arr2 = []
        arr2[0] = '"'
        arr2[1] = arr[2]
        arr2[2] = arr[3].slice(0, 1) + '.'
        if(arr.length <=  4) {
            arr2[3] = '"'
        } else {
            arr2[2] = arr2[2] + arr[4].slice(0, 1) + '.'
            arr2[3] = '"'
        }
        arr = arr2
    } else if(org.indexOf(arr[0]) != -1) {
        arr.shift()
    }

    name = arr.join('+')

    if(name[1] == '+') {
        return name = '"' + name.slice(2, name.length -2) + '"'
    } else {
        return name
    }
}

const downloadBinaryFile = (binary, filename = 'download.pdf') => {
    if(navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(binary, filename)
    } else {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([binary]))
        link.download = filename
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}

const downloadFileById = ( url, id, filename = 'download', sid) => {
    const link = document.createElement('a')
    link.href = `${url + id}?sid=${sid}`
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

const Base64 = {
    // private property
    _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  
    // public method for encoding
    encode: function(input) {
        var output = ''
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4
        var i = 0
  
        input = Base64._utf8_encode(input)
  
        while(i < input.length) {
  
            chr1 = input.charCodeAt(i++)
            chr2 = input.charCodeAt(i++)
            chr3 = input.charCodeAt(i++)
  
            enc1 = chr1 >> 2
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
            enc4 = chr3 & 63
  
            if(isNaN(chr2)) {
                enc3 = enc4 = 64
            } else if(isNaN(chr3)) {
                enc4 = 64
            }
  
            output = output +
            this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
            this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4)
  
        }
  
        return output
    },
  
    // public method for decoding
    decode: function(input) {
        var output = ''
        var chr1, chr2, chr3
        var enc1, enc2, enc3, enc4
        var i = 0
  
        input = input.replace(/[^A-Za-z0-9+/=]/g, '')
  
        while(i < input.length) {
  
            enc1 = this._keyStr.indexOf(input.charAt(i++))
            enc2 = this._keyStr.indexOf(input.charAt(i++))
            enc3 = this._keyStr.indexOf(input.charAt(i++))
            enc4 = this._keyStr.indexOf(input.charAt(i++))
  
            chr1 = (enc1 << 2) | (enc2 >> 4)
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
            chr3 = ((enc3 & 3) << 6) | enc4
  
            output = output + String.fromCharCode(chr1)
  
            if(enc3 != 64) {
                output = output + String.fromCharCode(chr2)
            }
            if(enc4 != 64) {
                output = output + String.fromCharCode(chr3)
            }
  
        }
  
        output = Base64._utf8_decode(output)
  
        return output
  
    },
  
    // private method for UTF-8 encoding
    _utf8_encode: function(string) {
        string = string.replace(/\r\n/g, '\n')
        var utftext = ''
  
        for(var n = 0; n < string.length; n++) {
  
            var c = string.charCodeAt(n)
  
            if(c < 128) {
                utftext += String.fromCharCode(c)
            } else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192)
                utftext += String.fromCharCode((c & 63) | 128)
            } else {
                utftext += String.fromCharCode((c >> 12) | 224)
                utftext += String.fromCharCode(((c >> 6) & 63) | 128)
                utftext += String.fromCharCode((c & 63) | 128)
            }
  
        }
  
        return utftext
    },
  
    // private method for UTF-8 decoding
    _utf8_decode: function(utftext) {
        var string = ''
        var i = 0
        var c = 0
        // var c1 = 0
        var c2 = 0
        var c3 = 0
  
        while( i < utftext.length ) {
  
            c = utftext.charCodeAt(i)
  
            if(c < 128) {
                string += String.fromCharCode(c)
                i++
            } else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1)
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
                i += 2
            } else {
                c2 = utftext.charCodeAt(i+1)
                c3 = utftext.charCodeAt(i+2)
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
                i += 3
            }
        }
  
        return string
    }
}

const objectMerger = (array) => {
    const resObj = {}
    const keys = Object.keys(array[0])

    keys.forEach(key => resObj[key] = null)

    array.forEach(el => {
        keys.forEach(key => {
            if(!resObj[key]) { resObj[key] = el[key] }
        })
    })

    return resObj
}

const correctFio = fio => fio.toLowerCase().split(' ').map(e => capitalize(e)).join(' ')

const getCodeFromOgrn = ogrn => ogrn.toString().split('').filter((e, i) => i === 3 || i === 4).join('')


const downloadByUrl = url => {
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.click()
}

// экспорт для // import { htmlStringToPrint } from '@/helpers.js' таргетированного импорта
export {
    moneyFormat,
    htmlStringToPrint,
    translit,
    getQueryStringValue,
    detectIEEdge,
    cookieLib,
    transcribeFinancialStatement,
    testEmailString,
    dateParser,
    formatOkved,
    isOgrnIP,
    stripCompanyName,
    downloadBinaryFile,
    Base64,
    objectMerger,
    correctFio,
    date_format,
    downloadFileById,
    getCodeFromOgrn,
    downloadByUrl
}

// экспорт для helpers общего импорта
// import helpers from './helpers.js'
export default {
    isThereAnyValueInThisObject,
    getHoursAndMinutesFomDateObjectString,
    moneyFormat,
    statusTypeText,
    getStatusIcon,
    lowerButFirst,
    formatCode,
    getQueryStringValue,
    date_format,
    capitalize,
    lowerButFirstSentence,
    isOgrnUL,
    dateParser,
    firstCapitalLetter,
    monthDiff,
    correctFio,
    downloadFileById,
    downloadByUrl,
    getCodeFromOgrn
}