var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [
      _c("b", [_vm._v("Всего: ")]),
      _vm._v(" " + _vm._s(_vm.afilArray.length)),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("span", [
        _c("b", [_vm._v("Действующие: ")]),
        _vm._v(" " + _vm._s(_vm.operating.length) + " "),
      ]),
      _vm._v(" "),
      _c("span", [
        _c("b", [_vm._v("Ликвидированные: ")]),
        _vm._v(" " + _vm._s(_vm.liquidated.length) + " "),
      ]),
      _vm._v(" "),
      _c("span", [
        _c("b", [_vm._v("Находятся в стадии ликвидации: ")]),
        _vm._v(_vm._s(_vm.liquidation_process.length) + " "),
      ]),
      _c("br"),
      _vm._v(" "),
      (_vm.currentStakeholder.length || _vm.currentStakeholder.length === 0) &&
      _vm.type !== "history"
        ? _c("span", [
            _c("b", [_vm._v("Действующий " + _vm._s(_vm.typeString) + ": ")]),
            _vm._v(" " + _vm._s(_vm.currentStakeholder.length)),
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.former || _vm.former === 0) && _vm.type !== "history"
        ? _c("span", [
            _c("b", [_vm._v("Бывший " + _vm._s(_vm.typeString) + ": ")]),
            _vm._v(" " + _vm._s(_vm.former)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }