<template>
    <div :class="{ 'custom-modal': true, 'custom-modal__fullscreen': fullScreen }">
        <div @click="() => close()" class="custom-modal__background"></div>
        <div class="custom-modal__inner">
            <div class="custom-modal__header" ref="modalHeader">
                <slot name="modalHeader"/>
                <i
                    v-if="showFullScreenIcon"
                    @click="fullScreen = !fullScreen"
                    :class="fullScreenIconClass"></i>
                <i @click="() => close()" class="fas fa-times"></i>
            </div>

            <div class="custom-modal__body" :style="modalBodyStyle" ref="modalBody">
                <slot name="modalBody"/>
            </div>

            <div class="custom-modal__footer" v-if="hasFooter">
                <btn v-if="downloadButton" @click="getPdf" blue><fi class="icon-size" icon="file"></fi> Скачать PDF</btn>
                <btn v-if="printButton" @click="print"><i class="fas fa-print"></i> Распечатать</btn>
            </div>
        </div>
    </div>
</template>

<script>
import { htmlStringToPrint } from '@/helpers.js'
import btn from '@/components/ui/button/button.vue.js'
import fi from '@/components/ui/feather-icon/feather-icon.vue.js'
// import axios from 'axios'

export default {
    components: { btn, fi },
    props: [
        'close', // коллбек закрытия
        'imgHeader', // картинка в заголовке (кастомная высота хедера + )
        'noHeader', // без заголовка
        'customInnerHeight', // задать свою высоту
        'customInnerWidth', // задать свою ширину
        'printButton', // кнопка печати
        'downloadButton', // кнопка сохранения
        'showFullScreenIcon',
        'title' // заголовок модалки для печатной и pdf версий
    ],
    data() {
        return {
            fullScreen: false
        }
    },
    methods: {
        print() {
            const imgHtmlCode = htmlStringToPrint(this.printTitle, this.$refs.modalBody.outerHTML, '.no_print {display: none !important;} .no_print-pre {white-space: pre-line;} .bold__date{font-weight: bold;} .print-main-title {font-weight: bold;}  td,th {text-align: left;} canvas {display: none;} table {border-collapse: collapse; width: 100%;} .no_print {display: none !important;} .no_print-pre {white-space: pre-line;} .bold__date{font-weight: bold;} td, th{padding: 10px; text-align:center; border-width: 1px; border-style: solid;border-collapse: collapse; border-color: #777778;} .fa-check, .print-green{color: #27ae60;} .fa-times{color: #e74c3c;} .print-red {text-decoration: line-through; color: #e74c3c;} .print-table{width: 100%;} .print-image{max-width: 100px} ')

            const printWin = window.open()
            printWin.document.write(imgHtmlCode)
            printWin.document.close()
        },
        getPdf() { this.$store.dispatch('downloadPDF', { title: this.title, html: this.$refs.modalBody.outerHTML }) }
    },
    computed: {
        printTitle() {
            if(this.$props.title) {
                return `${this.$props.title}`
            } else {
                return this.$refs.modalHeader.children[0].innerText
            }
        },
        fullScreenIconClass() {
            return {
                fas: true,
                'fa-compress': this.fullScreen,
                'fa-expand': !this.fullScreen
            }
        },
        hasFooter() {
            return this.$props.printButton || this.$props.downloadButton
        },
        modalBodyStyle() {
            const vh = this.fullScreen || this.mobile.isMobile ? '100vh' : '80vh'
            const px = this.hasFooter ? '110px' : '55px'
            
            return { height: `calc(${vh} - ${px})` }
        },
        ogrn() { return this.$store.getters['card/ogrn'] }
    }
}
</script>

<style scoped lang="sass">
@use 'styles/mobile.sass'

$header-footer-size: 55px

.custom-modal
    z-index: 123123
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    text-align: left
    &__fullscreen
        .custom-modal__inner
            width: 99vw
            height: 100vh
            max-height: 100vh
            top: 0
            left: 0
        .custom-modal__body
            height: 100vh
    &__header
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        width: 100%
        font-family: Arial, Tahoma, sans-serif
        font-weight: 100
        font-size: 24px
        padding: 15px
        height: $header-footer-size
        padding-right: 40px
        border-bottom: 1px solid #ddd
        i
            &.fa, &.fas
                position: absolute
                top: 17px
                right: 15px
                cursor: pointer
                color: #444
                opacity: 0.3
            &:hover
                opacity: 0.7
            &.fa.fa-close
                right: 15px
            &.fas.fa-expand,
            &.fas.fa-compress
                right: 42px
                transform: scale(0.95)
    &__body
        white-space: normal
        overflow-y: auto
        font-size: 16px
    &__footer
        width: 100%
        height: 10vh
        padding: 10px
        border-top: 1px solid #ddd
        border-radius: 0 0 4px 4px
        bottom: 0
        text-align: right
    &__inner
        margin: 0 auto
        border-radius: 5px
        width: 80vw
        height: 80vh
        max-height: 80vh
        background-color: #fafafa
        top: 10vh
        position: relative
        overflow: hidden
        +mobile.mobile
            width: 100%
            height: 100vh
            max-height: 100vh
            top: 0
            left: 0
    &__background
        background-color: rgba(0,0,0,.35)
        position: absolute
        width: 100vw
        height: 100vh
        top: 0
        left: 0
.icon-size
    width: 18px
</style>