<template>
    <div class="vheader-menu">
        <div class="vheader-menu-item hide-on-tablet" id="old-interface-link">
            <a class="licexpert__text" :href="oldInterfaceLink" target="_blank">
                Перейти на
                <br>
                новый интерфейс
            </a>
        </div>
        <div class="vheader-menu-item vheader-menu-item-profile" @mouseover="showProfileMenu = true" @mouseleave="showProfileMenu = false">
            <span class="lnr lnr-user" v-if="userName"></span>
            <span v-if="userName" class="vheader-menu-item-profile__user-name">
                {{userName}}
            </span>
            <div v-else @click="showSignin">Войти</div>
            <div v-if="showProfileMenu && userName" class="vheader-menu-item-submenu">
                <a v-router-link-fallback href="/profile">Профиль</a>
                <a @click="signout" href="#">Выход</a>
            </div>
        </div>
        <div class="vheader-menu-item vheader-menu-item-burger" @mouseover="showBurgerMenu = true" @mouseleave="showBurgerMenu = false">
            <i class="uk-icon-th uk-icon-small"></i>
            <div v-if="showBurgerMenu" class="vheader-menu-item-submenu vheader-menu-item-burger-submenu">
                <a v-router-link-fallback href="/search">Подбор клиентов</a>
                <a v-router-link-fallback v-if="userName" href="/monitoring">
                    Мониторинг<i v-if="allCountUnseen" class="monitoring-notification">{{allCountUnseen}}</i>
                </a>
                <a v-router-link-fallback v-if="userName  && hasAccessToRelationsSearch" href="/relations-search" class="beta">Поиск связей</a>
                <a v-router-link-fallback v-if="userName" href="/passport-check">Проверка паспорта</a>
                <a v-router-link-fallback v-if="userName && checkFlAccess" href="/fl-check" class="new">Проверка ФЛ</a>
                <a v-router-link-fallback v-if="userName" href="/flat-check">Проверка квартиры</a>
                <a v-router-link-fallback v-if="userName" href="/egrn">Выписка ЕГРН</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showProfileMenu: false,
            showBurgerMenu: false
        }
    },
    methods: {
        showSignin() {
            this.$store.commit('header/toggleSignin', true)
        },
        signout(evt) {
            evt.preventDefault()
            this.$store.dispatch('user/signout')
        }
    },
    computed: {
        userName() { return this.$store.state.user.user_data ? this.$store.state.user.user_data.user_login : null },
        allCountUnseen() { return this.$store.getters['monitoring/allCountUnseen'] },
        checkFlAccess() { return this.$store?.state?.user?.user_data?.fl_online },
        oldInterfaceLink() {
            const ogrnParam = this.$route.params?.ogrn
            if(ogrnParam) {
                return `https://v3.licexpert.ru/company/${ogrnParam}`
            } else {
                return 'https://v3.licexpert.ru'
            }
        },
        hasAccessToRelationsSearch() {
            return this.$store.state.user?.limits?.map(e => e.id)?.includes(113)
        }
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'
@import 'styles/global-variables.sass'

.new
    &:before
        content: 'new '
        color: $licexpert-yellow
        padding: 0 !important

.beta
    &:before
        content: 'beta '
        color: $licexpert-yellow
        padding: 0 !important

.vheader-menu
    display: inline-block
    font-size: 0
    a
        color: $header-color
        text-decoration: none
        border: 0
        &:hover
            color: $header-color
    &-item
        display: inline-block
        font-size: 16px
        font-weight: normal
        line-height: $header-height - $header-offset
        cursor: pointer
        padding: 0 20px
        text-align: center
        position: relative
        vertical-align: top
        &#old-interface-link
            line-height: 20px
            margin-top: 15px
            a:hover
                color: $licexpert-yellow !important
        &-search
            font-variant: all-small-caps
            &:hover
                background-color: rgba(255,255,255,.3)
        &-profile
            &__user-name
                +mobile.hide-on-tablet
            .lnr.lnr-user
                display: none
                height: $header-height - $header-offset
                line-height: $header-height - $header-offset
                +mobile.tablet
                    display: block
            &:hover
                color: $licexpert-yellow
        &-submenu
            z-index: 3
            background-color: $header-background-color
            width: 100%
            right: 0
            top: $header-height - ($header-offset + 6)
            position: absolute
            text-align: right
            color: $header-color
            font-size: 14px
            min-width: 135px
            a
                font-weight: 400
                height: $header-menu-height
                line-height: $header-menu-height
                width: 100%
                display: block
                padding: 0 20px
                &:hover
                    background-color: rgba(255,255,255,.3)
                    color: #fff !important
        &-burger
            &:hover
                .uk-icon-th
                    color: $licexpert-yellow
            &-submenu
                width: 200px
                i.monitoring-notification
                    display: inline-block
                    margin-left: 5px
                    border-radius: 12px
                    background-color: $licexpert-yellow
                    height: 36px
                    line-height: 36px
                    padding: 0 10px
                    color: #333
</style>