var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "custom-modal": true,
        "custom-modal__fullscreen": _vm.fullScreen,
      },
    },
    [
      _c("div", {
        staticClass: "custom-modal__background",
        on: {
          click: function () {
            return _vm.close()
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "custom-modal__inner" }, [
        _c(
          "div",
          { ref: "modalHeader", staticClass: "custom-modal__header" },
          [
            _vm._t("modalHeader"),
            _vm._v(" "),
            _vm.showFullScreenIcon
              ? _c("i", {
                  class: _vm.fullScreenIconClass,
                  on: {
                    click: function ($event) {
                      _vm.fullScreen = !_vm.fullScreen
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("i", {
              staticClass: "fas fa-times",
              on: {
                click: function () {
                  return _vm.close()
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "modalBody",
            staticClass: "custom-modal__body",
            style: _vm.modalBodyStyle,
          },
          [_vm._t("modalBody")],
          2
        ),
        _vm._v(" "),
        _vm.hasFooter
          ? _c(
              "div",
              { staticClass: "custom-modal__footer" },
              [
                _vm.downloadButton
                  ? _c(
                      "btn",
                      { attrs: { blue: "" }, on: { click: _vm.getPdf } },
                      [
                        _c("fi", {
                          staticClass: "icon-size",
                          attrs: { icon: "file" },
                        }),
                        _vm._v(" Скачать PDF"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.printButton
                  ? _c("btn", { on: { click: _vm.print } }, [
                      _c("i", { staticClass: "fas fa-print" }),
                      _vm._v(" Распечатать"),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }