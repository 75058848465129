import { set } from 'lodash'


export default {
    namespaced: true,
    state: {
        list: [],
        loading: false
    },
    getters: {
        years(state) {
            return Object.keys(state.list)
        }
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        getData({ commit, dispatch, rootState, rootGetters }) {
            commit('setLoading', true)
            if(!rootState.card.info.inn) { return }

            return dispatch('v3/API', {
                method: 'economics',
                data: { 
                    action: 'business_coefficient',
                    ogrn: rootGetters['card/ogrn'],
                    inn: rootState.card.info.inn

                }
            }, { root: true }).then(e => {
                commit('setStateObject', { name: 'list', value: e?.data?.data })

                return e?.data?.data
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    }
}
