export default {
    namespaced: true,
    state: {
        showSignin: false
    },
    mutations: {
        toggleSignin(state, show) {
            state.showSignin = show
        }
    }
}